import axiosRequest from "../axios/axios";

//get notifiction List
export const getNotificationList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`NotificationList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};
