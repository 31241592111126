import { Box } from "@mui/material";
import React from "react";
import SettingsList from "./SettingsList";

const Settings = () => {
  return (
    <Box>
      <SettingsList />
    </Box>
  );
};

export default Settings;
