import axiosRequest from "../axios/axios";

//get Rider List
export const getRiderList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`getriders` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get Rider req List
export const getRiderReqList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`getriders` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider Details
export const getRiderDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/RiderView/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider Details
export const getRiderApproveDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/RiderDetails/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider Delete
export const DeleteRider = async (id) => {
  try {
    const response = await axiosRequest.delete(`/RiderDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Rider Status Update

export const RiderStatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`/StatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider update

export const updateRider = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`/RiderEdit/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//verify list

export const getVerifyList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/VerifiedListRider`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider vehical dropdown list
export const getVehicleDropdownList = async () => {
  try {
    const response = await axiosRequest.get(`/Vehicallist`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider approve
export const RiderApprove = async (id, queryString) => {
  try {
    const response = await axiosRequest.post(
      `/RiderDocumentCount/${id}?status=1`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider reject

export const RiderReject = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/RiderDocumentCount/${id}?status=2`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider verify Document
export const RiderDocumentVerify = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `/DocumentStatusUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//review

export const RiderReview = async (id) => {
  try {
    const response = await axiosRequest.get(`/getReviewData/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Rating

export const UserRating = async (id) => {
  try {
    const response = await axiosRequest.get(`/getRatingData/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add rider bank details
export const AddRiderBankDetails = async (payload) => {
  try {
    const response = await axiosRequest.post(`/riderBankDetails`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add rider bank details
export const withdrawalAmount = async (payload) => {
  try {
    const response = await axiosRequest.post(`/withdrawalAmount`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};
