import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Slide,
  DialogContent,
  IconButton,
  Modal,
  Paper,
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CardHeader,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GrView } from "react-icons/gr";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./rider.css";
import { useLocation, useNavigate } from "react-router";
import VerifiedIcon from "../../assets/premium.png";
import moment from "moment";
import { styled } from "@mui/system";
import { Form, useForm } from "../../Utils/useForm";
import { useForm1 } from "../../Utils/useForm1";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  AddRiderBankDetails,
  DeleteRider,
  RiderReview,
  getRiderApproveDetails,
  getRiderDetails,
  UserRating,
  withdrawalAmount,
} from "../../state/action/riderAction";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Controls from "../../Component/controls/Controls";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useProSidebar } from "react-pro-sidebar";
import { useSnackbar } from "notistack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { getWithDrawDetailsList } from "../../state/action/withdrawAction";
import { useDispatch, useSelector } from "react-redux";
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const initialValues = {
  accountNumber: "",
  bankName: "",
  accountHolderName: "",
  bankIFSCCode: "",
  bankAddress: "",
};
const initialValues1 = {
  amounts: "",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const RiderDetails = () => {
  const [value, setValue] = useState("1");

  const [riderList, setRiderList] = useState([]);
  const [riderDocsList, setRiderDocsList] = useState([]);
  const [WithdrawalsList, setWithdrawalsList] = useState([]);
  const [openStatusModel, setOpenStatusModel] = useState(false);
  const [deviceDetailsList, setDeviceDetailsList] = useState([]);
  const [orderDetailsList, setorderDetailsList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [Ratings, setRating] = useState();
  const [planHistoryList, setplanHistoryList] = useState([]);
  const { collapsed, broken } = useProSidebar();
  const [status, setStatus] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [bankList, setBankList] = useState({});

  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openReviewModel, setOpenReviewModel] = useState(false);
  const [openRatingModel, setOpenRatingModel] = useState(false);
  const [openBankModel, setOpenBankModel] = useState(false);
  const [openWithdrawModel, setOpenWithdrawModel] = useState(false);

  const [currentImageIndex, setImageIndex] = useState(0);

  //delete the user
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  //review the user
  const handleCloseReview = () => setOpenReviewModel(false);
  const handleReviewModel = () => setOpenReviewModel(true);

  //Rating the user
  const handleCloseRating = () => setOpenRatingModel(false);
  const handleRatingModel = () => setOpenRatingModel(true);

  // visa
  const [openVisaDocument, setOpenVisaDocument] = useState(false);
  const [openVisaDocumentBack, setOpenVisaDocumentBack] = useState(false);

  const [switchId, setSwitchId] = useState(null);

  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openSwitchsModel, setOpenSwitchsModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [open, setOpen] = useState(false);

  const [openPassport, setOpenPassport] = useState(false);
  const [openPassportBack, setOpenPassportBack] = useState(false);
  const [openIdentityCard, setOpenIdentityCard] = useState(false);
  const [openIdentityCardBack, setOpenIdentityCardBack] = useState(false);
  const [openDriverLicense, setOpenDriverLicense] = useState(false);
  const [openDriverLicenseBack, setOpenDriverLicenseBack] = useState(false);
  const [openDriverLicenseOther, setOpenDriverLicenseOther] = useState(false);
  const [openRegistrationCert, setOpenRegistrationCert] = useState(false);
  const [openVehicleDocument, setOpenVehicleDocument] = useState(false);
  const [openVehicleDocumentBack, setOpenVehicleDocumentBack] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openRegistrationCertBack, setOpenRegistrationCertBack] =
    useState(false);

  const [openPermit, setOpenPermit] = useState(false);
  const [openPermitBack, setOpenPermitBack] = useState(false);

  //document reject
  const handleRejectModel = () => setOpenRejectModel(true);
  const handleCloseReject = () => setOpenRejectModel(false);

  //document verify
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const Role = useSelector((state) => state?.auth?.user);
  const handleClickOpen = (index) => {
    setImageIndex(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPassport = (index) => {
    setImageIndex(index);
    setOpenPassport(true);
  };
  const handleClosePassport = () => {
    setOpenPassport(false);
  };

  //passportback

  const handleClickOpenPassportBack = (index) => {
    setImageIndex(index);
    setOpenPassportBack(true);
  };
  const handleClosePassportBack = () => {
    setOpenPassportBack(false);
  };

  //Identity Card

  const handleClickOpenIdentityCard = (index) => {
    setImageIndex(index);
    setOpenIdentityCard(true);
  };
  const handleCloseIdentityCard = () => {
    setOpenIdentityCard(false);
  };

  //Identity Card Back

  const handleClickOpenIdentityCardBack = (index) => {
    setImageIndex(index);
    setOpenIdentityCardBack(true);
  };
  const handleCloseIdentityCardBack = () => {
    setOpenIdentityCardBack(false);
  };

  //Driver License

  const handleClickOpenDriverLicense = (index) => {
    setImageIndex(index);
    setOpenDriverLicense(true);
  };
  const handleCloseDriverLicense = () => {
    setOpenDriverLicense(false);
  };

  //Driver License back

  const handleClickOpenDriverLicenseBack = (index) => {
    setImageIndex(index);
    setOpenDriverLicenseBack(true);
  };
  const handleCloseDriverLicenseBack = () => {
    setOpenDriverLicenseBack(false);
  };

  //Driver License other

  const handleClickOpenDriverLicenseOther = (index) => {
    setImageIndex(index);
    setOpenDriverLicenseOther(true);
  };
  const handleCloseDriverLicenseOther = () => {
    setOpenDriverLicenseOther(false);
  };

  //Registration Certificate

  const handleClickOpenRegistrationCertificate = (index) => {
    setImageIndex(index);
    setOpenRegistrationCert(true);
  };
  const handleCloseRegistrationCertificate = () => {
    setOpenRegistrationCert(false);
  };

  //Registration Certificate back

  const handleClickOpenRegistrationCertificateBack = (index) => {
    setImageIndex(index);
    setOpenRegistrationCertBack(true);
  };
  const handleCloseRegistrationCertificateBack = () => {
    setOpenRegistrationCertBack(false);
  };

  //Vehicle Document

  const handleClickOpenVehicleDocument = (index) => {
    setImageIndex(index);
    setOpenVehicleDocument(true);
  };
  const handleCloseVehicleDocument = () => {
    setOpenVehicleDocument(false);
  };

  //Vehicle Document

  const handleClickOpenVehicleDocumentBack = (index) => {
    setImageIndex(index);
    setOpenVehicleDocumentBack(true);
  };
  const handleCloseVehicleDocumentBack = () => {
    setOpenVehicleDocumentBack(false);
  };

  //Permit

  const handleClickOpenPermit = (index) => {
    setImageIndex(index);
    setOpenPermit(true);
  };
  const handleClosePermit = () => {
    setOpenPermit(false);
  };

  //Permit back

  const handleClickOpenPermitBack = (index) => {
    setImageIndex(index);
    setOpenPermitBack(true);
  };
  const handleClosePermitBack = () => {
    setOpenPermitBack(false);
  };

  // visa Document front

  const handleClickOpenVisaDocument = (index) => {
    setImageIndex(index);
    setOpenVisaDocument(true);
  };
  const handleCloseVisaDocument = () => {
    setOpenVisaDocument(false);
  };


  // visa Document back
  const handleClickOpenVisaDocumentBack = (index) => {
    setImageIndex(index);
    setOpenVisaDocumentBack(true);
  };
  const handleCloseVisaDocumentBack = () => {
    setOpenVisaDocumentBack(false);
  };


  //add the bank
  const handleCloseBank = () => {
    setOpenBankModel(false);
    getDetails(state);
  };
  const handleBankModel = () => {
    setOpenBankModel(true);
    getDetails(state);
    BankListing(state);
  };

  // Withdraw model
  const handleCloseWithdraw = () => {
    setOpenWithdrawModel(false);
    getDetails(state);
  };
  const handleWithdrawModel = () => {
    setOpenWithdrawModel(true);
    // getDetails(state);
    // BankListing(state);
  };

  const refreshScreen = () => {
    setRefresh(!refresh);
  };


  const navigate = useNavigate();

  const { state } = useLocation();

  const getDetails = async (state) => {
    const res = await getRiderApproveDetails(state);
    if (res && res.status) {
      setRiderList(res?.data);
      setDeviceDetailsList(res.data.deviceDetails);
      setorderDetailsList(res.data.orderDetails);
      setplanHistoryList(res.data.planHistory);
      setRiderDocsList(res?.data?.riderDocs);
      setWithdrawalsList(res?.data?.WithdrawalsList);
    }
  };

  useEffect(() => {
    getDetails(state);
  }, [state]);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };


    if ("amounts" in fieldValues) {
      var regex = /^[+-]?([0-9]*[.])?[0-9]+$/;
      if (!regex.test(fieldValues.amounts)) {
        temp.amounts = "Only numbers are allowed";
      } else {
        temp.amounts = "";
      }
    }



    if ("accountNumber" in fieldValues)
      temp.accountNumber = fieldValues.accountNumber
        ? ""
        : "This field is required.";

    if ("bankName" in fieldValues)
      temp.bankName = fieldValues.bankName ? "" : "This field is required.";

    if ("accountHolderName" in fieldValues)
      temp.accountHolderName = fieldValues.accountHolderName
        ? ""
        : "This field is required.";

    if ("bankIFSCCode" in fieldValues)
      temp.bankIFSCCode = fieldValues.bankIFSCCode
        ? ""
        : "This field is required.";

    if ("bankAddress" in fieldValues)
      temp.bankAddress = fieldValues.bankAddress
        ? ""
        : "This field is required.";



    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const validate1 = (fieldValues = values1) => {
    let temp = { ...errors1 };


    if ("amounts" in fieldValues) {
      var regex = /^[+-]?([0-9]*[.])?[0-9]+$/;
      if (!regex.test(fieldValues.amounts)) {
        temp.amounts = "Only numbers are allowed";
      } else {
        temp.amounts = "";
      }
    }
    setErrors1({
      ...temp,
    });

    if (fieldValues === values1)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const { values1, setValues1, errors1, setErrors1, handleInputChange1, resetForm1 } =
    useForm1(initialValues1, true, validate1);

  //document status
  const documentStatus = (documentStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        color: "#fc7e00",
      },
      1: {
        key: "Approved",
        color: "green",
      },
      2: {
        key: "Rejected",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[documentStatus]}
        label={
          statusColors[documentStatus] ? statusColors[documentStatus].key : ""
        }
      />
    );
  };





  //add user
  const [addUser, setAddUser] = useState(false);
  const openUserModal = () => {
    setAddUser(true);
  };
  const closeUserModal = () => {
    setAddUser(false);
  };

  const orderStatus = (orderStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Order Pickup Schedule",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Pickup Package",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      3: {
        key: "Package in Transit",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      4: {
        key: "Delivered",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      5: {
        key: "Cancelled",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderStatus]}
        label={statusColors[orderStatus] ? statusColors[orderStatus].key : ""}
      />
    );
  };

  //paln status
  const planStatus = (planStatus) => {
    const statusColors = {
      1: {
        key: "Success",
        color: "green",
      },
      2: {
        key: "Failed",
        color: "#ff0000",
      },
      3: {
        key: "Pending",
        color: "#fc7e00",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[planStatus]}
        label={statusColors[planStatus] ? statusColors[planStatus].key : ""}
      />
    );
  };

  //review details
  const getReview = async (state) => {
    const res = await RiderReview(state);
    if (res && res.status) {
      setReviewList(res?.data);
    }
  };

  // rating
  const getRating = async (id) => {
    const res = await UserRating(id);
    if (res && res.status) {
      setRating(res?.data[0]);
    }
  };



  //delete
  const handleDelete = async () => {
    const res = await DeleteRider(state);
    if (res && res.status === true) {
      handleCloseDelete();
      navigate("/rider");
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //device status
  const deviceStatus = (deviceStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "black",
      },
      0: {
        key: "No",
        color: "black",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[deviceStatus]}
        label={statusColors[deviceStatus] ? statusColors[deviceStatus].key : ""}
      />
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (bankList?.id) {
        const payload = {
          bankName: values.bankName,
          accountHolderName: values.accountHolderName,
          accountNumber: values.accountNumber,
          bankIFSCCode: values.bankIFSCCode,
          bankAddress: values.bankAddress,

          riderID: state,
          id: bankList?.id,
        };
        const res = await AddRiderBankDetails(payload);

        if (res && res.status === true) {
          handleCloseBank();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
      if (!bankList?.id) {
        const payload = {
          bankName: values.bankName,
          accountHolderName: values.accountHolderName,
          accountNumber: values.accountNumber,
          bankIFSCCode: values.bankIFSCCode,

          bankAddress: values.bankAddress,
          riderID: state,
          id: bankList?.id,
        };
        const res = await AddRiderBankDetails(payload);

        if (res && res.status === true) {
          handleCloseBank();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };


  // Withdraw 
  const handleWithdrawSubmit = async (e) => {
    e.preventDefault();
    if (validate1()) {
      const payload = {
        amounts: values.amounts,
        riderID: state,
      };
      const res = await withdrawalAmount(payload);
      if (res && res.status === true) {
        handleCloseWithdraw();
        resetForm();
        getDetails(state);
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res.response.data.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };
  //bank details

  const BankListing = async () => {
    const res = await getWithDrawDetailsList(state);
    if (res.status === true) {
      setBankList(res.data);
    } else {
      setBankList([]);
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      bankName: bankList.bankName,
      accountHolderName: bankList.accountHolderName,
      accountNumber: bankList.accountNumber,
      bankIFSCCode: bankList.bankIFSCCode,
      bankAddress: bankList.bankAddress,
    });
  }, [bankList]);

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/rider");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Riders
          </Typography>
          <div>
            <Controls.Button
              text="Back"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "50px",
                border: "0",
                color: "white",
                ":hover": { bgcolor: "black", border: "0" },
                backgroundColor: "black",
                textTransform: "none",
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => {
                navigate("/rider");
              }}
            />
            {Role.role == 1 ? (
              <Controls.Button
                text="Delete"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#fff",
                  backgroundColor: "Red",
                  ":hover": { bgcolor: "Red" },
                  textTransform: "none",
                  borderRadius: "50px",
                  boxShadow: 0,
                  marginLeft: "7px",
                }}
                onClick={() => {
                  handleDeleteModel();
                }}
              />
            ) : null}
            {Role.role == 1 ? (
              <Controls.Button
                startIcon={<AccountBalanceIcon />}
                text="Add"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "50px",
                  border: "0",
                  color: "#fff",
                  ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                  backgroundColor: "rgb(79, 70, 229)",
                  marginLeft: {
                    xs: "0px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  handleBankModel();
                }}
                variant="outlined"
              />
            ) : null}
            {Role.role == 1 ? (
              <Controls.Button
                startIcon={<PaymentIcon />}
                text="Withdraw"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "50px",
                  border: "0",
                  color: "#fff",
                  ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                  backgroundColor: "rgb(79, 70, 229)",
                  marginLeft: {
                    xs: "0px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  handleWithdrawModel();
                }}
                variant="outlined"
              />
            ) : null}
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          marginLeft: { xs: "15px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Card
                  sx={{
                    paddingTop: "24px",
                    textAlign: "center",
                    color: "black",
                    paddingBottom: "15px",
                  }}
                >
                  <StyledIcon
                    sx={{
                      color: "black",
                      backgroundImage: "white",
                      mt: 1,
                    }}
                  >
                    <Avatar
                      sx={{ width: "90px", height: "90px" }}
                      src={riderList?.profilePic}
                    />
                  </StyledIcon>

                  <Typography
                    variant="h4"
                    sx={{
                      marginTop: "25px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    {riderList?.name}{" "}
                    {riderList?.isPro !== 0 ? (
                      <img
                        src={VerifiedIcon}
                        alt=""
                        sx={{ width: "20px", height: "20px", color: "blue" }}
                      />
                    ) : null}
                  </Typography>
                  <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <EmailIcon sx={{ width: "15px", height: "15px", px: 1 }} />{" "}
                    {riderList?.email || "-"}
                  </Typography>

                  <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <PhoneIcon sx={{ width: "15px", height: "15px", px: 1 }} />{" "}
                    {riderList?.countryCode || "-"} {riderList?.mobile || "-"}
                  </Typography>
                  <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <b style={{ marginRight: "5px" }}>Vehicle Type: </b>
                    {riderList?.vehicleType}
                  </Typography>
                  <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <b style={{ marginRight: "5px" }}>Referal Code: </b>
                    {riderList?.referalCode}
                  </Typography>
                  <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <b style={{ marginRight: "5px" }}>Joining Date: </b>
                    {moment(riderList?.createdAt).format('YYYY-MM-DD')}
                  </Typography>
                  <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <b style={{ marginRight: "5px" }}>Rating: </b>{" "}
                    {riderList?.avgRating ? (
                      <Typography>{riderList?.avgRating} / 5</Typography>
                    ) : (
                      <Typography>0 / 5</Typography>
                    )}
                    <span
                      style={{
                        color: "blue",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        handleReviewModel(e);
                        getReview(state);
                      }}
                    >
                      {" "}
                      {riderList?.noRating ? (
                        <Typography>( {riderList?.noRating} Review)</Typography>
                      ) : (
                        <Typography>0 Review</Typography>
                      )}
                    </span>
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                <Box
                  sx={{
                    marginTop: { xs: "0px", sm: "20px", md: "20px", lg: "0px" },
                    marginBottom: "20px",
                  }}
                >
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={4}>
                      <Card>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16, color: "black" }}
                            gutterBottom
                          >
                            Wallet Total Balance
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 18,
                              mb: 1.5,
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            {riderList?.walletAmount}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16, color: "black" }}
                            gutterBottom
                          >
                            Total Withdrawal
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 18,
                              mb: 1.5,
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            {riderList?.TotalWithdrawal}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16, color: "black" }}
                            gutterBottom
                          >
                            Total Order
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 18,
                              mb: 1.5,
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            {riderList?.totalOrder}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Paper
          sx={{ marginTop: { xs: "20px", sm: "15px", md: "15px", lg: "25px" } }}
        >
          <Box>
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                m: 1,
                padding: "12px",
                fontWeight: "bold",
              }}
            >
              Device Details
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: {
                lg: "1250px",
              },
              marginTop: "-27px",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                marginBottom: "20px",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ boxShadow: 0, paddingLeft: "0!important" }}
              >
                <Table
                  aria-label="simple table"
                  sx={{ paddingLeft: "0!important", minWidth: 1140 }}
                >
                  <TableHead sx={{ paddingLeft: "0!important" }}>
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f9fb",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Is Logged In
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Device Id
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Model
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Platform
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Device Version
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        App Version
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Registration Date
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Last Used
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Ip
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#fff",
                      paddingLeft: "0!important",
                    }}
                  >
                    {deviceDetailsList?.map((row) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {deviceStatus(row?.isLoggedIn) || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.deviceID || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.model || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.platform || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.version || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.appVersion || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {moment(row.lastUsed).format("MM-DD-YYYY LT")}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.ip || "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {deviceDetailsList && deviceDetailsList.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </TableContainer>
            </Box>
          </Box>
        </Paper>

        <Paper
          sx={{ marginTop: { xs: "20px", sm: "15px", md: "15px", lg: "25px" } }}
        >
          <Box>
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                m: 1,
                padding: "12px",
                fontWeight: "bold",
              }}
            >
              Plan Transaction History
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: {
                lg: "1250px",
              },
              marginTop: "-27px",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                marginBottom: "20px",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ boxShadow: 0, paddingLeft: "0!important" }}
              >
                <Table
                  aria-label="simple table"
                  sx={{ paddingLeft: "0!important", minWidth: 1233 }}
                >
                  <TableHead sx={{ paddingLeft: "0!important" }}>
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f9fb",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Plan Status
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Plan Name
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Plan Date
                      </TableCell> */}
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Start Date
                      </TableCell>{" "}
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        End Date
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Due Days
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Transaction Ref.No
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#fff",
                      paddingLeft: "0!important",
                    }}
                  >
                    {planHistoryList?.map((row) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {planStatus(row?.transactionStatus) || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.planName || "-"}
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </TableCell> */}
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {moment(row.startDate).format("MM-DD-YYYY")}
                          </TableCell>

                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {moment(row.endDate).format("MM-DD-YYYY")}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            1 month
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.transactionNo || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.amount || "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {planHistoryList && planHistoryList.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </TableContainer>
            </Box>
          </Box>
        </Paper>

        <Paper sx={{ marginTop: "25px" }}>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            margin="8px"
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                padding: "12px",
                fontWeight: "bold",
              }}
            >
              Order History
            </Typography>
            {Role.role == 1 ? (
              <Controls.Button
                text="View All"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "blue",
                  backgroundColor: "#fff",
                  ":hover": { bgcolor: "#F1F5F9" },
                  textTransform: "none",
                  borderRadius: "15px",
                  border: 1,
                  borderColor: "#d3d3d3",
                  boxShadow: 0,
                  height: "40px",
                }}
                onClick={() => {
                  navigate("/orders", {
                    state: state,
                  });
                }}
              />
            ) : null}
          </Box>
          <Box
            sx={{
              maxWidth: {
                lg: "1250px",
              },
              marginTop: "-27px",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                marginBottom: "20px",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ boxShadow: 0, paddingLeft: "0!important" }}
              >
                <Table
                  aria-label="simple table"
                  sx={{ paddingLeft: "0!important", minWidth: 1258 }}
                >
                  <TableHead sx={{ paddingLeft: "0!important" }}>
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f9fb",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Order No
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        User Name
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Source Location
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Destination Location
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Vehicle Type
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Rating
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Amount
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Date
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Status
                      </TableCell>
                      {Role.role == 1 ? (
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Action
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#fff",
                      paddingLeft: "0!important",
                    }}
                  >
                    {orderDetailsList?.map((row) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {Role.role == 1 ? (
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/orderdetails", {
                                    state: row?.id,
                                  });
                                }}
                              >
                                {row?.orderNumber || "-"}
                              </Typography>
                            </TableCell>
                          ) : null}
                          {Role.role == 1 ? (
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/userdetails", {
                                    state: {
                                      user: row?.userID,
                                    },
                                  });
                                }}
                              >
                                {row?.customerName || "-"}
                              </Typography>
                            </TableCell>
                          ) : null}
                          {Role.role == 2 ? (
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                              >
                                {row?.orderNumber || "-"}
                              </Typography>
                            </TableCell>
                          ) : null}
                          {Role.role == 2 ? (
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                              >
                                {row?.customerName || "-"}
                              </Typography>
                            </TableCell>
                          ) : null}
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.startLocation || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.endLocation || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.VehicalTypeName || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.rating ? (
                              <span
                                style={{
                                  color: "blue",
                                  marginLeft: "5px",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  handleRatingModel(e);
                                  getRating(row?.id);
                                }}
                              >
                                {" "}
                                {row?.rating || "-"}
                              </span>
                            ) : "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.paybleCost || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </TableCell>

                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#000000c7",
                              }}
                            >
                              {orderStatus(row?.orderStatus) || "-"}
                            </Typography>
                          </TableCell>
                          {Role.role == 1 ? (
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              <Tooltip placement="bottom" title="View Order">
                                <button
                                  className="action-button view"
                                  onClick={() => {
                                    navigate("/orderdetails", {
                                      state: row?.id,
                                    });
                                  }}
                                >
                                  <GrView />
                                </button>
                              </Tooltip>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {orderDetailsList && orderDetailsList.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </TableContainer>
            </Box>
          </Box>
        </Paper>

        <Paper sx={{ marginTop: "25px" }}>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            margin="8px"
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                padding: "12px",
                fontWeight: "bold",
              }}
            >
              withdraw List
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: {
                lg: "1250px",
              },
              marginTop: "-27px",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                marginBottom: "20px",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ boxShadow: 0, paddingLeft: "0!important" }}
              >
                <Table
                  aria-label="simple table"
                  sx={{ paddingLeft: "0!important", minWidth: 1258 }}
                >
                  <TableHead sx={{ paddingLeft: "0!important" }}>
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f9fb",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        withdraw Amount
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#fff",
                      paddingLeft: "0!important",
                    }}
                  >
                    {WithdrawalsList?.map((row) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {row?.withdrawTo || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </TableCell>


                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {WithdrawalsList && WithdrawalsList.length === 0 ? (
                  <div
                    className="noDataFound"
                    style={{
                      display: "flex",
                    }}
                  >
                    {" "}
                    No Data Found
                  </div>
                ) : null}
              </TableContainer>
            </Box>
          </Box>
        </Paper>



        <Box sx={{ marginTop: "23px", width: "100%" }}>
          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <Card
                  sx={{
                    boxShadow: 0,
                    color: "black",
                  }}
                >
                  {row?.docType == 1 ? (
                    <Grid container spacing={{ xs: 2, md: 3 }} sx={{ px: 1 }}>
                      <Grid
                        item
                        xs={5}
                        sm={5}
                        md={6}
                        lg={4}
                        xl={4}
                        sx={{ px: 2 }}
                      >
                        <Typography
                          variant="h3"
                          textAlign="left"
                          sx={{ py: 1 }}
                        >
                          Basic Details
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>Name: </b>
                          {row?.firstName}
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>Birth date: </b>{" "}
                          {row?.dob}
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Document Status:{" "}
                          </b>{" "}
                          {documentStatus(row?.isApproved)}
                        </Typography>

                        <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "red",
                                backgroundColor: "#fff",
                                ":hover": { bgcolor: "#F1F5F9" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                              }}
                              onClick={() => {
                                handleRejectModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}

                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Approve"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "#4F46E5",
                                ":hover": { bgcolor: "#4F46E5" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                handleSwitchModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sm={5}
                        md={6}
                        lg={8}
                        xl={8}
                        sx={{ px: 2, py: 1 }}
                      >
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Profile Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpen(i);
                                }}
                                src={row?.imageFront}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageFront}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  ) : null}
                </Card>
              );
            })}
          </Item>

          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <>
                  <Card
                    sx={{
                      boxShadow: 0,

                      color: "black",
                    }}
                  >
                    {row.docType == 2 ? (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ px: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Passport
                          </Typography>
                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Expiration Date:{" "}
                            </b>{" "}
                            {row?.expirationDate}
                          </Typography>
                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>

                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Front Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenPassport(i);
                                  }}
                                  src={row?.imageFront}
                                  sx={{
                                    width: {
                                      sm: "100px",
                                      md: "100px",
                                      lg: "150px",
                                      xs: "100%",
                                    },
                                    height: {
                                      lg: "100px",
                                      xs: "150px",
                                    },
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Back Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenPassportBack();
                                  }}
                                  src={row?.imageBack}
                                  sx={{
                                    width: {
                                      sm: "100px",
                                      md: "100px",
                                      lg: "150px",
                                      xs: "100%",
                                    },
                                    height: {
                                      lg: "100px",
                                      xs: "150px",
                                    },
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={openPassport}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClosePassport}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={openPassportBack}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClosePassportBack}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageBack}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                </>
              );
            })}
          </Item>

          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <Card
                  sx={{
                    boxShadow: 0,

                    color: "black",
                  }}
                >
                  {row?.docType == 3 ? (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{ px: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{ px: 2 }}
                      >
                        <Typography
                          variant="h3"
                          textAlign="left"
                          sx={{ py: 1 }}
                        >
                          Identity Card
                        </Typography>
                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "4px" }}>Identity No: </b>{" "}
                          {row?.identityNumber}
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Document Status:{" "}
                          </b>{" "}
                          {documentStatus(row?.isApproved)}
                        </Typography>

                        <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "red",
                                backgroundColor: "#fff",
                                ":hover": { bgcolor: "#F1F5F9" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                              }}
                              onClick={() => {
                                handleRejectModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}

                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Approve"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "#4F46E5",
                                ":hover": { bgcolor: "#4F46E5" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                handleSwitchModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        sx={{ px: 2, py: 1 }}
                      >
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Front Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenIdentityCard();
                                }}
                                src={row?.imageFront}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Back Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenIdentityCardBack();
                                }}
                                src={row?.imageBack}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Dialog
                        open={openIdentityCard}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseIdentityCard}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageFront}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={openIdentityCardBack}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseIdentityCardBack}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageBack}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  ) : null}
                </Card>
              );
            })}
          </Item>

          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <Card
                  sx={{
                    boxShadow: 0,

                    color: "black",
                  }}
                >
                  {row?.docType == 4 ? (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{ px: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{ px: 2 }}
                      >
                        <Typography
                          variant="h3"
                          textAlign="left"
                          sx={{ py: 1 }}
                        >
                          Driver License
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Expiration Date:{" "}
                          </b>{" "}
                          {row?.expirationDate}
                        </Typography>
                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Document Status:{" "}
                          </b>{" "}
                          {documentStatus(row?.isApproved)}
                        </Typography>

                        <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "red",
                                backgroundColor: "#fff",
                                ":hover": { bgcolor: "#F1F5F9" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                              }}
                              onClick={() => {
                                handleRejectModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}

                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Approve"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "#4F46E5",
                                ":hover": { bgcolor: "#4F46E5" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                handleSwitchModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        sx={{ px: 2, py: 1 }}
                      >
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Front Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenDriverLicense();
                                }}
                                src={row?.imageFront}
                                sx={{
                                  width: {
                                    sm: "100px",
                                    md: "100px",
                                    lg: "150px",
                                  },
                                  height: 100,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Back Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenDriverLicenseBack();
                                }}
                                src={row?.imageBack}
                                sx={{
                                  width: {
                                    sm: "100px",
                                    md: "100px",
                                    lg: "150px",
                                  },
                                  height: 100,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Other Document
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenDriverLicenseOther();
                                }}
                                src={row?.otherDocuments}
                                sx={{
                                  width: {
                                    sm: "100px",
                                    md: "100px",
                                    lg: "150px",
                                  },
                                  height: 100,
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Dialog
                        open={openDriverLicense}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDriverLicense}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageFront}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={openDriverLicenseBack}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDriverLicenseBack}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageBack}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                      <Dialog
                        open={openDriverLicenseOther}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDriverLicenseOther}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.otherDocuments}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  ) : null}
                </Card>
              );
            })}
          </Item>

          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <Card
                  sx={{
                    boxShadow: 0,

                    color: "black",
                  }}
                >
                  {row?.docType == 5 ? (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{ px: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{ px: 2 }}
                      >
                        <Typography
                          variant="h3"
                          textAlign="left"
                          sx={{ py: 1 }}
                        >
                          Vehicle Document
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>Vehicle Type: </b>{" "}
                          {row?.bike}
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Document Status:{" "}
                          </b>{" "}
                          {documentStatus(row?.isApproved)}
                        </Typography>
                        <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "red",
                                backgroundColor: "#fff",
                                ":hover": { bgcolor: "#F1F5F9" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                              }}
                              onClick={() => {
                                handleRejectModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}

                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Approve"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "#4F46E5",
                                ":hover": { bgcolor: "#4F46E5" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                handleSwitchModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        sx={{ px: 2, py: 1 }}
                      >
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Front Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenVehicleDocument();
                                }}
                                src={row?.imageFront}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Dialog
                        open={openVehicleDocument}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseVehicleDocument}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageFront}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={openVehicleDocumentBack}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseVehicleDocumentBack}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageBack}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  ) : null}
                </Card>
              );
            })}
          </Item>

          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <Card
                  sx={{
                    boxShadow: 0,

                    color: "black",
                  }}
                >
                  {row?.docType == 6 ? (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{ px: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{ px: 2 }}
                      >
                        <Typography
                          variant="h3"
                          textAlign="left"
                          sx={{ py: 1 }}
                        >
                          Registration Certificate
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Vehicle Transport Year:{" "}
                          </b>{" "}
                          {row?.vehicleTransportyear}
                        </Typography>
                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Document Status:{" "}
                          </b>{" "}
                          {documentStatus(row?.isApproved)}
                        </Typography>
                        <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "red",
                                backgroundColor: "#fff",
                                ":hover": { bgcolor: "#F1F5F9" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                              }}
                              onClick={() => {
                                handleRejectModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}

                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Approve"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "#4F46E5",
                                ":hover": { bgcolor: "#4F46E5" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                handleSwitchModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        sx={{ px: 2, py: 1 }}
                      >
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Front Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenRegistrationCertificate();
                                }}
                                src={row?.imageFront}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Back Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenRegistrationCertificateBack();
                                }}
                                src={row?.imageBack}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Dialog
                        open={openRegistrationCert}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseRegistrationCertificate}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageFront}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={openRegistrationCertBack}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseRegistrationCertificateBack}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageBack}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  ) : null}
                </Card>
              );
            })}
          </Item>

          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <Card
                  sx={{
                    boxShadow: 0,

                    color: "black",
                  }}
                >
                  {row?.docType == 7 ? (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{ px: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{ px: 2 }}
                      >
                        <Typography
                          variant="h3"
                          textAlign="left"
                          sx={{ py: 1 }}
                        >
                          Permit
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Document Status:{" "}
                          </b>{" "}
                          {documentStatus(row?.isApproved)}
                        </Typography>
                        <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "red",
                                backgroundColor: "#fff",
                                ":hover": { bgcolor: "#F1F5F9" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                              }}
                              onClick={() => {
                                handleRejectModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}

                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Approve"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "#4F46E5",
                                ":hover": { bgcolor: "#4F46E5" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                handleSwitchModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        sx={{ px: 2, py: 1 }}
                      >
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Front Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenPermit();
                                }}
                                src={row?.imageFront}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Back Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenPermitBack();
                                }}
                                src={row?.imageBack}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Dialog
                        open={openPermit}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClosePermit}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageFront}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={openPermitBack}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClosePermitBack}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageBack}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  ) : null}
                </Card>
              );
            })}
          </Item>

          <Item sx={{ marginBottom: "10px" }}>
            {riderDocsList?.map((row, i) => {
              return (
                <Card
                  sx={{
                    boxShadow: 0,

                    color: "black",
                  }}
                >
                  {row?.docType == 8 ? (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{ px: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{ px: 2 }}
                      >
                        <Typography
                          variant="h3"
                          textAlign="left"
                          sx={{ py: 1 }}
                        >
                          Visa Document
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Expiration Date:{" "}
                          </b>{" "}
                          {row?.expirationDate}
                        </Typography>

                        <Typography
                          variant="h5"
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          sx={{ py: 0.5 }}
                        >
                          <b style={{ marginRight: "5px" }}>
                            Document Status:{" "}
                          </b>{" "}
                          {documentStatus(row?.isApproved)}
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ py: 1 }}
                        >
                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "red",
                                backgroundColor: "#fff",
                                ":hover": { bgcolor: "#F1F5F9" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                              }}
                              onClick={() => {
                                handleRejectModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}

                          {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                            <Controls.Button
                              text="Approve"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "#4F46E5",
                                ":hover": { bgcolor: "#4F46E5" },
                                textTransform: "none",
                                borderRadius: "15px",
                                border: 1,
                                borderColor: "#d3d3d3",
                                boxShadow: 0,
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                handleSwitchModel();
                                setSwitchId(row?.id);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        sx={{ px: 2, py: 1 }}
                      >
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                textAlign="left"
                                sx={{ py: 1 }}
                              >
                                Front Image
                              </Typography>
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="profile_image"
                                onClick={(e) => {
                                  handleClickOpenVisaDocument();
                                }}
                                src={row?.imageFront}
                                sx={{ width: 150, height: 100 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Dialog
                        open={openVisaDocument}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseVisaDocument}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageFront}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={openVisaDocumentBack}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseVisaDocumentBack}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent>
                          <img
                            style={{
                              width: "50%",
                              height: "100%",
                              marginLeft: "127px",
                            }}
                            src={row?.imageBack}
                            alt=""
                          />
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  ) : null}
                </Card>
              );
            })}
          </Item>
        </Box>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  sx={{
                    backgroundColor: "#fff",
                    ":hover": { color: "#fff" },
                    marginRight: { xs: "10px", sm: "0px" },
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDelete(state)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>

      <Modal
        keepMounted
        open={openReviewModel}
        onClose={handleCloseReview}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="modelBox"
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
            height: "50%",
            overflow: "scroll",
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            User Review
          </Typography>
          <Divider sx={{ my: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
          {reviewList?.map((row) => {
            return (
              <Box sx={{ display: "flex", my: 2, gap: 2 }}>
                <Avatar aria-label="recipe" src={row?.UserProfilePic}></Avatar>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "86%" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ color: "black", fontWeight: "bold" }}
                    >
                      {row?.Username}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={{ color: "black" }}
                    >
                      {row?.remark}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ color: "black" }}
                    >
                      {row?.rating} / 5{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
          {reviewList && reviewList.length === 0 ? (
            <div
              className="noDataFound"
              style={{
                display: "flex",
              }}
            >
              {" "}
              No Review
            </div>
          ) : null}
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openRatingModel}
        onClose={handleCloseRating}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="modelBox"
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
            height: "auto",
            overflow: "scroll",
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "bold", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {" "}
            User Rating
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  handleCloseRating()
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </Typography>

          <Divider sx={{ my: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
          <Stack spacing={1}>
            <Rating
              name="half-rating-read"
              value={Ratings && Ratings?.rating ? parseFloat(Ratings?.rating) : 3.5}
              precision={0.5}
              readOnly
              sx={{ fontSize: '32px' }}
            />
          </Stack>
          <Box sx={{ display: "flex", alignItems: 'center', my: 2, gap: 2 }}>
            <Avatar aria-label="recipe" src={Ratings?.UserProfilePic}></Avatar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "86%" }}>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ color: "black", fontWeight: "bold" }}
                >
                  {Ratings?.Username}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", my: 2, gap: 2 }}>
            <Box sx={{ width: "86%" }}>
              <Typography
                component="div"
                variant="h5"
                sx={{ color: "black", fontWeight: "bold" }}
              >
                {Ratings?.remark}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openBankModel}
        onClose={handleCloseBank}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Form onSubmit={handleSubmit} id="addNewBuyer">
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
              height: "58%",
              overflow: "scroll",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Bank Details
            </Typography>
            <Divider sx={{ my: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "1px",
              }}
            >
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
                container
                spacing={1}
                sx={{ display: "contents" }}
              >
                <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
                  <Controls.Input
                    name="bankName"
                    onChange={handleInputChange}
                    value={values.bankName}
                    label="Bank Name"
                    sx={{ marginTop: "0px !important" }}
                    error={errors.bankName}
                    helperText={errors.bankName}
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Bank Account Holder Name"
                    name="accountHolderName"
                    onChange={handleInputChange}
                    value={values.accountHolderName}
                    error={errors.accountHolderName}
                    helperText={errors.accountHolderName}
                    sx={{ marginTop: "0px !important" }}
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Bank Account Number"
                    name="accountNumber"
                    onChange={handleInputChange}
                    value={values.accountNumber}
                    error={errors.accountNumber}
                    helperText={errors.accountNumber}
                    sx={{ marginTop: "0px !important" }}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Bank IFSC Code"
                    name="bankIFSCCode"
                    onChange={handleInputChange}
                    value={values.bankIFSCCode}
                    error={errors.bankIFSCCode}
                    helperText={errors.bankIFSCCode}
                    sx={{ marginTop: "0px !important" }}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>{" "}
                <Grid item xs={12}>
                  <Controls.Input
                    label="Bank Address"
                    name="bankAddress"
                    onChange={handleInputChange}
                    value={values.bankAddress}
                    error={errors.bankAddress}
                    helperText={errors.bankAddress}
                    sx={{ marginTop: "0px !important" }}
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>{" "}
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "1rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginRight: "-15px",
                  }}
                  text="Cancel"
                  onClick={handleCloseBank}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Save"
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Modal>

      <Modal
        keepMounted
        open={openWithdrawModel}
        onClose={handleCloseWithdraw}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Form onSubmit={handleWithdrawSubmit} id="addNewBuyer">
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
              height: "30%",
              overflow: "scroll",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "bold", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Withdraw Amount
              <Tooltip title="Close">
                <IconButton
                  onClick={() => {
                    handleCloseWithdraw()
                  }}
                >
                  <Close sx={{ color: "black" }} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Divider sx={{ my: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "1px",
              }}
            >
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
                container
                spacing={1}
                sx={{ display: "contents" }}
              >
                <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
                  <Controls.Input
                    name="amounts"
                    onChange={handleInputChange1}
                    value={values1.amounts}
                    label="Amount"
                    sx={{ marginTop: "0px !important" }}
                    error={errors1.amounts}
                    helperText={errors1.amounts}
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "25px" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginRight: "-15px",
                  }}
                  text="Cancel"
                  onClick={handleCloseWithdraw}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Save"
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Modal>
    </>
  );
};

export default RiderDetails;
