import axiosRequest from "../axios/axios";

//get Banner List
export const getBannerLists = async (queryString) => {
  try {
    const response = await axiosRequest.get(`bannerList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Banner

export const addBanner = async (payload) => {
  try {
    const response = await axiosRequest.post(`addBanner`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Banner Delete
export const DeleteBanner = async (id) => {
  try {
    const response = await axiosRequest.delete(`deleteBanner/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Banner Status Update

export const StatusUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.post(`statusBanner/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Banner update

export const updateBanner = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`updateBanner/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
