import axiosRequest from "../axios/axios";

//get Offer List
export const getOfferLists = async (queryString) => {
  try {
    const response = await axiosRequest.get(`offersList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Offer

export const addOffer = async (payload) => {
  try {
    const response = await axiosRequest.post(`offersAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Offer Delete
export const DeleteOffer = async (id) => {
  try {
    const response = await axiosRequest.delete(`offersDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Offer Status Update

export const StatusUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `offersStatusUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//Offer update

export const updateOffer = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`offersUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
