import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useForm, Form } from "../../Utils/useForm";
import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Controls from "../controls/Controls";
import { FormHelperText } from "@mui/material";
import {
  addCityByState,
  updateCity,
  updateStateLocation,
  getDummpedCityList,
  getDdbStateList,
  allDumppedCities
} from "../../state/action/locationAction";
import axios from "axios";

const initialValues = {
  name: "",
  stateId: "",
  north: "",
  south: "",
  west: "",
  east: "",
  contryId: ""
};
const styles = {
  position: "absolute",
  top: "50%",
  left: { xs: "50%", sm: "65%", md: "50", lg: "50%", xl: "50%" },
  transform: "translate(-50%, -50%)",
  width: { xs: "600px", sm: "600px", md: "600px", lg: "600px", xl: "600px" },
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};
function AddCityModel(props) {
  const {
    handleCloseAdd,
    openAddModel,
    isEdit,
    page,
    enqueueSnackbar,
    CityListData,
    cityData,
    stateDropdown,
    selectedCountryId,
    selectedStateId,
    allCountries
  } = props;

  const [allDummpedCities, setallDummpedCities] = useState([]);
  const [dbStatesListst, setdbStatesListst] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("stateId" in fieldValues)
      temp.stateId = fieldValues.stateId ? "" : "This field is required.";
    if ("contryId" in fieldValues)
      temp.contryId = fieldValues.contryId ? "" : "This field is required.";

    // if ("north" in fieldValues) {
    //   if (fieldValues.north !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.north)) {
    //       temp.north = "Invalid Input";
    //     } else {
    //       temp.north = "";
    //     }
    //   } else {
    //     temp.north = "This field is required.";
    //   }
    // }

    // if ("south" in fieldValues) {
    //   if (fieldValues.south !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.south)) {
    //       temp.south = "Invalid Input";
    //     } else {
    //       temp.south = "";
    //     }
    //   } else {
    //     temp.south = "This field is required.";
    //   }
    // }

    // if ("west" in fieldValues) {
    //   if (fieldValues.west !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.west)) {
    //       temp.west = "Invalid Input";
    //     } else {
    //       temp.west = "";
    //     }
    //   } else {
    //     temp.west = "This field is required.";
    //   }
    // }

    // if ("east" in fieldValues) {
    //   if (fieldValues.east !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.east)) {
    //       temp.east = "Invalid Input";
    //     } else {
    //       temp.east = "";
    //     }
    //   } else {
    //     temp.east = "This field is required.";
    //   }
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        stateId: values.stateId,
        name: values.name,
        countryId: values.contryId,
        north: values.north,
        south: values.south,
        west: values.west,
        east: values.east,
      };
      if (isEdit) {
        const res = await updateCity(cityData.id, payload);
        if (res && res.status === true) {
          CityListData(page, "");
          handleCloseAdd();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          // CityListData(page, "");
          // handleCloseAdd();
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await addCityByState(payload);
        if (res && res.status === true) {
          CityListData(page, "");
          handleCloseAdd();
          resetForm();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          // CityListData(page, "");
          // handleCloseAdd();
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };
  console.log('cityData ------------->', cityData);
  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        name: cityData?.dumpCityId,
        contryId: cityData?.countryId,
        stateId: cityData?.stateId,
        north: cityData.north,
        south: cityData.south,
        west: cityData.west,
        east: cityData.east,
      });
      getStateList(cityData?.countryId)
      getAllDumpedCity(cityData?.stateId)
    } else {
      resetForm();
    }
  }, [isEdit]);

  const getAllDumpedCity = async (id) => {
    const getDummyCity = await getDummpedCityList(id);
    if (getDummyCity.status == true) {
      setallDummpedCities(getDummyCity.data)
    } else {
      enqueueSnackbar(getDummyCity.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
      setallDummpedCities([]);
    }
  }

  const hadleStateChange = (e) => {
    setValues({ ...values, stateId: e.target.value })
    getAllDumpedCity(e.target.value)
  }

  const getStateList = async (id) => {
    const getDummyStates = await getDdbStateList(id);
    if (getDummyStates.status == true) {
      setdbStatesListst(getDummyStates.data)
    } else {
      enqueueSnackbar(getDummyStates.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
      setdbStatesListst([]);
    }
  }

  const getAllDumppedCities = async (id) => {
    const getAllCities = await allDumppedCities(id);
    if (getAllCities.status == true) {
      setallDummpedCities(getAllCities.data)
    } else {
      enqueueSnackbar(getAllCities.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
      setallDummpedCities([]);
    }
  }

  const handelCountrySelect = async (e) => {
    setValues({ ...values, contryId: e.target.value })
    getStateList(e.target.value)
  }

  const handelStateSelect = async (e) => {
    setValues({ ...values, stateId: e.target.value })
    getAllDumppedCities(e.target.value)
  }


  const handleStateCountry = async (event) => {
    const cityId = event.target.value;
    const selectedCity = allDummpedCities.find(item => item.id === cityId);
    const selectedCountry = allCountries.find(item => item.id === values.contryId);
    const selectedState = dbStatesListst.find(item => item.id === values.stateId);
    if (selectedCity) {
      try {
        const qry = `${selectedCountry.name},${selectedState.name},${selectedCity.name}`
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: {
            address: qry,
            key: 'AIzaSyD2cfignyIQC94CJmpxD5XdLakeuyAOOnQ' // Replace 'YOUR_API_KEY' with your actual API key
          }
        });

        const { data } = response;
        if (data.status === "OK" && data.results?.length) {
          const bounds = data.results[0].geometry.bounds;

          if (bounds) {
            setValues(prevValues => ({
              ...prevValues,
              name: cityId,
              north: bounds.northeast.lat,
              south: bounds.southwest.lat,
              west: bounds.southwest.lng,
              east: bounds.northeast.lng
            }));
          }
        } else {
          enqueueSnackbar("Selected city not found", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        enqueueSnackbar("Selected city not found", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };


  return (
    <>
      <Modal
        // keepMounted
        open={openAddModel}
        onClose={() => {
          handleCloseAdd();
          resetForm();
        }}
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            {isEdit ? "Edit City" : "Add City"}
          </Typography>
          <Form sx={{ width: "100%" }} onSubmit={handleFormSubmit}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Box
                sx={{ marginTop: "-25px", marginBottom: "2rem", width: "100%" }}
              >
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.SelectBox
                    className="input"
                    label="Select Country"
                    fullWidth
                    id="contryId"
                    name="contryId"
                    value={values.contryId}
                    onChange={handelCountrySelect}
                    options={allCountries}
                    error={errors.contryId}
                    helperText={errors.contryId}
                  />
                  {errors && errors.contryId ? (
                    <FormHelperText
                      sx={{ color: "#f44336", marginLeft: "16px !important" }}
                    >
                      {errors.contryId}
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.SelectBox
                    className="input"
                    label="Select State"
                    fullWidth
                    id="stateId"
                    name="stateId"
                    value={values.stateId}
                    onChange={handelStateSelect}
                    options={dbStatesListst}
                    error={errors.stateId}
                    helperText={errors.stateId}
                  />
                  {errors && errors.stateId ? (
                    <FormHelperText
                      sx={{ color: "#f44336", marginLeft: "16px !important" }}
                    >
                      {errors.stateId}
                    </FormHelperText>
                  ) : null}
                </Grid>


                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  {/* <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="City Name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name}
                    fullWidth
                  /> */}
                  <Controls.SelectBox
                    className="input"
                    label="Select City"
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleStateCountry}
                    options={allDummpedCities}
                    error={errors.name}
                    helperText={errors.name}
                  />
                </Grid>

                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <table border={4} style={{ color: "black", border: "1px solid #dddddd", padding: "10px", borderCollapse: "collapse", width: "100%" }}>
                    {/* <th> */}
                    <tr>
                      <th>North</th>
                      <th>South</th>
                      <th>West</th>
                      <th>East</th>
                    </tr>
                    <tr>
                      <td>{values?.north}</td>
                      <td>{values?.south}</td>
                      <td>{values?.west}</td>
                      <td>{values?.east}</td>
                    </tr>
                  </table>
                </Grid>

                {/* <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="North"
                    variant="outlined"
                    color="primary"
                    label="North"
                    name="north"
                    value={values.north}
                    onChange={handleInputChange}
                    error={errors.north}
                    helperText={errors.north}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="South"
                    variant="outlined"
                    color="primary"
                    label="South"
                    name="south"
                    value={values.south}
                    onChange={handleInputChange}
                    error={errors.south}
                    helperText={errors.south}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="West"
                    variant="outlined"
                    color="primary"
                    label="West"
                    name="west"
                    value={values.west}
                    onChange={handleInputChange}
                    error={errors.west}
                    helperText={errors.west}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="East"
                    variant="outlined"
                    color="primary"
                    label="East"
                    name="east"
                    value={values.east}
                    onChange={handleInputChange}
                    error={errors.east}
                    helperText={errors.east}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid> */}

              </Box>

              <Grid container justifyContent="end">
                <Grid item xs={12} textAlign="end">
                  <Controls.Button
                    style={{ backgroundColor: "#fff", color: "#000" }}
                    text="Cancel"
                    onClick={() => {
                      handleCloseAdd();
                      resetForm();
                    }}
                  />

                  <Controls.Button
                    sx={{ marginLeft: "15px" }}
                    text="Submit"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Modal>
    </>
  );
}

export default AddCityModel;
