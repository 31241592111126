import axiosRequest from "../axios/axios";

//get Vehical List
export const getVehicalList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`vehicalsList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Vehical

export const addVehical = async (payload) => {
  try {
    const response = await axiosRequest.post(`vehicalAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Vehical Delete
export const DeleteVehical = async (id) => {
  try {
    const response = await axiosRequest.delete(`vehicalDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Vehical Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`vehicalStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Vehical update

export const updateVehical = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`vehicalUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
