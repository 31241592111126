import React, { useEffect, useState } from "react";
import {
    Typography,
    InputBase,
    Pagination,
    Drawer,
    Stack,
    styled,
   
    TableRow,
    tableCellClasses,
   
    TableCell,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    Modal,
    Chip
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";

import { useLocation, useNavigate } from "react-router";

import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


const initialValues = {
    productName: "",
    status: "",
    margin: "",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
       
        color: "#000",
        fontSize: 14,
        border: 0,
        fontWeight: 600,
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: "rgb(17, 24, 39)",
        border: 0,
        paddingLeft: "25px",

        paddingTop: "10px",
        paddingBottom: "10px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
        backgroundColor: "#fff",
        ":hover": { backgroundColor: "rgb(248 248 248)!important" },

        "&:nth-of-type(odd)": {
            backgroundColor: "rgb(250 250 250)!important",
        },
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
}));


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "0",
    outline: "0",
    bgcolor: "#fff",
    borderRadius: "10px",
    p: 3,
};

const Payment = () => {
    const [value, setValue] = useState("1");
    const [deleteValue, setDeleteValue] = useState(false);
    const [switchId, setSwitchId] = useState(null);
    
    const [openSwitchModel, setOpenSwitchModel] = useState(false);
    const [status, setStatus] = useState();
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [search, setSearch] = useState("");
    const [flagName, setflagName] = useState(false);
    const [flagPrice, setflagPrice] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [filterData, setFilterData] = useState(true);
    const [filter, setFilter] = useState(false);
    const [productList, setProductList] = useState([]);
    const [sortFlag, setSortFlag] = useState(false);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [notify, setNotify] = useState({});
    const [fieldName, setFieldName] = useState("");
    const [range, setRange] = useState([10, 57]);

    const riderStatus = [
        { id: "1", title: "Active" },
        { id: "0", title: "Inactive" },
        { id: "3", title: "Delete" },
    ];

    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangess = (event, newValue) => {
        setRange(newValue);
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const { state } = useLocation();
    const handleSwitchModel = () => setOpenSwitchModel(true);
    const handleCloseSwitch = () => setOpenSwitchModel(false);
    const handleCloseDelete = () => setOpenDeleteModel(false);
    const handleDeleteModel = () => setOpenDeleteModel(true);

    const handleChangePage = (e, value) => {
       
    };

    useEffect(() => {
        ProductList(page, "", "");
    }, [filterData, search, refresh]);

    const ProductList = async (page, name = "", flag) => {
       
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues);

    const dataShorting = (name) => {
        if (name === "name") {
            setflagName(!flagName);
            ProductList(page, name, !flagName);
        } else {
            setflagName(false);
            setSortFlag(!sortFlag);
        }

        if (name === "margin") {
            setflagPrice(!flagPrice);
            ProductList(page, name, !flagPrice);
        } else {
            setflagPrice(false);
            setSortFlag(!sortFlag);
        }
        setFieldName(name);
    };

    const handledelete = () => {
        setDeleteValue(true);
    };
    const closedelete = () => {
        setDeleteValue(false);
    };

    const handleChangeStatus = async () => {
     
    };

    const handleDelete = async (id) => {
       
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setValues({
            ...values,
            fromPrice: range[0],
            toPrice: range[1],
        });
        setFilterData(!filterData);
    };

    const openFilterDrawer = () => {
        setFilter(true);
    };
    const closeFilterDrawer = () => {
        setFilter(false);
        resetForm();
        setFilterData(!filterData);
    };
    const closeFilterDrawer1 = () => {
        setFilter(false);
    };

    const paymentMode = (paymentMode) => {
        const statusColors = {
            0: {
                key: "Cash",
                backgroundColor: "#ede7dd",
                color: "#fc7e00",
            },
            1: {
                key: "Online",
                backgroundColor: "green",
                color: "#ede7dd",
            },

        };

        return (
            <StyledChip
                style={{
                    height: "30px",
                    fontSize: "15px",
                }}
                sx={statusColors[paymentMode]}
                label={statusColors[paymentMode] ? statusColors[paymentMode].key : ""}
            />
        );
    };
    const paymentStatus = (paymentStatus) => {
        const statusColors = {
            0: {
                key: "Pending",
                backgroundColor: "#ede7dd",
                color: "#fc7e00",
            },
            1: {
                key: "Completed",
                backgroundColor: "green",
                color: "#ede7dd",
            },
            2: {
                key: "Canceled",
                backgroundColor: "#FDE3E1",
                color: "#ff0000",
            },
        };

        return (
            <StyledChip
                style={{
                    height: "30px",
                    fontSize: "15px",
                }}
                sx={statusColors[paymentStatus]}
                label={statusColors[paymentStatus] ? statusColors[paymentStatus].key : ""}
            />
        );
    };
    return (
        <>
            <Box>
                <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
                    <Grid
                        container
                        style={{
                            paddingTop: "40px",
                            paddingBottom: "40px",
                            alignItems: "center",
                        }}
                    >
                        <Grid item sm={7} xs={12}>
                            <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                                Payments
                            </Typography>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <Box
                                display="flex"
                                sx={{
                                    justifyContent: {
                                        xs: "block",
                                        sm: "flex-end",
                                        md: "flex-end",
                                        lg: "flex-end",
                                    },
                                    marginTop: { xs: "20px", md: "0", lg: "0" },
                                }}
                            >
                                <Box
                                    border={1}
                                    borderColor="rgba(0, 0, 0, .1)"
                                    alignItems="center"
                                    display="flex"
                                    backgroundColor="#ffffffa3"
                                    p={0.4}
                                    borderRadius={10}
                                >
                                    <SearchIcon
                                        style={{
                                            color: colors.grey[300],
                                        }}
                                        sx={{ ml: 1, fontSize: "24px" }}
                                    />
                                    <InputBase
                                        name="search"
                                        sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                                        placeholder="Search"
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                            setPage(1);
                                        }}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                                >
                                    <Button
                                        variant="outlined"
                                        startIcon={<FilterAltIcon />}
                                        onClick={openFilterDrawer}
                                        sx={{
                                            marginLeft: "15px",
                                            borderRadius: "50px",
                                            paddingTop: "5px",
                                            paddingBottom: "5px",
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            color: "#fff",
                                            ":hover": { bgcolor: "rgb(30, 41, 59)" },
                                            background: "rgb(30, 41, 59)",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            textTransform: "none",
                                        }}
                                    >
                                        Filter
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box

                >
                    <div>
                        <Box
                            sx={{ width: "98%", backgroundColor: "rgb(241, 245, 249)", margin: "auto" }}
                        >
                            <Box
                                sx={{

                                    border: 1,
                                    borderColor: "#eaeaea",
                                    maxHeight: "485px",
                                }}
                            >
                                <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    onClick={() => dataShorting("id")}
                                                >
                                                    Order Id
                                                    {flagName ? (
                                                        <ArrowUpwardIcon
                                                            sx={{
                                                                color: "rgba(107, 114, 128, .5)",
                                                                fontSize: "17px",
                                                                marginLeft: "3px",
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <ArrowDownwardIcon
                                                            sx={{
                                                                color: "rgba(107, 114, 128, .5)",
                                                                fontSize: "17px",
                                                                marginLeft: "3px",
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    onClick={() => dataShorting("name")}
                                                >
                                                    Name
                                                    {flagName ? (
                                                        <ArrowUpwardIcon
                                                            sx={{
                                                                color: "rgba(107, 114, 128, .5)",
                                                                fontSize: "17px",
                                                                marginLeft: "3px",
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <ArrowDownwardIcon
                                                            sx={{
                                                                color: "rgba(107, 114, 128, .5)",
                                                                fontSize: "17px",
                                                                marginLeft: "3px",
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell>Email</StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    onClick={() => dataShorting("amount")}
                                                >
                                                    Amount
                                                    {flagPrice ? (
                                                        <ArrowUpwardIcon
                                                            sx={{
                                                                color: "rgba(107, 114, 128, .5)",
                                                                fontSize: "17px",
                                                                marginLeft: "3px",
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <ArrowDownwardIcon
                                                            sx={{
                                                                color: "rgba(107, 114, 128, .5)",
                                                                fontSize: "17px",
                                                                marginLeft: "3px",
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell>Date</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    Payment Status
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    Payment Mode
                                                </StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <StyledTableRow

                                                hover
                                                sx={{ cursor: "pointer" }}
                                            >
                                                <StyledTableCell component="th" scope="row">
                                                    1
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    jack
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    jack@gmail.com
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    $ 112.22
                                                </StyledTableCell>

                                                <StyledTableCell>
                                                    03-17-2023
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                            color: "#000000c7",
                                                        }}
                                                    >
                                                        {paymentMode(0)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                            color: "#000000c7",
                                                        }}
                                                    >
                                                        {paymentStatus(1)}
                                                    </Typography>
                                                </StyledTableCell>

                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: {
                                    xs: "block",
                                    sm: "flex",
                                    md: "flex",
                                    lg: "flex",
                                },
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginLeft: {
                                    xs: "0",
                                    sm: "20px",
                                    md: "20px",
                                    lg: "20px",
                                },
                                marginRight: {
                                    xs: "0",
                                    sm: "15px",
                                    md: "15px",
                                    lg: "15px",
                                },
                                mt: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: "14px",
                                    marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                                }}
                            >
                                {" "}
                                Showing{" "}
                                {page * rowsPerPage > totalRecords
                                    ? totalRecords
                                    : page * rowsPerPage}{" "}
                                of {totalRecords} Results
                            </Box>
                            <Box>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handleChangePage}
                                    variant="outlined"
                                    shape="rounded"
                                    sx={{
                                        "& .MuiButtonBase-root ": {
                                            color: "#000 !important",
                                        },
                                        "& .Mui-selected ": {
                                            background: "#4b4b4e70 !important",
                                        },
                                        "& .Mui-disabled ": {
                                            background: "#6c696966 !important",
                                            color: "#000000 !important",
                                            opacity: 1,
                                        },
                                    }}
                                />
                            </Box>
                        </Box>


                        <Modal
                            keepMounted
                            open={openSwitchModel}
                            onClose={handleCloseSwitch}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                        >
                            <Box sx={style}>
                                <Typography
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                        color: "#000",
                                        marginBottom: "15px",
                                    }}
                                >
                                    Status Change
                                </Typography>
                                <label className="fontLight">
                                    Are you sure change status to {status} ?
                                </label>
                                <Grid container style={{ marginTop: "2rem" }}>
                                    <Grid item xs={9} className="text-end">
                                        <Controls.Button
                                            style={{ backgroundColor: "#fff", color: "#000" }}
                                            text="Cancel"
                                            onClick={handleCloseSwitch}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="text-end">
                                        <Controls.Button
                                            style={{ marginRight: "7px" }}
                                            text="Change"
                                            onClick={() => handleChangeStatus(switchId)}
                                            type="submit"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>

                        <Modal
                            keepMounted
                            open={openDeleteModel}
                            onClose={handleCloseDelete}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                        >
                            <Box sx={style}>
                                <label className="fontLight">
                                    Are you sure to want to delete this record?
                                </label>
                                <Grid container gap={1} style={{ marginTop: "1rem" }}>
                                    <Grid item xs={8} className="text-end">
                                        <Controls.Button
                                            text="Cancel"
                                            onClick={handleCloseDelete}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="text-end">
                                        <Controls.Button
                                            text="Delete"
                                            style={{ backgroundColor: "red" }}
                                            onClick={() => handleDelete(deleteId)}
                                            type="submit"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </div>
                </Box>
                <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: "#fff",
                        }}
                    >
                        <Box
                            sx={{
                                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                                padding: "10px 20px",
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                                >
                                    Filter
                                </Typography>

                                <AiOutlineCloseCircle
                                    className="close-button"
                                    onClick={closeFilterDrawer1}
                                />
                            </Stack>

                           
                        </Box>
                        <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
                            <button className="cancel-filter" onClick={closeFilterDrawer}>
                                Reset
                            </button>
                            <button className="order-filter-button" type="submit">
                                Filter
                            </button>
                        </Stack>
                    </form>
                </Drawer>
            </Box>
        </>
    );
};

export default Payment;
