import { Box } from "@mui/material";
import React from "react";
import OrderList from "./OrderList";

const Order = () => {
  return (
    <Box>
      <OrderList />
    </Box>
  );
};

export default Order;
