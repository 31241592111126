import { ColorModeContext, theme1, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Topbar from "./pages/global/Topbar";
import "react-toastify/dist/ReactToastify.min.css";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Layout from "./Component/Layout/Layout";
import CompanyLayout from "./Component/Layout/CompanyLayout";
import PrivateRoute from "./Routes/PrivetRoute";
import { useEffect, useState } from "react";
import { loadUserApi } from "./state/action/authAction";
import { useDispatch, useSelector } from "react-redux";
const App = () => {
  const [theme, colorMode] = useMode();

  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.substr(1);

  const Role = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    dispatch(loadUserApi());
  }, [])


  useEffect(() => {
    document.title = `Jyffit || ${pathname}`;
  }, [pathname]);

  let routeElement = null;

  if (Role && Role.role) {
    routeElement =
      Role.role == 1 ? (
        <Layout />
      ) : (
        <CompanyLayout />
      )
  }


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme} theme1={theme1}>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/companylogin" element={<Login />} />
          <Route exact path="/SignUp" element={<SignUp />} />
          <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="*" element={<PrivateRoute path="/login">
            {routeElement}
          </PrivateRoute>} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
