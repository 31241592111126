import axios from "axios";
import { store } from "../store";

const axiosRequest = axios.create({

  //baseURL: "https://api.jyffit.com/api/v1",
  //baseURL: "http://localhost:4005/api/v1",
  baseURL: process.env.REACT_APP_API_URL,
});

axiosRequest.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.token;
  config.params = config.params || {};
  config.headers["Authorization"] = token;
  return config;
});

export default axiosRequest;
