import axiosRequest from "../axios/axios";

//get PlanTransactionList
export const getPlanTransactionList = async (queryString) => {
  try {
    const response = await axiosRequest.get(
      `planTransactionList` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//get OrderTransactionList
export const getOrderTransactionList = async (queryString) => {
  try {
    const response = await axiosRequest.get(
      `orderTransactionList` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//plan Status Update

export const PlanStatusUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `planTransactionStatusUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//order Status Update

export const OrderStatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(
      `orderTransactionStatusUpdate/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//order dropdown

export const getOrderDropdown = async () => {
  try {
    const response = await axiosRequest.get(`getPamentMode`);
    return response.data;
  } catch (error) {
    return error;
  }
};
