import React from "react";
import Topbar from "../../pages/global/Topbar";
import { MyProSidebarProvider } from "../../pages/global/companysidebar/companysidebarContext";
import CompanyMain from "../Layout/CompanyMain";

function CompanyLayout() {
  return (
    <MyProSidebarProvider>
      <div style={{ height: "100%", width: "100%", overflowY: "scroll" }}>
        <main>
          <Topbar />
          <CompanyMain />
        </main>
      </div>
    </MyProSidebarProvider>
  );
}

export default CompanyLayout;