import React, { useEffect, useState } from "react";
import { Avatar, InputBase, MenuItem, Pagination, Select } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Tooltip, Drawer, Chip, FormLabel, TextField } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { Space } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import { Spin } from "antd";
import {
  getSubscriptionList,
  DeleteSubscription,
  SubscriptionStatusUpdate,
  DeleteSubscriptionTerms,
  DeleteSubscriptionDescription,
} from "../../state/action/subscription";
import { Navigate, useNavigate } from "react-router";
import moment from "moment";
import { GrView } from "react-icons/gr";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const initialValues = {
  name: "",
  planType: "",
  status: "",
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",

  p: 3,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const RecordPerPage = [
  { id: "10", title: "10" },
  { id: "20", title: "20" },
];

export default function SubscriptionList() {
  const [planList, setPlanList] = useState([]);

  const [planDescriptionList, setPlanDescriptionList] = useState([]);
  const [planFaqList, setPlanFaqList] = useState([]);

  const [pageLimit, setPageLimit] = useState(10);
  const [checked, setChecked] = useState(true);

  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const [status, setStatus] = useState();
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [flagName, setflagName] = useState(false);
  const [refresh, setRefresh] = useState(false);

  //delete description
  const [deleteDescriptionId, setDeleteDescriptionId] = useState(null);
  const [openDeleteDescriptionModel, setOpenDeleteDescriptionModel] =
    useState(false);

  const handleCloseDeleteDescription = () =>
    setOpenDeleteDescriptionModel(false);
  const handleDeleteDescriptionModel = () =>
    setOpenDeleteDescriptionModel(true);

  //delete faq
  //delete description
  const [deleteFaqId, setDeleteFaqId] = useState(null);
  const [openDeleteFaqModel, setOpenDeleteFaqModel] = useState(false);
  const handleCloseDeleteFaq = () => setOpenDeleteFaqModel(false);
  const handleDeleteFaqModel = () => setOpenDeleteFaqModel(true);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  //description modal
  const handleDescriptionModel = () => {
    setOpenDescriptionModel(true);
    getPlanList();
  };
  const handleCloseDescription = () => setOpenDescriptionModel(false);
  const [openDescriptionModel, setOpenDescriptionModel] = useState(false);

  //faq modal
  const handleFaqModel = () => {
    setOpenFaqModel(true);
    getPlanList();
  };
  const handleCloseFaq = () => setOpenFaqModel(false);
  const [openFaqModel, setOpenFaqModel] = useState(false);

  const navigate = useNavigate();

  const handleChangePage = (e, value) => {
    if (fieldName == "planName") {
      getPlanList(value, fieldName, flagName);
    } else {
      getPlanList(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "planName") {
      getPlanList(page, fieldName, flagName, event);
    } else {
      getPlanList(page, "", "", event);
    }
  };
  const planTypes = [
    { id: 1, name: "User" },
    { id: 2, name: "Rider" },
  ];

  const statusTypes = [
    { id: 1, name: "Active" },
    { id: 0, name: "Inactive" },
  ];

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleChangeStatus = async () => {
    const data = {
      status: status == "Active" ? 1 : 0,
    };
    const res = await SubscriptionStatusUpdate(switchId, data);
    if (res.status === true) {
      enqueueSnackbar("Subscription Status Updated Successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setLoading(false);
      setOpenSwitchModel(false);
      getPlanList();
    }
  };

  //deleteSubscription
  const handleDelete = async (id) => {
    const res = await DeleteSubscription(id);
    if (res.status === true) {
      enqueueSnackbar("Subscription Deleted Successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setLoading(false);
      setOpenDeleteModel(false);
      refreshScreen();
      getPlanList();
    } else {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //deleteDescription
  const handleDeletePlanDescription = async (id) => {
    const res = await DeleteSubscriptionDescription(id);
    if (res.status === true) {
      enqueueSnackbar("Subscription Deleted Successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setLoading(false);
      setOpenDeleteDescriptionModel(false);
      handleCloseDeleteDescription();
      getPlanList();
      refreshScreen();
    } else {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //deleteDescription
  const handleDeleteFaq = async (id) => {
    const res = await DeleteSubscriptionTerms(id);
    if (res.status === true) {
      enqueueSnackbar("Subscription Deleted Successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setLoading(false);
      setOpenDeleteFaqModel(false);
      handleCloseDeleteFaq();
      refreshScreen();
      getPlanList();
    } else {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  useEffect(() => {
    getPlanList(page, "", "");
  }, [search, filterData]);

  const getPlanList = async (pageNum,  name = "", flag, limit) => {
    setLoading(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;

    let queryString = `?page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${sortFlag}`;

    const res = await getSubscriptionList(queryString);

    if (res.status === true) {
      setPlanList(res?.data);
      setTotalRecords(res?.totalRecord);
      setTotalPages(Math.ceil(res?.totalRecord / recordLimit));
      setSortFlag(!sortFlag);
      setPageLimit(recordLimit);
      setFilter(false);
      setLoading(false);
      // setPage(pageNum);
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
      setPlanList([]);
      setFilter(false);
      setLoading(false);
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [extracharge, setAddExtracharge] = useState(false);
  const openUserModal = () => {
    setAddExtracharge(true);
  };
  const closeUserModal = () => {
    setAddExtracharge(false);
    setIsEdit(false);
  };
  //isVerify document
  const IsVerify = (IsVerify) => {
    const statusColors = {
      1: {
        key: "User",
        border: 1,
        borderColor: "green",
        color: "green",
      },
      2: {
        key: "Rider",
        border: 1,
        borderColor: "blue",
        color: "blue",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[IsVerify]}
        label={statusColors[IsVerify] ? statusColors[IsVerify].key : ""}
      />
    );
  };

  //sorting
  const dataShorting = (name) => {
    if (name === "planName") {
      setflagName(!flagName);
      getPlanList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(name);
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Subscription List
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                 
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table" sx={{ width: "144%" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Image</StyledTableCell>
                    <StyledTableCell>User For</StyledTableCell>
                    <StyledTableCell onClick={() => dataShorting("planName")}>
                      Plan Name
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>Title</StyledTableCell>
                    <StyledTableCell>Base Price</StyledTableCell>
                    <StyledTableCell>Plan Duration Type</StyledTableCell>
                    <StyledTableCell>Expiration Duration Month</StyledTableCell>
                    <StyledTableCell>Plan Price</StyledTableCell>
                    <StyledTableCell>Max Order</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left",
                        }}
                      >
                        Status
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left",
                        }}
                      >
                        Action
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableBody
                    sx={{ position: "fixed", top: "50%", left: "58%" }}
                  >
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        style={{
                          height: "250px",
                        }}
                      >
                        <Spin size="large" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  planList?.map((row) => {
                    return (
                      <>
                        <TableBody>
                          <StyledTableRow sx={{ cursor: "pointer" }}>
                            <StyledTableCell component="th" scope="row">
                              <Avatar
                                component="span"
                                variant="rounded"
                                alt="image"
                                src={row?.image}
                              />
                            </StyledTableCell>
                            <StyledTableCell component="th">
                              {IsVerify(row?.userType)}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row?.planName}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row?.title}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row?.planPrice}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {"Month"}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row?.planExpirationDuration}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row?.finalPrice}
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              {row?.maxOrder}
                            </StyledTableCell>

                            <StyledTableCell
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Controls.Switch
                                checked={row.isActive == 1 ? true : false}
                                onChange={(e) => {
                                  handleSwitchModel();
                                  setStatus(
                                    e.target.checked == true
                                      ? "Active"
                                      : "InActive"
                                  );
                                  setSwitchId(row.id);
                                  setChecked(e.target.checked);
                                }}
                                name="checkedB"
                                color="primary"
                                sx={{ marginLeft: "40px" }}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Space size="middle">
                                <Tooltip
                                  placement="bottom"
                                  title="View Subscrption"
                                >
                                  <button
                                    className="action-button view"
                                    onClick={() => {
                                      handleDescriptionModel();
                                      setPlanDescriptionList(row);
                                      handleCloseDeleteDescription();
                                      setPlanFaqList(row);
                                    }}
                                  >
                                    <GrView />
                                  </button>
                                </Tooltip>
                                <Tooltip
                                  placement="bottom"
                                  title="Edit Subscrption"
                                >
                                  <button
                                    className="action-button edit"
                                    onClick={() => {
                                      navigate("/addSubscription", {
                                        state: {
                                          subscription: row,
                                        },
                                      });
                                      setIsEdit(true);
                                    }}
                                  >
                                    <RiEditLine />
                                  </button>
                                </Tooltip>
                              </Space>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </>
                    );
                  })
                )}
                <Modal
                  keepMounted
                  open={openDescriptionModel}
                  onClose={handleCloseDescription}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box
                    className="modelBox"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "60%",
                      transform: "translate(-50%, -50%)",
                      width: 700,
                      border: "0",
                      outline: "0",
                      bgcolor: "#fff",
                      borderRadius: "10px",
                      height: "75%",
                      overflow: "scroll",

                      p: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#000",
                        marginBottom: "15px",
                      }}
                    >
                      Plan Description List
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                        width: "98%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ height: "258px" }}
                        >
                          <Table
                            aria-label="customized table"
                            sx={{
                              width: { md: "160%", lg: "100%", xl: "100%" },
                            }}
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Image</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell>Sub Value</StyledTableCell>
                                <StyledTableCell>Sequence</StyledTableCell>

                                <StyledTableCell>
                                  <Button
                                    variant="text"
                                    sx={{
                                      ":hover": { bgcolor: "transparent" },
                                      paddingLeft: "0",
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Action
                                  </Button>
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {planDescriptionList?.planDescriptions?.map(
                                (row) => {
                                  return (
                                    <>
                                      <StyledTableRow
                                        // key={index}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          <Avatar
                                            component="span"
                                            variant="rounded"
                                            alt="image"
                                            src={row?.image}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {row?.description}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {row?.subValue}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {row?.sequence}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        >
                                          <Space size="middle">
                                            <Tooltip
                                              placement="bottom"
                                              title="Delete Description"
                                            >
                                              <button
                                                className="action-button delete-btn"
                                                onClick={() => {
                                                  // setId(row.id);
                                                  setDeleteDescriptionId(
                                                    row?.id
                                                  );
                                                  handleDeleteDescriptionModel();
                                                }}
                                              >
                                                <RiDeleteBinLine />
                                              </button>
                                            </Tooltip>
                                          </Space>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                          {planDescriptionList?.planDescriptions &&
                          planDescriptionList?.planDescriptions.length === 0 ? (
                            <div
                              className="noDataFound"
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              No Data Found
                            </div>
                          ) : null}
                        </TableContainer>
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#000",
                        marginBottom: "15px",
                        marginTop: "15px",
                      }}
                    >
                      Terms And Condition List
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                        width: "98%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ height: "258px" }}
                        >
                          <Table
                            aria-label="customized table"
                            sx={{
                              width: { md: "160%", lg: "100%", xl: "100%" },
                            }}
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell>Sequence</StyledTableCell>

                                <StyledTableCell>
                                  <Button
                                    variant="text"
                                    sx={{
                                      ":hover": { bgcolor: "transparent" },
                                      paddingLeft: "0",
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Action
                                  </Button>
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {planFaqList?.planFAQ?.map((row) => {
                                return (
                                  <>
                                    <StyledTableRow
                                      // key={index}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                      >
                                        {row?.subValue}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                      >
                                        {row?.description}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                      >
                                        {row?.sequence}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <Space size="middle">
                                          <Tooltip
                                            placement="bottom"
                                            title="Delete FAQ"
                                          >
                                            <button
                                              className="action-button delete-btn"
                                              onClick={() => {
                                                // setId(row.id);
                                                setDeleteFaqId(row?.id);
                                                handleDeleteFaqModel();
                                              }}
                                            >
                                              <RiDeleteBinLine />
                                            </button>
                                          </Tooltip>
                                        </Space>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </>
                                );
                              })}
                            </TableBody>
                          </Table>
                          {planFaqList?.planFAQ &&
                          planFaqList?.planFAQ.length === 0 ? (
                            <div
                              className="noDataFound"
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              No Data Found
                            </div>
                          ) : null}

                          {planList && planList.length === 0 ? (
                            <div
                              className="noDataFound"
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              No Data Found
                            </div>
                          ) : null}
                        </TableContainer>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
                ;
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {!loading && (
          <Box
            sx={{
              display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
              marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
              mt: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                fontSize: "14px",
                marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                gap: 2,
                width: { sm: "100%", md: "50%" },
              }}
              display="flex"
              alignItems="center"
            >
              <Box>
                {" "}
                Showing{" "}
                {page * pageLimit > totalRecords
                  ? totalRecords
                  : page * pageLimit}{" "}
                of {totalRecords} Results
              </Box>
              <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                <Typography>Records per page</Typography>
                <Select
                  className="recordLimit"
                  sx={{
                    color: "#000",
                    height: "40px",
                    fontSize: "15px",
                    borderRadius: "5px",
                    border: 1,
                    paddingLeft: "10px",
                    borderColor: "#000",
                    ".MuiSvgIcon-root ": {
                      fill: "black !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  id="limites"
                  name="limites"
                  value={pageLimit}
                  onChange={(e) => {
                    handleChangeRow(e.target.value);
                  }}
                >
                  {RecordPerPage.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiButtonBase-root ": {
                  color: "#000 !important",
                },
                "& .Mui-selected ": {
                  background: "#4b4b4e70 !important",
                },
                "& .Mui-disabled ": {
                  background: "#6c696966 !important",
                  color: "#000000 !important",
                  opacity: 1,
                },
              }}
            />
          </Box>
        )}

        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Status Change
            </Typography>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Change"
                  onClick={() => handleChangeStatus()}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteDescriptionModel}
          onClose={handleCloseDeleteDescription}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseDeleteDescription}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() =>
                    handleDeletePlanDescription(deleteDescriptionId)
                  }
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteFaqModel}
          onClose={handleCloseDeleteFaq}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseDeleteFaq}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDeleteFaq(deleteFaqId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                <StyledFormLabel htmlFor="name">Plan Name</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  placeholder="Enter Plan Name"
                  fullWidth
                  id="planName"
                  name="planName"
                  value={values.planName}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="name">User Type</StyledFormLabel>
                <Controls.Select
                  name="userType"
                  value={values.userType}
                  onChange={handleInputChange}
                  options={planTypes}
                  sx={{ mt: 0 }}
                />
              </Box>

              <StyledFormLabel htmlFor="name">Status</StyledFormLabel>
              <Controls.Select
                name="isActive"
                value={values.isActive}
                onChange={handleInputChange}
                options={statusTypes}
                style={{
                  height: "20px !important",
                }}
              />
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button
                className="order-filter-button"
                type="submit"
                style={{
                  cursor: "pointer",
                }}
              >
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
    </>
  );
}
