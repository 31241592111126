import React, { useEffect, useState } from "react";
import { InputBase, Pagination, Select } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Tooltip, Drawer, FormLabel, TextField } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { Space } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import AddCityModel from "../../Component/Models/AddCityModel";
import {
  getStateDropdownList,
  getCityLists,
  stateLocationStatus,
  deleteCity,
  addCityByState,
  updateCity,
  cityLocationStatus,
} from "../../state/action/locationAction";
import { useNavigate, useLocation } from "react-router";
import { GrView } from "react-icons/gr";
import Modal from "@mui/material/Modal";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSnackbar } from "notistack";
import { dumpAllContry } from "../../state/action/countryAction";
const initialValues = {
  name: "",
  state: "",
  status: "",
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {

    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(State, Status, Action) {
  return { State, Status, Action };
}
const rows = [createData("Alashka")];

const RecordPerPage = [
  { id: "10", title: "10" },
  { id: "20", title: "20" },
];

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);

  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState([]);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleAddModel = () => setOpenAddModel(true);
  const handleCloseAdd = () => {
    setOpenAddModel(false);
    resetForm();
    setIsEdit(false);
  };
  const [status, setStatus] = useState();
  const [cityData, setCityData] = useState([]);
  // console.log('cityData ------------------>', cityData);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const [stateDropdown, setStateDropdown] = useState([]);
  const [flagCity, setFlagCity] = useState(false);
  const [flagState, setFlagState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [checked, setChecked] = useState(true);
  const [editModel, setEditMode] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedStateId, setselectedStateId] = useState(null);
  const [allCountries, setAllCountries] = useState([])
  const { state } = useLocation();

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      CityListData(value, fieldName, flagCity);
    } else {
      CityListData(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "name") {
      CityListData(page, fieldName, flagCity, event);
    } else {
      CityListData(page, "", "", event);
    }
  };

  const options = ["View Cities"];
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const CityListData = async (page, name = "", limit) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;

    const queryString = `?page=${page}&search=${search}${string}&pagePerSize=${recordLimit}&sortby=${name}&sortFlag=${sortFlag}`;

    const res = await getCityLists(queryString);

    if (res.status === true) {
      setCityList(res.data);
      setTotalRecords(res.totalCity);
      setTotalPages(Math.ceil(res.totalCity / recordLimit));
      setPageLimit(recordLimit);
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setCityList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };


  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        stateId: values.stateId,
        name: values.name,
        countryId: 1,
      };
      if (isEdit) {

        const res = await updateCity(cityData.id, payload);
        if (res && res.status === true) {
          CityListData(page, "");
          handleCloseAdd();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          CityListData(page, "");
          handleCloseAdd();
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await addCityByState(payload);
        if (res && res.status === true) {
          CityListData(page, "");
          handleCloseAdd();
          resetForm();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          CityListData(page, "");
          handleCloseAdd();
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await cityLocationStatus(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        CityListData(page, fieldName, flagCity);
      } else {
        CityListData(page, "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handleDelete = async () => {
    const res = await deleteCity(deleteId);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        CityListData(page, fieldName, flagCity);
      } else {
        CityListData(page, "", 0);
      }
      handleCloseDelete();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const statusList = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
    { id: "3", title: "Delete" },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (state && state.countryId) {
      setSelectedCountryId(state.countryId);
    }
    if (state && state.stateId) {
      setselectedStateId(state.stateId)
    }
    CityListData(page, "");
  }, [search, filterData]);

  const dataShorting = (name) => {
    if (name == "name") {
      setFlagCity(!flagCity);
      CityListData(page, name, !flagCity);
    } else {
      setFlagCity(false);
      setSortFlag(!sortFlag);
    }
    if (name == "stateName") {
      setFlagState(!flagState);
      CityListData(page, name, !flagCity);
    } else {
      setFlagState(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(name);
  };
  // const StateDropdownList = async (id) => {
  //   setOpenBreakdrop(true);
  //   console.log('selectedCountryId ===', selectedCountryId);
  //   const res = await getStateDropdownList(id);
  //   if (res.status === true) {
  //     setStateDropdown(res.data);
  //     setOpenBreakdrop(false);
  //   } else {
  //     setStateDropdown([]);
  //     setOpenBreakdrop(false);
  //   }
  // };
  // useEffect(() => {

  //   setTimeout(() => {
  //     StateDropdownList();
  //   }, 2000);


  // }, []);

  const getAllCounries = async () => {
    const res = await dumpAllContry();
    if (res.status === true) {
      setAllCountries(res.data);
    } else {
      setAllCountries([]);

    }
  }


  useEffect(() => {
    getAllCounries();
  }, [])

  return (
    <>
      <Box>
        <BackdropComponent open={openBackdrop} />

        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Cities
                {/* <Grid item sm={4} xs={12}>
                  <Controls.Button
                    startIcon={<ArrowBackIosIcon />}
                    text="Back"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",

                      textTransform: "none",
                    }}
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  />
                </Grid> */}
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Controls.Button
                    startIcon={<AddIcon />}
                    text="Add"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      handleAddModel();
                      setIsEdit(false);
                    }}
                    variant="outlined"
                  />

                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto ",
          }}
        >
          <Box>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left"
                        }}
                        onClick={() => dataShorting("name")}
                      >
                        City
                        {flagCity ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left"
                        }}
                        onClick={() => dataShorting("stateName")}
                      >
                        State
                        {flagState ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left"
                        }}
                      >
                        Status
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          justifyContent: "left"
                        }}
                      >
                        Action
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cityList && cityList.length > 0 ? (
                    cityList.map((row, index) => (
                      <StyledTableRow key={index} sx={{ cursor: "pointer" }}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.stateName}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Controls.Switch
                            checked={row.status == 1 ? true : false}
                            onChange={(e) => {
                              handleSwitchModel();
                              setStatus(
                                e.target.checked == true ? "Active" : "InActive"
                              );
                              setSwitchId(row.id);
                              setChecked(e.target.checked);
                            }}
                            name="checkedB"
                            color="primary"
                            sx={{ marginLeft: "40px" }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Space size="middle">
                            <Tooltip
                              placement="bottom"
                              className="cursor-pointer"
                              title="Edit State"
                            >
                              <button
                                className="action-button edit "
                                onClick={() => {
                                  handleAddModel();
                                  setIsEdit(true);
                                  setCityData(row);
                                }}
                              >
                                <RiEditLine />
                              </button>
                            </Tooltip>
                            <Tooltip placement="bottom" title="Delete State">
                              <button
                                className="action-button delete-btn"
                                onClick={() => {

                                  setDeleteId(row?.id);
                                  handleDeleteModel();
                                }}
                              >
                                <RiDeleteBinLine />
                              </button>
                            </Tooltip>
                          </Space>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={4}>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "20px",
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          No Record Found
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {" "}
              Showing{" "}
              {page * pageLimit > totalRecords
                ? totalRecords
                : page * pageLimit}{" "}
              of {totalRecords} Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>
              <Select
                className="recordLimit"
                sx={{
                  color: "#000",
                  height: "40px",
                  fontSize: "15px",
                  borderRadius: "5px",
                  border: 1,
                  paddingLeft: "10px",
                  borderColor: "#000",
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="limites"
                name="limites"
                value={pageLimit}
                onChange={(e) => {
                  handleChangeRow(e.target.value);
                }}
              >
                {RecordPerPage.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>
        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Status Change
            </Typography>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <AddCityModel
          handleCloseAdd={handleCloseAdd}
          openAddModel={openAddModel}
          isEdit={isEdit}
          page={page}
          enqueueSnackbar={enqueueSnackbar}
          CityListData={CityListData}
          cityData={cityData}
          stateDropdown={stateDropdown}
          selectedCountryId={selectedCountryId}
          selectedStateId={selectedStateId}
          allCountries={allCountries}
        />

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                <StyledFormLabel htmlFor="name">State</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"

                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="hsnCode">Status</StyledFormLabel>

                <Controls.SelectBox
                  className="input"

                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleInputChange}
                  options={statusList}
                />
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button
                className="order-filter-button"
                type="submit"
                style={{
                  cursor: "pointer",
                }}
              >
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
    </>
  );
}
