import { Box } from "@mui/material";
import React from "react";
import NeedHelpList from "./NeedHelpList";

const NeedHelp = () => {
  return (
    <Box>
      <NeedHelpList />
    </Box>
  );
};

export default NeedHelp;
