import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import "./order.css";

import moment from "moment";
import { getOrderDetails } from "../../state/action/orderAction";

const OrderHistory = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [riderList, setRiderList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [orderHistoryList, setorderHistoryList] = useState([]);

  //get the details
  const getDetails = async (state) => {
    const res = await getOrderDetails(state);
    if (res && res.status) {
      setRiderList(res.data.RiderData);
      setUserList(res.data.UserData);
      setorderHistoryList(res.data.orderHistory);
    }
  };

  useEffect(() => {
    getDetails(state);
  }, [state]);

  return (
    <Box>
      <Timeline position="alternate">
        <Typography
          sx={{ display: "flex", justifyContent: "center" }}
        ></Typography>
        {orderHistoryList?.map((row) => {
          
          return (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot
                    sx={{
                      "&.MuiTimelineDot-defaultGrey": {
                        backgroundColor: "black",
                        color: "#fafafa",
                        borderColor: "transparent",
                      },
                    }}
                    className={
                      row?.status === 0
                        ? "zero"
                        : row?.status === 1
                        ? "one"
                        : row?.status === 2
                        ? "two"
                        : "none"
                    }
                  >
                    <Avatar
                      component="span"
                      variant="rounded"
                      alt="profile_image"
                      src={row?.icon}
                    />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{ py: "15px", px: 2 }}
                  className="MuiTimelineContent-root "
                >
                  <Typography variant="h6" component="span">
                    {row?.orderStatusName}
                  </Typography>
                  <Typography>{row.createdBy}</Typography>
                  {row?.createdAt ? (
                    <Typography>
                      {moment(row.createdAt).format("MM-DD-YYYY LT")}
                    </Typography>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            </>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default OrderHistory;
