import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import { addVehical, updateVehical } from "../../state/action/vehicalAction";
import {
  addParameterValue,
  getParameterTypeList,
  updateParameter,
} from "../../state/action/ParameterAction";
import { addParcelType } from "../../state/action/parcelTypeAction";

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  vehicleName: "",
  vehicleType: "",
  capacity: "",
};

const AddParcel = (props) => {
  const { open, handleClose, parameterData, refreshScreen, setIsEdit, isEdit } =
    props;

  const [ParameterTypesList, setParameterTypesList] = useState([]);
  const [typeID, setTypeId] = useState();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("sequence" in fieldValues)
      temp.sequence = fieldValues.sequence ? "" : "This field is required.";

    if ("valueCode" in fieldValues)
      temp.valueCode = fieldValues.valueCode ? "" : "This field is required.";

    if ("acceptedValues" in fieldValues)
      temp.acceptedValues = fieldValues.acceptedValues
        ? ""
        : "This field is required.";

    if ("remark" in fieldValues)
      temp.remark = fieldValues.remark ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("parameterTypeID", 1);
      formData.append("imageLink", values.imageLink);
      formData.append("valueCode", values.valueCode);
      formData.append("name", values.name);
      formData.append("sequenceNo", values.sequenceNo);
      formData.append("acceptedValues", values.acceptedValues);
      formData.append("remark", values.remark);

      if (isEdit) {
        const res = await updateParameter(parameterData.id, formData);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await addParcelType(formData);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.error.details[0].message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["imageLink"];
      return newValues;
    });
  };

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, imageLink: selected });
    } else {
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        name: parameterData.name,
        acceptedValues: parameterData.acceptedValues,
        remark: parameterData.remark,
        sequenceNo: parameterData.sequenceNo,
        valueCode: parameterData.valueCode,
        imageLink: parameterData?.imageLink,
      });
    } else {
      resetForm();
    }
  }, [isEdit, parameterData]);

  useEffect(() => {
    if (isEdit) {
      setImage(parameterData?.imageLink);
    } else {
      setImage("");
    }
  }, [isEdit, parameterData]);

  //parameter Type List

  useEffect(() => {
    ParameterType();
  }, []);

  const ParameterType = async () => {
    const res = await getParameterTypeList();
    if (res.status === true) {
      setParameterTypesList(res.data);
    } else {
      setParameterTypesList([]);
    }
  };

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{ width: "80%", marginLeft: "260px", color: "black" }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Parcel Type " : "Add New Parcel Type"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        image == ""
                          ? {}
                          : {
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }
                      }
                    >
                      {image === "" && <img src={camera} alt="Camera" />}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    {isEdit ? "Edit Profile Image" : "Add Profile Image"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                  </Typography>
                  {image != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage();
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Grid>

              <Grid item md={9} sm={8} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Value Code"
                    name="valueCode"
                    value={values.valueCode}
                    onChange={handleInputChange}
                    error={errors.valueCode}
                    helperText={errors.valueCode}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Accepted Value"
                    name="acceptedValues"
                    value={values.acceptedValues}
                    onChange={handleInputChange}
                    error={errors.acceptedValues}
                    helperText={errors.acceptedValues}
                  />
                </Grid>
                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Sequence"
                    name="sequenceNo"
                    value={values.sequenceNo}
                    onChange={handleInputChange}
                    error={errors.sequenceNo}
                    helperText={errors.sequenceNo}
                  />
                </Grid>
                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Remark"
                    name="remark"
                    value={values.remark}
                    onChange={handleInputChange}
                    error={errors.remark}
                    helperText={errors.remark}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "-124px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default AddParcel;
