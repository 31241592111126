import { Box } from "@mui/material";
import React from "react";
import NotificationList from "./NotificationList";

const Notification = () => {
  return (
    <Box>
      <NotificationList />
    </Box>
  );
};
export default Notification;
