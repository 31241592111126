import * as React from "react";
import Multiselect from "multiselect-react-dropdown";
import Typography from "@mui/material/Typography";
import {
  Box,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  tableCellClasses,
  MenuItem
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { useForm, Form } from "../../Utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import camera from "../../assets/camera.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  addCompany,
  updateCompany,
  getVehicleDropdownList,
  deleteSelectedImageApi,
} from "../../state/action/companyAction";

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});



const initialValues = {
  companyName: "",
  company_Type: "",
  ownerName: "",
  partnerName: "",
  company_License_Number: "",
  company_Registration_Number: "",
  companyPermit: "",
  insurance_Details: "",
  legal_Representative_Name: "",
  operational_Region: "",
  percentage_Sharing: "",

  address: "",
  phone_Number: "",
  email: "",
  Fax: "",
  vehicle_Type: "",
  vehicle_Types: [],
  company_Logo: "",
  countryCode: "",
  no_Riders_By_Vehicle_Type: "",
  isActive: "",
};

const userList = [
  { id: 1, name: "Solo" },
  { id: 2, name: "Proprietor" },
  { id: 3, name: "Partnership" },
];

const countries = [
  { id: "+91", name: "+91" },
  { id: "+971", name: "+971" },

];

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [rowsTermsData, setRowsTermsData] = useState([]);
  const [val, setVal] = useState([]);
  const [companyImage, setcompanyImage] = useState();
  //image
  const [image, setImage] = useState("");
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [iconFlag, setIconFlag] = useState(0);

  const [vehicleDropdown, setvehicleDropdown] = useState([]);
  //vehicletype
  const vehicleDropdownList = async () => {
    const res = await getVehicleDropdownList();
    if (res.status === true) {
      setvehicleDropdown(res.data);
    } else {
      setvehicleDropdown([]);
    }
  };
  useEffect(() => {
    vehicleDropdownList();
  }, []);

  //  to handle local files

  const maxSize = 5120;

  const validateImageSize = (documnet) => {
    if (documnet) {
      const fsize = documnet.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  async function handleImageItems(event) {
    if (validateImageSize(event.target.files[0])) {
      const selectedFiles = event.target.files;
      var localFiles = [...Array.from(selectedFiles)];

      setImages((preval) => {
        return [...preval, ...localFiles];
      });

      let newImagearr = localFiles.map((file) => ({
        file: file,
        image: URL.createObjectURL(file),
      }));
      setFiles([...files, ...newImagearr]);

    } else {
      enqueueSnackbar("Document shounld be less than 5 MB only", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }


  }

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName
        ? ""
        : "This field is required.";

    if ("company_Type" in fieldValues)
      temp.company_Type = fieldValues.company_Type
        ? ""
        : "This field is required.";

    if ("ownerName" in fieldValues)
      temp.ownerName = fieldValues.ownerName ? "" : "This field is required.";
    if ("company_License_Number" in fieldValues) {
      let alphanumericRegex = /^[a-zA-Z0-9]+$/; // Alphanumeric regex

      if (alphanumericRegex.test(fieldValues.company_License_Number)) {
        temp.company_License_Number = ""; // Valid alphanumeric value
      } else {
        temp.company_License_Number = "Only alphanumeric characters allowed"; // Invalid alphanumeric value
      }
    }



    if ("company_Registration_Number" in fieldValues) {
      let alphanumericRegex = /^[a-zA-Z0-9]+$/; // Alphanumeric regex

      if (alphanumericRegex.test(fieldValues.company_Registration_Number)) {
        temp.company_Registration_Number = ""; // Valid alphanumeric value
      } else {
        temp.company_Registration_Number = "Only alphanumeric characters allowed"; // Invalid alphanumeric value
      }

    }
    if ("percentage_Sharing" in fieldValues) {
      let regex2 = /^[0-9\b]+(\.[0-9]{1,2})?$/;
      if (regex2.test(fieldValues.percentage_Sharing)) {
        temp.percentage_Sharing = "";
      } else {
        temp.percentage_Sharing = "Only number allowed";
      }
    }

    if ("partnerName" in fieldValues)
      temp.partnerName = fieldValues.partnerName
        ? ""
        : "This field is required.";

    // companyPermit

    // if ("companyPermit" in fieldValues)
    //   temp.companyPermit = fieldValues.companyPermit
    //     ? ""
    //     : "This field is required.";

    // insurance_Details

    // if ("insurance_Details" in fieldValues)
    //   temp.insurance_Details = fieldValues.insurance_Details
    //     ? ""
    //     : "This field is required.";

    if ("legal_Representative_Name" in fieldValues)
      temp.legal_Representative_Name = fieldValues.legal_Representative_Name
        ? ""
        : "This field is required.";

    if ("operational_Region" in fieldValues)
      temp.operational_Region = fieldValues.operational_Region
        ? ""
        : "This field is required.";


    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    if ("phone_Number" in fieldValues) {
      let regex2 = /^[0-9\b]+(\.[0-9]{1,2})?$/;
      if (regex2.test(fieldValues.phone_Number)) {
        temp.phone_Number = "";
      } else {
        temp.phone_Number = "Only number allowed";
      }
    }

    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    // Fax
    // if ("Fax" in fieldValues)
    //   temp.Fax = fieldValues.Fax ? "" : "This field is required.";

    if ("countryCode" in fieldValues)
      temp.countryCode = fieldValues.countryCode ? "" : "This field is required.";

    if (fieldValues.vehicle_Types?.length == "0") {

      if ("vehicle_Types" in fieldValues) {
        temp.vehicle_Types =
          fieldValues.vehicle_Types?.length > 0
            ? ""
            : "This field is required.";
      }
    }


    if ("no_Riders_By_Vehicle_Type" in fieldValues)
      temp.no_Riders_By_Vehicle_Type = fieldValues.no_Riders_By_Vehicle_Type
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //descrption for array
  const data = rowsData?.map((row) => row);
  //terms and condition
  const dataTerms = rowsTermsData?.map((row) => row);
  const vehicalData = values?.vehicle_Types?.map((row) => row?.id);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("companyName", values.companyName);
      formData.append("company_Type", values.company_Type);
      formData.append("ownerName", values.ownerName);
      if (companyImage) {
        formData.append("company_Logo", companyImage);
      }
      formData.append("partnerName", values.partnerName);
      formData.append("company_License_Number", values.company_License_Number);
      formData.append(
        "company_Registration_Number",
        values.company_Registration_Number
      );
      formData.append("companyPermit", values.companyPermit);
      formData.append("insurance_Details", values.insurance_Details);
      formData.append(
        "legal_Representative_Name",
        values.legal_Representative_Name
      );
      formData.append("operational_Region", values.operational_Region);
      formData.append("percentage_Sharing", values.percentage_Sharing);

      formData.append("address", values.address);
      formData.append("phone_Number", values.phone_Number);
      formData.append("countryCode", values.countryCode);
      formData.append("email", values.email);
      formData.append("Fax", values.Fax);
      formData.append("vehicle_Type", JSON.stringify(vehicalData));
      formData.append(
        "no_Riders_By_Vehicle_Type",
        values.no_Riders_By_Vehicle_Type
      );

      images.forEach((val) => {
        formData.append("images", val);
      });
      if (isEdit) {
        const res = await updateCompany(state?.company?.id, formData);
        if (res && res.status === true) {
          navigate("/company");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        if (companyImage && companyImage.name) {
          if (images.length > 0) {

            const res = await addCompany(formData);

            if (res && res.status === true) {
              navigate("/company");
              enqueueSnackbar(res.message, {
                variant: "success",
                autoHideDuration: 2000,
              });
            } else {
              enqueueSnackbar(res.message, {
                variant: "error",
                autoHideDuration: 2000,
              });
            }
          } else {
            enqueueSnackbar("Please add document", {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        } else {
          enqueueSnackbar("Please add Company logo", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  // function for delete image from images and files array
  async function deleteImage(index, val) {
    if (images[index]) {
      setImages((preval) => {
        return preval.filter((val, id) => id !== index);
      });
    }
    if (files[index]) {
      setFiles((preval) => {
        return preval.filter((val, id) => id !== index);
      });
    }
    if (val.companys_Id && val.id) {
      await deleteSelectedImageApi(val.id);
    }
  }

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["company_Logo"];
      return newValues;
    });
    setcompanyImage({});
  };

  const maxSizes = 2048;

  const validateImageSizes = (documnet) => {
    if (documnet) {
      const fsizes = documnet.size / 1024;
      if (fsizes > maxSizes) {
        return false;
      } else {
        return true;
      }
    }
  };


  async function handleImageItem(e) {
    if (validateImageSizes(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setcompanyImage(selected);
        setValues({ ...values, companyImage: selected });
      } else {
      }
    } else {
      enqueueSnackbar("Company logo shounld be less than 2 MB only", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  //details edit
  useEffect(() => {
    if (
      state?.company.id != "" &&
      state?.company.id != null &&
      state?.company.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        companyName: state?.company.companyName,
        company_Type: state?.company.company_Type,
        ownerName: state?.company.ownerName,
        partnerName: state?.company.partnerName,
        company_License_Number: state?.company?.company_License_Number,
        company_Registration_Number:
          state?.company?.company_Registration_Number,
        companyPermit: state?.company?.companyPermit,
        insurance_Details: state?.company?.insurance_Details,
        legal_Representative_Name: state?.company?.legal_Representative_Name,
        operational_Region: state?.company?.operational_Region,
        percentage_Sharing: state?.company?.percentage_Sharing,

        address: state?.company?.address,
        phone_Number: state?.company?.phone_Number,
        countryCode: state?.company?.countryCode,
        email: state?.company.email,
        Fax: state?.company.Fax,
        vehicle_Types: state?.company.vehiclearrays,

        no_Riders_By_Vehicle_Type: state?.company?.no_Riders_By_Vehicle_Type,
      });
      setImage(state?.company?.company_Logo);
      setFiles(state?.company?.documentImages);
    } else {
      resetForm();
    }
  }, [state?.company]);

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              sx={{
                paddingTop: "25px",
                paddingBottom: "15px",
                fontSize: "15px",
                fontWeight: "600",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/company");
              }}
            >
              <KeyboardBackspaceIcon
                sx={{ verticalAlign: "top", marginRight: "5px" }}
              />
              Company
            </Typography>
            <div>
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: "10px",
                }}
                text="Cancel"
                onClick={() => {
                  navigate("/company");
                  resetForm();
                }}
              />
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Submit"
                onClick={(e) => handleSubmit(e)}
                type="submit"
              />
            </div>
          </Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Box
            className="b1"
            sx={{
              backgroundColor: "white",
            }}
          >
            <Grid item sx={{ m: 1 }} md={6} xs={12}>
              <StyledImageUploadWrapper>
                <label htmlFor="image-upload">
                  <StyledInput
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    name="documents"
                    onChange={handleImageItem}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                  <StyledIconWrapper
                    sx={
                      image == ""
                        ? {}
                        : {
                          backgroundImage: `url(${image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }
                    }
                  >
                    {image === "" && <img src={camera} alt="Camera" />}
                  </StyledIconWrapper>
                </label>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Add Company Logo
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(15, 15, 15, 0.5)",
                    mx: 2,
                    fontSize: "12px",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png,max size of 2 MB
                </Typography>
                {image != "" && (
                  <StyledRemoveButton
                    onClick={(e) => {
                      e.preventDefault();
                      removeImage();
                    }}
                  >
                    Remove
                  </StyledRemoveButton>
                )}
              </StyledImageUploadWrapper>
            </Grid>
            <Grid
              item
              md={12}
              justifyContent="center"
              sm={12}
              xs={12}
              container
            >
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input"
                  fullWidth
                  name="company_Type"
                  label="Company Type"
                  value={values.company_Type}
                  onChange={handleInputChange}
                  options={userList}
                />
                {Boolean(errors.company_Type) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.company_Type}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Company Name"
                  name="companyName"
                  fullWidth
                  value={values.companyName}
                  onChange={handleInputChange}
                  error={errors.companyName}
                  helperText={errors.companyName}
                  inputProps={{ maxLength: 120 }}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Owner Name"
                  name="ownerName"
                  fullWidth
                  value={values.ownerName}
                  onChange={handleInputChange}
                  error={errors.ownerName}
                  helperText={errors.ownerName}
                  inputProps={{ maxLength: 120 }}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Partner Name"
                  name="partnerName"
                  value={values.partnerName}
                  onChange={handleInputChange}
                  error={errors.partnerName}
                  helperText={errors.partnerName}
                  inputProps={{ maxLength: 120 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  helperText={errors.email}
                  inputProps={{ maxLength: 200 }}
                />
              </Grid>{" "}

              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Controls.SelectBox
                      className="input"
                      fullWidth
                      id="countryCode"
                      label="CountryCode"
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleInputChange}
                      options={countries}
                    />
                    {Boolean(errors.countryCode) ? (
                      <p
                        style={{
                          color: "rgb(255 0 0)",
                          marginTop: "4px",
                          marginBottom: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.countryCode}
                      </p>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item sx={{ width: "367px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Phone Number"
                      name="phone_Number"
                      value={values.phone_Number}
                      onChange={handleInputChange}
                      error={errors.phone_Number}
                      helperText={errors.phone_Number}
                      inputProps={{ maxLength: 15 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Company License Number"
                  name="company_License_Number"
                  value={values.company_License_Number}
                  onChange={handleInputChange}
                  error={errors.company_License_Number}
                  helperText={errors.company_License_Number}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Company Registration Number"
                  name="company_Registration_Number"
                  value={values.company_Registration_Number}
                  onChange={handleInputChange}
                  error={errors.company_Registration_Number}
                  helperText={errors.company_Registration_Number}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Company Permit"
                  name="companyPermit"
                  value={values.companyPermit}
                  onChange={handleInputChange}
                  error={errors.companyPermit}
                  helperText={errors.companyPermit}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Insurance Details"
                  name="insurance_Details"
                  value={values.insurance_Details}
                  onChange={handleInputChange}
                  error={errors.insurance_Details}
                  helperText={errors.insurance_Details}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Legal Representative Name"
                  name="legal_Representative_Name"
                  value={values.legal_Representative_Name}
                  onChange={handleInputChange}
                  error={errors.legal_Representative_Name}
                  helperText={errors.legal_Representative_Name}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Operational region"
                  name="operational_Region"
                  value={values.operational_Region}
                  onChange={handleInputChange}
                  error={errors.operational_Region}
                  helperText={errors.operational_Region}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Percentage Sharing"
                  name="percentage_Sharing"
                  value={values.percentage_Sharing}
                  onChange={handleInputChange}
                  error={errors.percentage_Sharing}
                  helperText={errors.percentage_Sharing}
                  inputProps={{ maxLength: 11 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Address"
                  name="address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  helperText={errors.address}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Fax"
                  name="Fax"
                  value={values.Fax}
                  onChange={handleInputChange}
                  error={errors.Fax}
                  helperText={errors.Fax}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Grid item sx={{ marginY: "10px" }} xs={12}>
                  <Multiselect
                    placeholder="Select Vehicle"
                    options={vehicleDropdown}
                    fullWidth
                    displayValue="name"
                    name="vehicle_Types"
                    closeOnSelect={true}
                    showArrow={true}
                    value={values?.vehicle_Types}
                    sx={{ marginTop: "21px", height: "100px" }}
                    selectedValues={values?.vehicle_Types}
                    onSelect={(e) => {
                      setErrors({ ...errors, vehicle_Types: "" });
                      setValues({
                        ...values,
                        vehicle_Types: e,
                      });
                    }}
                    onRemove={(e) => {
                      setValues({
                        ...values,
                        vehicle_Types: e,
                      });
                    }}
                  />
                </Grid>
                {errors.vehicle_Types && (
                  <p
                    style={{
                      color: "rgb(255, 0, 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.vehicle_Types}
                  </p>
                )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="No of Riders by vehicle type"
                  name="no_Riders_By_Vehicle_Type"
                  value={values.no_Riders_By_Vehicle_Type}
                  onChange={handleInputChange}
                  error={errors.no_Riders_By_Vehicle_Type}
                  helperText={errors.no_Riders_By_Vehicle_Type}
                  inputProps={{ maxLength: 11 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-uploads">
                    <StyledInput
                      accept=".pdf,.docx"
                      id="image-uploads"
                      type="file"
                      multiple
                      onChange={handleImageItems}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper>
                      <img src={camera} alt="Camera" />
                    </StyledIconWrapper>
                  </label>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    Add Document
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.pdf,.docx,Shounld be less than 5 MB
                  </Typography>
                </StyledImageUploadWrapper>
                <Grid container item={true} xs={12}>
                  {files.map((file, id) => {
                    return (
                      <StyledIconWrapper
                        sx={{
                          position: "relative",
                          margin: "25px 5px 5px 5px",
                          width: "147px",
                          height: "140px",
                          backgroundImage: `url(${file.image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        key={id + 1}
                        onClick={() => {
                          setIconFlag(id);
                        }}
                      >
                        <IconButton
                          size="large"
                          sx={{
                            position: "absolute",
                            right: "2%",
                            top: "3%",
                            width: "36px",
                            height: "36px",
                            background: "rgba(15, 15, 15, 0.5)",
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "10px",

                            color: "white",
                            "&:hover": {
                              backgroundColor: "#0957DD",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ width: "20px", height: "20px" }}
                            onClick={() => {
                              deleteImage(id, file);
                            }}
                          />
                        </IconButton>
                      </StyledIconWrapper>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}></Grid>{" "}
            </Grid>
          </Box>
        </Form>
      </Box>
    </>
  );
}
