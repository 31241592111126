import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Chip,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Autocomplete,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { styled } from "@mui/system";
import {useForm } from "../../Utils/useForm";
import { Space } from "antd";
// import { getRiderDetails } from "../../state/action/riderAction";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Controls from "../../Component/controls/Controls";
import EmailIcon from "@mui/icons-material/Email";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { HiDownload } from "react-icons/hi";
import { AiFillFilePdf } from "react-icons/ai";
import { FaFileWord } from "react-icons/fa";
import PhoneIcon from "@mui/icons-material/Phone";
import AddIcon from "@mui/icons-material/Add";
import { useProSidebar } from "react-pro-sidebar";
import CompanyRiderPendingModal from "./CompanyRiderPending";

import { useSnackbar } from "notistack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { getRiderDropdownList } from "../../state/action/orderAction";

import {
  CompanyDetails,
  DeleteCompany,
  AssignCompanyRider,
  DeleteAssignCompany,
} from "../../state/action/companyAction";

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialValues = {
  address: "",
};

const DetailsStudent = () => {
  const [companyList, setcompanyList] = useState([]);
  const [addressName, setAddressName] = useState([]);

  const [companydocument, setcompanydocument] = useState([]);
  const [riderassigns, setriderassign] = useState([]);
  const [openRiderModel, setOpenRiderModel] = useState(false);
  const [openAssignRiderModel, setOpenAssignRiderModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openAddressModel, setOpenAddressModel] = useState(false);
  const [openDeleteAddressModel, setOpenDeleteAddressModel] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState();
  const [AddressId, setAddressId] = useState();
  const { collapsed, broken } = useProSidebar();
  const [openAddressDefaultModel, setOpenAddressDefaultModel] = useState(false);
  const [planHistoryList, setplanHistoryList] = useState([]);
  const [AddVehicle, setAddVehicle] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [ids, setId] = useState("");
  const [openDeleteAssignCompanyModel, setOpenDeleteAssignCompanyModel] =
    useState(false);

    const [refresh, setRefresh] = useState(false);
    
    const refreshScreen = () => {
      setRefresh(!refresh);
    };

  //delete the user
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  // delete assing company
  const handleCloseDeleteAssignCompany = () =>
    setOpenDeleteAssignCompanyModel(false);
  const handleDeleteAssignCompanyModel = () =>
    setOpenDeleteAssignCompanyModel(true);

 

  // Rider module
  const handleCloseRider = () => setOpenRiderModel(false);
  const handleRiderModel = () => setOpenRiderModel(true);

  const [riderLists, setRiderLists] = useState([]);

  //add  new rider
  const [CompanyRiderPending, setCompanyRiderPending] = useState(false);
  const openCompanyRiderPendingModal = () => {
    setCompanyRiderPending(true);
  };
  const closeCompanyRiderPendingModal = () => {
    setCompanyRiderPending(false);
    setIsEdit(false);
  };

  //delete the user
  const handleCloseAssignRider = () => setOpenAssignRiderModel(false);
  const handleAssignRiderModel = () => setOpenAssignRiderModel(true);



  const [selectedOption, setSelectedOption] = useState(null);
 

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { state } = useLocation();
  const id = state.user;

  const getDetails = async () => {
    const res = await CompanyDetails(id);

    if (res && res.status) {
      setcompanyList(res?.data);
      setcompanydocument(res.data[0].companydocument);
      setriderassign(res.data[0].companyrider);
    }
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  // rider data

  const getriderListing = async () => {
    const res = await getRiderDropdownList();

    if (res.status === true) {
      setRiderLists(res.data);
    } else {
      setRiderLists([]);
    }
  };

  useEffect(() => {
    getriderListing();
  }, []);

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };



  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  // assign rider data

  const handleassignrider = async () => {
    const body = {
      companyID: id,
    };
    const res = await AssignCompanyRider(selectedOption.id, body);
    if (res && res.status === true) {
      handleCloseRider();
      handleCloseAssignRider();
      navigate("/company");
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    handleassignrider();
  }, []);

  // delete rider assign

  const handleDeleteAssignCompany = async () => {
    if (deleteId) {
      const res = await DeleteAssignCompany(deleteId);
      if (res && res.status === true) {
        handleCloseDeleteAssignCompany();
        navigate("/company");
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  useEffect(() => {
    handleDeleteAssignCompany();
  }, []);

 

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } = useForm(
    initialValues,
    true,
    validate
  );

 

  //address
  useEffect(() => {
    if (
      addressName.id != "" &&
      addressName.id != null &&
      addressName.id != undefined
    ) {
      setValues({
        ...values,
        address: addressName.address,
        id: addressName?.id,
      });
    }
  }, [addressName]);

  //delete the details

  const handleDeleteDetails = async () => {
    const res = await DeleteCompany(id);
    if (res && res.status === true) {
      handleCloseDelete();
      navigate("/company");
      toast.success(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //isMobileVerify document
  const IsVerify = (IsVerify) => {
    const statusColors = {
      0: {
        key: "Not Verified",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Verified",
        backgroundColor: "green",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[IsVerify]}
        label={statusColors[IsVerify] ? statusColors[IsVerify].key : ""}
      />
    );
  };

  //pro status
  const proStatus = (proStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "green",
      },
      0: {
        key: "No",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[proStatus]}
        label={statusColors[proStatus] ? statusColors[proStatus].key : ""}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/company");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Company
          </Typography>
          <div>
            <Controls.Button
              text="Back"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "50px",
                border: "0",
                color: "white",
                ":hover": { bgcolor: "black", border: "0" },
                backgroundColor: "black",
                textTransform: "none",
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => {
                navigate("/company");
              }}
            />

            <Controls.Button
              text="Delete"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#fff",
                backgroundColor: "Red",
                ":hover": { bgcolor: "Red" },
                textTransform: "none",
                borderRadius: "50px",
                boxShadow: 0,
                marginLeft: "7px",
              }}
              onClick={() => {
                handleDeleteModel();
              }}
            />
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              md: "800px",
              lg: "100%",
            },
            marginLeft: "-5px",
          }}
        >
          <Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  {/* <Item> */}
                  <Card
                    sx={{
                      paddingTop: "24px",
                      textAlign: "center",
                      color: "black",
                      paddingBottom: "15px",
                    }}
                  >
                    <StyledIcon
                      sx={{
                        color: "black",
                        backgroundImage: "white",
                      }}
                    >
                      <Avatar
                        sx={{ width: "90px", height: "90px" }}
                        src={companyList[0]?.company_Logo}
                        alt="profilepic"
                      />
                    </StyledIcon>

                    <Typography
                      variant="h5"
                      sx={{
                        marginTop: "25px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {companyList[0]?.companyName}
                    </Typography>

                    <Typography
                      variant="h5"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 0.5, marginLeft: "-22px" }}
                    >
                      <EmailIcon
                        sx={{ width: "15px", height: "15px", px: 1 }}
                      />
                      {companyList[0]?.email}
                    </Typography>
                    <Typography
                      variant="h5"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 0.5 }}
                    >
                      <PhoneIcon
                        sx={{ width: "15px", height: "15px", px: 1 }}
                      />{" "}
                      {companyList[0]?.phone_Number || "-"}
                    </Typography>
                  </Card>
                  {/* </Item> */}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={9}>
                  <Paper sx={{ marginTop: "0px", height: "100%" }}>
                    <Box>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          padding: "12px",
                          fontWeight: "bold",
                          marginLeft: "19px",
                        }}
                      >
                        Company Details
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                        marginTop: "-27px",
                        marginLeft: "19px",
                        marginRight: "19px",
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              {/* <Item> */}
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Company Name
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.companyName}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Company ID
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.id}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Company Type
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.companyTypeName}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Email
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.email}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Phone Number
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.phone_Number}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Owner Name
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.ownerName}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Partner Name
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.partnerName}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Company License Number
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.company_License_Number}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Company Registration Number
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {
                                      companyList[0]
                                        ?.company_Registration_Number
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Company Permit
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.companyPermit}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Insurance Details
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.insurance_Details}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Legal Representative Name
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.legal_Representative_Name}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Operational Region
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.operational_Region}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Percentage Sharing
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.percentage_Sharing}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Address
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.address}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Fax
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.Fax}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    Vehicle Type
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.vehicleTypeName}
                                  </Typography>
                                </Grid>
                              </Grid>
                              
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  boxShadow: "none",
                                  px: 1,
                                  marginTop: "-10px",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  md={6}
                                  sx={{ paddingTop: "12px", px: 2 }}
                                >
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black" }}
                                  >
                                    No Riders By Vehicle Type
                                  </Typography>
                                </Grid>
                                <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                                  <Typography
                                    textAlign="left"
                                    sx={{ color: "black", marginRight: "19px" }}
                                  >
                                    {companyList[0]?.no_Riders_By_Vehicle_Type}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {/* </Item> */}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <Paper
        sx={{ marginTop: { xs: "20px", sm: "15px", md: "15px", lg: "25px" } }}
      >
        <Box>
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              m: 1,
              padding: "12px",
              fontWeight: "bold",
            }}
          >
            Document Details
          </Typography>
        </Box>
        <Box
          sx={{
            color: "black",
            fontSize: "25px",
            m: 1,
            padding: "12px",
            fontWeight: "bold",
          }}
        >
          <Grid container spacing={2}>
            {companydocument?.map((row, index) => {
              const fileExtension = getFileExtension(row.image);

              return (
                <Grid item xs={4}>
                  <Box
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      gap: "10px",
                    }}
                    padding={"5px 20px"}
                    display={"flex"}
                    justifyContent={"space-between"} 
                    alignItems={'center'}
                  >
                    <a
                      href={row.image}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ width:'100%', textDecoration: "none",display:'flex',justifyContent:'space-between',alignItems:'center' }}
                    >
                      <Typography width={"200px"}>
                        {fileExtension === "pdf" ? "PDF" : "DOCX"}
                      </Typography>
                      <Typography
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {fileExtension === "pdf" ? (
                          <AiFillFilePdf />
                        ) : (
                          <FaFileWord />
                        )}
                      </Typography>
                    </a>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Paper>

      <Paper sx={{ marginTop: "25px" }}>
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
          margin="8px"
        >
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              padding: "12px",
              fontWeight: "bold",
            }}
          >
            Rider Details
          </Typography>
          <div>
          <Controls.Button
            startIcon={<AddIcon />}
            text="Add Rider"
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "blue",
              backgroundColor: "#fff",
              ":hover": { bgcolor: "#F1F5F9" },
              textTransform: "none",
              borderRadius: "15px",
              border: 1,
              borderColor: "#d3d3d3",
              boxShadow: 0,
              height: "40px",
              marginleft: "7px",
              marginright: "25px"
            }}
            onClick={() => {
              handleRiderModel();
            }}
          />
          <Controls.Button
            startIcon={<PersonIcon />}
            text="Company Rider Pending List"
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "blue",
              backgroundColor: "#fff",
              ":hover": { bgcolor: "#F1F5F9" },
              textTransform: "none",
              borderRadius: "15px",
              border: 1,
              borderColor: "#d3d3d3",
              boxShadow: 0,
              height: "40px",
              marginLeft: "20px",
            }}
            onClick={() => {
              setStateData(companyList[0].id);
              openCompanyRiderPendingModal();
              setIsEdit(false);            
            }}
          />
          </div>
        </Box>
        <Box
          sx={{
            maxWidth: {
              lg: "1250px",
            },
            marginTop: "-27px",
          }}
        >
          <Box
            sx={{
              marginTop: "40px",
              marginBottom: "20px",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ boxShadow: 0, paddingLeft: "0!important" }}
            >
              <Table
                aria-label="simple table"
                sx={{ paddingLeft: "0!important", minWidth: 1258 }}
              >
                <TableHead sx={{ paddingLeft: "0!important" }}>
                  <TableRow>
                    <StyledTableCell>Profile</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Is Pro</StyledTableCell>
                    <StyledTableCell>Vehicle Type</StyledTableCell>
                    <StyledTableCell>DOB</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    <StyledTableCell>Is Verified</StyledTableCell>
                    <StyledTableCell>Referral Code</StyledTableCell>
                    <StyledTableCell>Rating</StyledTableCell>
                    <StyledTableCell>DOJ</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    backgroundColor: "#fff",
                    paddingLeft: "0!important",
                  }}
                >
                  {riderassigns?.map((row) => {
                  console.log(row?.profilePic);
                    return (
                      <>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            <Avatar
                              component="span"
                              variant="rounded"
                              alt="profile_image"
                              src={row?.profilePic}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row?.name || "-"}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {proStatus(row?.isPro) || "-"}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row?.vehicleType || "-"}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.dateofBirth || "-"}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {row?.email ? (
                              <Typography>{row?.email}</Typography>
                            ) : (
                              <Typography>-</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.mobile || "-"}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            onClick={(e) => e.stopPropagation()}
                            sx={{
                              display: "flex",
                            }}
                          >
                            {IsVerify(row.isDocumentVerifiyed) || "-"}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.referalCode || "-"}
                          </StyledTableCell>

                          <StyledTableCell>
                            {row.avgRating ? (
                              <Typography>{row.avgRating} / 5</Typography>
                            ) : (
                              <Typography>0 / 5</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip
                                placement="bottom"
                                title="Delete Company"
                              >
                                <button
                                  className="action-button delete-btn"
                                  onClick={() => {
                                    setId(row.id);
                                    setDeleteId(row?.id);
                                    handleDeleteAssignCompanyModel();
                                  }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <CompanyRiderPendingModal
            open={CompanyRiderPending}
            setOpen={setCompanyRiderPending}
            handleClickOpen={openCompanyRiderPendingModal}
            handleClose={closeCompanyRiderPendingModal}
            stateData={stateData}
            refreshScreen={refreshScreen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </Box>
      </Paper>

      <Modal
        keepMounted
        open={openRiderModel}
        onClose={handleCloseRider}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Assign Rider
          </Typography>
          <Box>
            <Box sx={{ marginTop: "30px" }}>
              <Autocomplete
                name="riderId"
                value={selectedOption}
                onChange={handleOptionChange}
                options={riderLists}
                variant="outlined"
                color="primary"
                getOptionLabel={(option) => option.name}
                style={{ width: 390 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a rider" />
                )}
              />
            </Box>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={12} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Save"
                  type="submit"
                  onClick={() => {
                    handleAssignRiderModel();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openAssignRiderModel}
        onClose={handleCloseAssignRider}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Assign Rider
          </Typography>
          <label className="fontLight">
            Are you sure to want to assign rider ?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="No"
                onClick={handleCloseAssignRider}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Yes"
                onClick={() => handleassignrider()}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteAssignCompanyModel}
        onClose={handleCloseDeleteAssignCompany}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete assign rider?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{ backgroundColor: "#fff", color: "#000" }}
                text="Cancel"
                onClick={handleCloseDeleteAssignCompany}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDeleteAssignCompany(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
       <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseDelete}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDeleteDetails(id)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default DetailsStudent;
