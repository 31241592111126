import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import {
  dumpAllContry
} from "../../state/action/countryAction";
import axios from "axios";
import {
  AddCountry,
  updateCountry,
} from "../../state/action/countryAction";

const initialValues = {
  name: "",
  north: "",
  south: "",
  west: "",
  east: "",
};


const AddNeedHelp = (props) => {
  const { open, handleClose, CountryData, refreshScreen, setIsEdit, isEdit, allCountries } =
    props;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    // if ("north" in fieldValues) {
    //   if (fieldValues.north !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.north)) {
    //       temp.north = "Invalid Input";
    //     } else {
    //       temp.north = "";
    //     }
    //   } else {
    //     temp.north = "This field is required.";
    //   }
    // }

    // if ("south" in fieldValues) {
    //   if (fieldValues.south !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.south)) {
    //       temp.south = "Invalid Input";
    //     } else {
    //       temp.south = "";
    //     }
    //   } else {
    //     temp.south = "This field is required.";
    //   }
    // }

    // if ("west" in fieldValues) {
    //   if (fieldValues.west !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.west)) {
    //       temp.west = "Invalid Input";
    //     } else {
    //       temp.west = "";
    //     }
    //   } else {
    //     temp.west = "This field is required.";
    //   }
    // }

    // if ("east" in fieldValues) {
    //   if (fieldValues.east !== "") {
    //     if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.east)) {
    //       temp.east = "Invalid Input";
    //     } else {
    //       temp.east = "";
    //     }
    //   } else {
    //     temp.east = "This field is required.";
    //   }
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        name: values.name,
        north: values.north,
        south: values.south,
        west: values.west,
        east: values.east,
      };

      if (isEdit) {
        const res = await updateCountry(CountryData.id, body);
        if (res && res.status === true) {
          close();
          refreshScreen();
          resetForm();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await AddCountry(body);
        if (res && res.status === true) {
          close();
          refreshScreen();
          resetForm();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };


  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };


  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        name: CountryData.dumpContryId,
        north: CountryData.north,
        south: CountryData.south,
        west: CountryData.west,
        east: CountryData.east,
      });
    } else {
      resetForm();
    }

  }, [isEdit, CountryData]);


  const handleSelectedCountry = async (event) => {
    const countryId = event.target.value;
    const selectedCountry = allCountries.find(item => item.id === countryId);

    if (selectedCountry) {
      try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: {
            address: selectedCountry.name,
            key: 'AIzaSyD2cfignyIQC94CJmpxD5XdLakeuyAOOnQ' // Replace 'YOUR_API_KEY' with your actual API key
          }
        });

        const { data } = response;
        if (data.status === "OK" && data.results?.length) {
          const bounds = data.results[0].geometry.bounds;

          if (bounds) {
            setValues(prevValues => ({
              ...prevValues,
              name: countryId,
              north: bounds.northeast.lat,
              south: bounds.southwest.lat,
              west: bounds.southwest.lng,
              east: bounds.northeast.lng
            }));
          }
        } else {
          enqueueSnackbar("Selected country not found", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        enqueueSnackbar("Selected country not found", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };


  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        sx={{ width: "40%", marginLeft: "450px", color: "black" }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Country " : "Add Country"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  resetForm();
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  {/* <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name}
                  /> */}
                  <Controls.SelectBox
                    className="input"
                    label="Select Country"
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    // onChange={handleInputChange}
                    onChange={handleSelectedCountry}
                    options={allCountries}
                    error={errors.name}
                    helperText={errors.name}
                  />
                  {errors && errors.name ? (
                    <FormHelperText
                      sx={{ color: "#f44336", marginLeft: "16px !important" }}
                    >
                      {errors.name}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <table border={2} style={{ color: "black", border: "1px solid #dddddd", padding: "10px", borderCollapse: "collapse", width: "100%" }}>
                    {/* <th> */}
                    <tr>
                      <th>North</th>
                      <th>South</th>
                      <th>West</th>
                      <th>East</th>
                    </tr>
                    <tr>
                      <td>{values?.north}</td>
                      <td>{values?.south}</td>
                      <td>{values?.west}</td>
                      <td>{values?.east}</td>
                    </tr>
                  </table>
                </Grid>
                {/* <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="North"
                    variant="outlined"
                    color="primary"
                    label="North"
                    name="north"
                    value={values.north}
                    onChange={handleInputChange}
                    error={errors.north}
                    helperText={errors.north}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="South"
                    variant="outlined"
                    color="primary"
                    label="South"
                    name="south"
                    value={values.south}
                    onChange={handleInputChange}
                    error={errors.south}
                    helperText={errors.south}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="West"
                    variant="outlined"
                    color="primary"
                    label="West"
                    name="west"
                    value={values.west}
                    onChange={handleInputChange}
                    error={errors.west}
                    helperText={errors.west}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="East"
                    variant="outlined"
                    color="primary"
                    label="East"
                    name="east"
                    value={values.east}
                    onChange={handleInputChange}
                    error={errors.east}
                    helperText={errors.east}
                    inputProps={{ maxLength: 10 }}
                    disabled={true}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "-10px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default AddNeedHelp;
