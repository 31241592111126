// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import { useNavigate } from "react-router-dom";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import PaymentIcon from "@mui/icons-material/Payment";
import BikeScooterIcon from "@mui/icons-material/BikeScooter";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import { Tooltip } from "@mui/material";
import Logo from "../../../assets/Logo.png";
import MonetizationOnSharpIcon from '@mui/icons-material/MonetizationOnSharp';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Navigate = useNavigate();

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(title);
        Navigate(to);
      }}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item": {
          padding: "5px 10px 5px 10px !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item:hover": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item.active": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
      }}
    >
      <Sidebar
        id="sidebar"
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[600]}
        image={sidebarImage}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : sidebarRTL ? (
                <SwitchLeftOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              ) : (
                <SwitchRightOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              )
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" sx={{ color: "#fff" }}>
                  <img src={Logo} alt="" style={{ width: "81px" }} />
                </Typography>
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box
            display={collapsed ? "none" : "block"}
            padding={collapsed ? undefined : "0% 3%"}
          >
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<GridViewIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <SubMenu
              label="Master"
              sx={{ backgroundColor: "transparent" }}
              icon={<SettingsIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Parameter Values"
                  to="/parameterValue"
                  icon={<LocationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Payment Mode"
                  to="/paymentmode"
                  icon={<PaymentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Settings"
                  to="/setting"
                  icon={<MonetizationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Vehicle Type"
                  to="/vehicle"
                  icon={<BikeScooterIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              {/*<MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Parcel Type"
                  to="parcel"
                  icon={<LocationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                </MenuItem>*/}

              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Banner"
                  to="/banner"
                  icon={<AdUnitsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>

              <MenuItem>
                <Item
                  title="Privacy Policy"
                  to="/privacy-policy"
                  icon={<PrivacyTipIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Terms and conditions"
                  to="/terms-condition"
                  icon={<AcUnitIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>

              <MenuItem>
                <Item
                  title="Coupons & Offers"
                  to="/coupons-offers"
                  icon={<LocalOfferIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>

              <MenuItem>
                <Item
                  title="Subscription Plan"
                  to="/subscription"
                  icon={<MonetizationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>

              <MenuItem>
                <Item
                  title="Need Help"
                  to="/needhelp"
                  icon={<LocationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Company"
                  to="/company"
                  icon={<BusinessIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>

            <SubMenu
              label="Manage Users"
              sx={{ backgroundColor: "transparent" }}
              icon={<SwitchAccountIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="User"
                  to="/user"
                  icon={<PersonIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Rider"
                  to="/rider"
                  icon={<DirectionsBikeIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>

            <Item
              title="Orders"
              to="/orders"
              icon={<ShoppingCartIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Profit Sharing"
              to="/profitsharing"
              icon={<MonetizationOnSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <SubMenu
              label="Payment"
              sx={{ backgroundColor: "transparent" }}
              icon={<PaymentIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Order Transaction"
                  to="payment"
                  icon={<PaymentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Plan Transaction"
                  to="planpayment"
                  icon={<PaymentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              {/* <MenuItem>
                <Item
                  title="Withdraw Request "
                  to="withdraw"
                  icon={<PaymentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem> */}
            </SubMenu>

            <SubMenu
              label="Locations"
              sx={{ backgroundColor: "transparent" }}
              icon={<LocationOnIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Countries"
                  to="/country"
                  icon={<LocationCityIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="State"
                  to="/state"
                  icon={<MapIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="City"
                  to="/city"
                  icon={<LocationCityIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>

            <SubMenu
              label="Notification"
              sx={{ backgroundColor: "transparent" }}
              icon={<NotificationsIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Push Notification"
                  to="/push-notification"
                  icon={<NotificationsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Notification"
                  to="/notification"
                  icon={<NotificationsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>
          </Box>
          <Box
            display={collapsed ? "block" : "none"}
            padding={collapsed ? undefined : "0% 3%"}
          >
            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Dashboard"
              arrow
            >
              <div>
                <Item
                  to="/"
                  icon={<GridViewIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Master"
              arrow
            >
              <SubMenu
                sx={{ backgroundColor: "transparent" }}
                icon={<SettingsIcon />}
              >
                <MenuItem
                  className="collapse-menu-view"
                  style={{ paddingLeft: collapsed ? "0px" : "20px" }}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Parameter Values"
                    to="/parameterValue"
                    icon={<LocationOnIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem sx={{ backgroundColor: "transparent" }}>
                  <Item
                    title="Payment Mode"
                    to="/paymentmode"
                    icon={<PaymentIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem sx={{ backgroundColor: "transparent" }}>
                  <Item
                    title="Settings"
                    to="/setting"
                    icon={<MonetizationOnIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem
                  className="collapse-menu-view"
                  style={{ paddingLeft: collapsed ? "0px" : "20px" }}
                >
                  <Item
                    title="Vehicle Type"
                    to="/vehicle"
                    icon={<BikeScooterIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>

                {/* <MenuItem
                  className="collapse-menu-view"
                  style={{ paddingLeft: collapsed ? "0px" : "20px" }}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Parcel Type"
                    to="parcel"
                    icon={<LocationOnIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
              </MenuItem>*/}
                <MenuItem sx={{ backgroundColor: "transparent" }}>
                  <Item
                    title="Banner"
                    to="/banner"
                    icon={<AdUnitsIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem
                  className="collapse-menu-view"
                  style={{ paddingLeft: collapsed ? "0px" : "20px" }}
                >
                  <Item
                    title="Privacy Policy"
                    to="/privacy-policy"
                    icon={<PrivacyTipIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem
                  className="collapse-menu-view"
                  style={{ paddingLeft: collapsed ? "0px" : "20px" }}
                >
                  <Item
                    title="Terms and conditions"
                    to="/terms-condition"
                    icon={<AcUnitIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>

                <MenuItem
                  className="collapse-menu-view"
                  style={{ paddingLeft: collapsed ? "0px" : "20px" }}
                >
                  <Item
                    to="/coupons-offers"
                    icon={<LocalOfferIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>

                <MenuItem sx={{ backgroundColor: "transparent" }}>
                  <Item
                    title="Subscription Plan"
                    to="/subscription"
                    icon={<MonetizationOnIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
              </SubMenu>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Manage Users"
              arrow
            >
              <SubMenu
                label={collapsed ? "" : "Manage Users"}
                sx={{ backgroundColor: "transparent" }}
                icon={<SwitchAccountIcon />}
              >
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="User"
                    to="/user"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="Rider"
                    to="/rider"
                    icon={<DirectionsBikeIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
              </SubMenu>
            </Tooltip>
            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Locations"
              arrow
            >
              <SubMenu
                sx={{ backgroundColor: "transparent" }}
                icon={<LocationOnIcon />}
              >
                <MenuItem
                  className="collapse-menu-view"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Item
                    title="State"
                    to="/state"
                    icon={<MapIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
                <MenuItem className="collapse-menu-view">
                  <Item
                    title="City"
                    to="/city"
                    icon={<LocationCityIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </MenuItem>
              </SubMenu>
            </Tooltip>
            <Tooltip placement="right" title="Orders" arrow>
              <div>
                <Item
                  to="/orders"
                  icon={<ShoppingCartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>
            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Payments"
              arrow
            >
              <div>
                <Item
                  to="payment"
                  icon={<PaymentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Push Notification"
              arrow
            >
              <div>
                <Item
                  to="/push-notification"
                  icon={<NotificationsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
