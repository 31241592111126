import { Box } from "@mui/system";
import React from "react";
import CouponsOffersList from "./CouponsOffersList";

const CouponsOffers = () => {
    return (
        <Box>
            <CouponsOffersList />
        </Box>
    );
};

export default CouponsOffers;


