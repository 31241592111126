import {useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  
} from "@mui/material";
import { Grid,IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  mobile: "",
  country: "",
  state: "",
  city: "",
  address: "",
  zipcode: "",
};

const types = [
  { id: "1", title: "Fixed" },
  { id: "2", title: "Percentage" },
];

const AddExtraCharges = (props) => {
  const { open, handleClose } = props;
  
  const [edit, setEdit] = useState(false);


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("vehicleName" in fieldValues)
      temp.vehicleName = fieldValues.vehicleName
        ? ""
        : "This field is required.";
    if ("vehicleType" in fieldValues)
      temp.vehicleType = fieldValues.vehicleType
        ? ""
        : "This field is required.";

    if ("capacity" in fieldValues)
      temp.capacity = fieldValues.capacity ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const close = () => {
    reset();
    handleClose();
  };

  const handleSubmit = async (e) => {};

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const CountryList = [
    { id: "1", title: "india" },
    { id: "0", title: "Dubai" },
    { id: "3", title: "abcd" },
  ];
  const CityList = [{ id: "0", title: "Pune" }];

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{ width: "50%", marginLeft: "430px", color: "black" }}
      >
        <DialogTitle
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "30px",
            display: "flex",
            justifyContent: "space-between",
            color: "black",
          }}
        >
          {edit ? "Edit Extra Charges" : "Add New Extra Charges"}
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                close();
              }}
            >
              <Close sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Form onSubmit={handleSubmit} id="addNewBuyer">
            <Grid container spacing={2}>
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
                container
                spacing={1}
                sx={{ display: "contents" }}
              >
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.SelectBox
                    className="input"
                    fullWidth
                    id="user"
                    name="user"
                    label="Select Country"
                    value={values.user}
                    onChange={handleInputChange}
                    options={CountryList}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.SelectBox
                    className="input"
                    fullWidth
                    id="user"
                    name="user"
                    label="Select City"
                    value={values.user}
                    onChange={handleInputChange}
                    options={CityList}
                  />
                </Grid>

                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Title"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                    error={errors.title}
                    helperText={errors.title}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Charge"
                    name="charge"
                    value={values.charge}
                    onChange={handleInputChange}
                    error={errors.charge}
                    helperText={errors.charge}
                  />
                </Grid>

                <Grid item sx={{ m: 1 }} md={12} xs={12}>
                  <Controls.SelectBox
                    className="input"
                    fullWidth
                    id="user"
                    name="user"
                    label="Select"
                    value={values.user}
                    onChange={handleInputChange}
                    options={types}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#fff",
              backgroundColor: "Red",
              ":hover": { bgcolor: "Red" },
              textTransform: "none",
              borderRadius: "50px",
              boxShadow: 0,
            }}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              borderRadius: "50px",
              border: "0",
              color: "#fff",
              ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
              backgroundColor: "rgb(79, 70, 229)",
              marginLeft: {
                xs: "0px",
                sm: "15px",
                md: "15px",
                lg: "15px",
              },
              textTransform: "none",
            }}
            type="submit"
            form="addnewUser"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddExtraCharges;
