import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Tooltip,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../pages/Rider/rider.css";
import { useLocation, useNavigate } from "react-router";
import { GrView } from "react-icons/gr";
import moment from "moment";
import { styled } from "@mui/system";
import { Form, useForm } from "../../Utils/useForm";
// import { getRiderDetails } from "../../state/action/riderAction";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Controls from "../../Component/controls/Controls";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FlagIcon from "@mui/icons-material/Flag";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { useProSidebar } from "react-pro-sidebar";
import {
  AddressDefault,
  DeleteAddress,
  DeleteUser,
  getUserDetails,
  updateAddress,
} from "../../state/action/userAction";
import { useSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import VerifiedIcon from "../../assets/user.png";

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
  boxShadow: "none",
  borderRadius: "20px",
}));

const initialValues = {
  address: "",
};

const DetailsUser = () => {
  const [userList, setUserList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [deviceDetailsList, setDeviceDetailsList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [addressName, setAddressName] = useState([]);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openAddressModel, setOpenAddressModel] = useState(false);
  const [openDeleteAddressModel, setOpenDeleteAddressModel] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState();
  const [AddressId, setAddressId] = useState();
  const { collapsed, broken } = useProSidebar();
  const [openAddressDefaultModel, setOpenAddressDefaultModel] = useState(false);
  const [planHistoryList, setplanHistoryList] = useState([]);

  //delete the user
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  //edit the address
  const handleCloseAddress = () => setOpenAddressModel(false);
  const handleAddressModel = () => setOpenAddressModel(true);

  //delete the Address

  const handleCloseDeleteAddress = () => setOpenDeleteAddressModel(false);
  const handleDeleteAddressModel = () => setOpenDeleteAddressModel(true);

  //change default
  const handleCloseAddressDefualt = () => setOpenAddressDefaultModel(false);
  const handleAddressDefaultModel = () => setOpenAddressDefaultModel(true);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { state } = useLocation();
  const id = state.user;

  //get details

  const getDetails = async () => {
    const res = await getUserDetails(id);
    if (res && res.status) {
      setUserList(res?.data);
      setAddressList(res.data.address);
      setDeviceDetailsList(res.data.deviceDetails);
      setOrdersList(res.data.orders);
      setplanHistoryList(res.data.planHistory);
    }
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  //device status
  const deviceStatus = (deviceStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "black",
      },
      0: {
        key: "No",
        color: "black",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[deviceStatus]}
        label={statusColors[deviceStatus] ? statusColors[deviceStatus].key : ""}
      />
    );
  };

  //paln status
  const planStatus = (planStatus) => {
    const statusColors = {
      1: {
        key: "Success",
        color: "green",
      },
      2: {
        key: "Failed",
        color: "#ff0000",
      },
      3: {
        key: "Pending",
        color: "#fc7e00",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[planStatus]}
        label={statusColors[planStatus] ? statusColors[planStatus].key : ""}
      />
    );
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const orderStatus = (orderStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Order Pickup Schedule",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: " Pickedup Package",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      3: {
        key: " Packagein Transit",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      4: {
        key: "Delivered",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      5: {
        key: "Cancelled",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
      6: {
        key: "Failed",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[orderStatus]}
        label={statusColors[orderStatus] ? statusColors[orderStatus].key : ""}
      />
    );
  };

  //update Address
  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {
      address: values.address,
    };
    if (validate()) {
      const res = await updateAddress(values.id, id, body);
      if (res && res.status === true) {
        handleCloseAddress();
        getDetails();
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  //address
  useEffect(() => {
    if (
      addressName.id != "" &&
      addressName.id != null &&
      addressName.id != undefined
    ) {
      setValues({
        ...values,
        address: addressName.address,
        id: addressName?.id,
      });
    }
  }, [addressName]);

  //change default

  const handleDefault = async (e) => {
    e.preventDefault();

    const res = await AddressDefault(AddressId, id);

    if (res && res.status === true) {
      handleCloseAddressDefualt();
      getDetails();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //delete address
  const handleDelete = async () => {
    const res = await DeleteAddress(deleteAddressId, id);
    if (res && res.status === true) {
      handleCloseDeleteAddress();
      getDetails();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //delete the details

  const handleDeleteDetails = async () => {
    const res = await DeleteUser(id);
    if (res && res.status === true) {
      handleCloseDelete();
      navigate("/user");
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/user");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Users
          </Typography>
          <div>
            <Controls.Button
              text="Back"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "50px",
                border: "0",
                color: "white",
                ":hover": { bgcolor: "black", border: "0" },
                backgroundColor: "black",
                textTransform: "none",
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => {
                navigate("/user");
              }}
            />

            <Controls.Button
              text="Delete"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#fff",
                backgroundColor: "Red",
                ":hover": { bgcolor: "Red" },
                textTransform: "none",
                borderRadius: "50px",
                boxShadow: 0,
                marginLeft: "7px",
              }}
              onClick={() => {
                handleDeleteModel();
              }}
            />
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              md: "800px",
              lg: "100%",
            },
            marginLeft: "-5px",
          }}
        >
          <Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <Item>
                    <Card
                      sx={{
                        paddingTop: "24px",
                        textAlign: "center",
                        color: "black",
                        paddingBottom: "15px",
                      }}
                    >
                      <StyledIcon
                        sx={{
                          color: "black",
                          backgroundImage: "white",
                        }}
                      >
                        <Avatar
                          sx={{ width: "90px", height: "90px" }}
                          src={userList?.profilePic}
                          alt="profilepic"
                        />
                      </StyledIcon>

                      <Typography
                        variant="h4"
                        sx={{
                          marginTop: "25px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        {userList?.name}{" "}
                        {userList?.isPro !== 0 ? (
                          <img
                            src={VerifiedIcon}
                            alt=""
                            style={{
                              width: "30px",
                              height: "31px",
                              color: "blue",
                            }}
                          />
                        ) : null}
                      </Typography>

                      <Typography
                        variant="h5"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ py: 0.5, marginLeft: "-22px" }}
                      >
                        <EmailIcon
                          sx={{ width: "15px", height: "15px", px: 1 }}
                        />{" "}
                        {userList?.email}
                      </Typography>

                      <Typography
                        variant="h5"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ py: 0.5, marginLeft: "-33px" }}
                      >
                        <PhoneIcon
                          sx={{ width: "15px", height: "15px", px: 1 }}
                        />{" "}
                        {userList?.countryCode} {userList?.mobile}
                      </Typography>
                      <Typography
                        variant="h5"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ py: 0.5 }}
                      >
                        <b style={{ marginRight: "5px" }}>Referal Code: </b>
                        {userList?.referalCode}
                      </Typography>

                      <Typography
                        variant="h5"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ py: 0.5 }}
                      >
                        <CalendarTodayIcon
                          sx={{ width: "15px", height: "15px", px: 1 }}
                        />{" "}
                        {moment(userList?.createdAt).format("MM-DD-YYYY LT")}
                      </Typography>
                    </Card>
                  </Item>

                  <Item sx={{ my: 2 }}>
                    <Card
                      sx={{
                        py: 1,
                        boxShadow: 0,
                        color: "black",
                      }}
                    >
                      <Typography
                        variant="h4"
                        display="flex"
                        sx={{ paddingBottom: "10px", px: 2 }}
                      >
                        Address
                      </Typography>
                      <Divider
                        sx={{
                          bgcolor: "rgba(107, 114, 128, .4)",
                          marginBottom: "10px",
                        }}
                      />
                      {addressList?.map((row) => {
                        return (
                          <>
                            <Typography sx={{ my: 2 }} textAlign="left">
                              {row?.address}
                            </Typography>

                            <Box
                              display="flex"
                              justifyContent="space-between"
                              sx={{ marginTop: "5px", marginBottom: "10px" }}
                            >
                              <button
                                disabled={row?.isDefault == 1}
                                style={{ marginRight: "5px" }}
                                className={
                                  row?.isDefault == 1
                                    ? "default-button"
                                    : "make-default"
                                }
                                onClick={() => {
                                  setAddressId(row.id);
                                  handleAddressDefaultModel();
                                }}
                              >
                                {row?.isDefault == 1
                                  ? "Default"
                                  : "Select as default"}
                              </button>

                              <div>
                                {/* <button
                                  style={{ marginRight: "5px" }}
                                  className="edit-button "
                                  onClick={() => {
                                    setAddressName(row);
                                    handleAddressModel();
                                  }}
                                >
                                  <RiEditLine />
                                </button> */}

                                <button
                                  className="edit-button "
                                  onClick={() => {
                                    setDeleteAddressId(row?.id);
                                    handleDeleteAddressModel();
                                  }}
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </div>
                            </Box>
                            <Divider
                              sx={{
                                bgcolor: "rgba(107, 114, 128, .4)",
                                marginBottom: "10px",
                              }}
                            />
                          </>
                        );
                      })}
                    </Card>
                    {addressList && addressList.length === 0 ? (
                      <div
                        className="noDataFound"
                        style={{
                          display: "flex",
                        }}
                      >
                        {" "}
                        No Data Found
                      </div>
                    ) : null}
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={9}>
                  <Paper sx={{ marginTop: "0px" }}>
                    <Box>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          padding: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Device Details
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                        marginTop: "-27px",
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                        >
                          <Table
                            aria-label="simple table"
                            sx={{ paddingLeft: "0!important", minWidth: 1225 }}
                          >
                            <TableHead sx={{ paddingLeft: "0!important" }}>
                              <TableRow
                                sx={{
                                  backgroundColor: "#f6f9fb",
                                  paddingLeft: "0!important",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Is Logged In
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Device Id
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Model
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Platform
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Device Version
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  App Version
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Registration Date
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Last Used
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Ip
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                backgroundColor: "#fff",
                                paddingLeft: "0!important",
                              }}
                            >
                              {deviceDetailsList?.map((row) => {
                                return (
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {deviceStatus(row?.isLoggedIn)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.deviceID}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.model}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.platform}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.version}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.appVersion}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {moment(row.createdAt).format(
                                        "MM-DD-YYYY LT"
                                      )}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {moment(row.lastUsed).format(
                                        "MM-DD-YYYY LT"
                                      )}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.ip}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                          {deviceDetailsList &&
                            deviceDetailsList.length === 0 ? (
                            <div
                              className="noDataFound"
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              No Data Found
                            </div>
                          ) : null}
                        </TableContainer>
                      </Box>
                    </Box>
                  </Paper>

                  <Paper
                    sx={{
                      marginTop: {
                        xs: "20px",
                        sm: "15px",
                        md: "15px",
                        lg: "25px",
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          m: 1,
                          padding: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Plan Transaction History
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                        marginTop: "-27px",
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                        >
                          <Table
                            aria-label="simple table"
                            sx={{ paddingLeft: "0!important", minWidth: 1233 }}
                          >
                            <TableHead sx={{ paddingLeft: "0!important" }}>
                              <TableRow
                                sx={{
                                  backgroundColor: "#f6f9fb",
                                  paddingLeft: "0!important",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Plan Status
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Plan Name
                                </TableCell>
                                {/* <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Plan Date
                                </TableCell> */}
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Start Date
                                </TableCell>{" "}
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  End Date
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Due Days
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Transaction Ref.No
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Amount
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                backgroundColor: "#fff",
                                paddingLeft: "0!important",
                              }}
                            >
                              {planHistoryList?.map((row) => {
                                return (
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {planStatus(row?.transactionStatus)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.planName}
                                    </TableCell>
                                    {/* <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {moment(row.createdAt).format(
                                        "MM-DD-YYYY LT"
                                      )}
                                    </TableCell> */}
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {moment(row.startDate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {moment(row.endDate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.DueDays}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.transactionNo}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.amount}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                          {planHistoryList && planHistoryList.length === 0 ? (
                            <div
                              className="noDataFound"
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              No Data Found
                            </div>
                          ) : null}
                        </TableContainer>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "60px" }}>
                  <Paper sx={{ marginTop: "0px" }}>
                    <Box
                      display="flex"
                      alignItems="baseline"
                      justifyContent="space-between"
                      paddingRight="5px"
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          padding: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Order History
                      </Typography>
                      <Controls.Button
                        text="View All"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "blue",
                          backgroundColor: "#fff",
                          ":hover": { bgcolor: "#F1F5F9" },
                          textTransform: "none",
                          borderRadius: "15px",
                          border: 1,
                          borderColor: "#d3d3d3",
                          boxShadow: 0,
                          height: "40px",
                        }}
                        onClick={() => {
                          navigate("/orders", {
                            state: id,
                          });
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                        marginTop: "-27px",
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                        >
                          <Table
                            aria-label="simple table"
                            sx={{ paddingLeft: "0!important", minWidth: 1198 }}
                          >
                            <TableHead sx={{ paddingLeft: "0!important" }}>
                              <TableRow
                                sx={{
                                  backgroundColor: "#f6f9fb",
                                  paddingLeft: "0!important",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Order No
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Rider Name
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Source Location
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Destination Location
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Vehicle Type
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Amount
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Date
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Status
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                backgroundColor: "#fff",
                                paddingLeft: "0!important",
                              }}
                            >
                              {ordersList?.map((row) => {
                                return (
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          color: "blue",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          navigate("/orderdetails", {
                                            state: row?.id,
                                          });
                                        }}
                                      >
                                        {row?.orderNumber}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          color: "blue",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          navigate("/riderdetails", {
                                            state: row?.riderID,
                                          });
                                        }}
                                      >
                                        {row?.riderName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.startLocation}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.endLocation}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.vehicleType}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {row?.paybleCost}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      {moment(row.createdAt).format(
                                        "MM-DD-YYYY LT"
                                      )}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          color: "#000000c7",
                                        }}
                                      >
                                        {orderStatus(row?.orderStatus)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      <Tooltip
                                        placement="bottom"
                                        title="View Order"
                                      >
                                        <button
                                          className="action-button view"
                                          onClick={() => {
                                            navigate("/orderdetails", {
                                              state: row?.id,
                                            });
                                          }}
                                        >
                                          <GrView />
                                        </button>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                          {ordersList && ordersList.length === 0 ? (
                            <div
                              className="noDataFound"
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              No Data Found
                            </div>
                          ) : null}
                        </TableContainer>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={openAddressModel}
        onClose={handleCloseAddress}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Edit Address
          </Typography>
          <Form onSubmit={handleSubmit}>
            <Box>
              <Box sx={{ marginTop: "30px" }}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Address"
                  name="address"
                  value={values.address}
                  onChange={handleInputChange}
                  fullWidth
                  error={errors.address}
                  helperText={errors.address}
                />
              </Box>
              <Grid container style={{ marginTop: "2rem" }}>
                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    style={{ backgroundColor: "#fff", color: "#000" }}
                    text="Cancel"
                    onClick={handleCloseAddress}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    style={{ marginRight: "7px" }}
                    text="Save"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseDelete}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDeleteDetails(id)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteAddressModel}
        onClose={handleCloseDeleteAddress}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseDeleteAddress}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDelete(deleteAddressId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openAddressDefaultModel}
        onClose={handleCloseAddressDefualt}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Default Address
          </Typography>
          <label className="fontLight">
            Are you sure to want change default address?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={handleCloseAddressDefualt}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Change"
                onClick={handleDefault}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DetailsUser;
