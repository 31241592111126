import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Chart from "react-apexcharts";
import { useLocation } from "react-router";
import { styled } from "@mui/system";
import { useForm } from "../../Utils/useForm";
import { getDashboardDatas } from "../../state/action/dashboardAction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";


const chartOptions1 = {
  series: [],
  options: {
    colors: ["#111827"],
    chart: {
      toolbar: {
        show: false, // This will hide the default toolbar
      },
      type: "bar",
      // foreColor: "#ffffff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      y: {
        formatter: function (val) {
          // return "$ " + val + " thousands";
          return val;
        },
      },
    },
  },
};

const chartOptions2 = {
  series: [],
  options: {
    colors: ["#111827"],
    chart: {
      toolbar: {
        show: false, // This will hide the default toolbar
      },
      type: "bar",
      // foreColor: "#ffffff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      y: {
        formatter: function (val) {
          // return "$ " + val + " thousands";
          return val;
        },
      },
    },
  },
};

const chartOptions3 = {
  series: [],
  options: {
    colors: ["#111827"],
    chart: {
      toolbar: {
        show: false, // This will hide the default toolbar
      },
      type: "bar",
      // foreColor: "#ffffff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      y: {
        formatter: function (val) {
          // return "$ " + val + " thousands";
          return val;
        },
      },
    },
  },
};

const chartOptions = {
  series: [
    {
      name: "Rider",
      data: [40, 70, 20, 90, 36, 80, 30, 91, 60],
    },
    {
      name: "User",
      data: [40, 30, 70, 80, 40, 16, 40, 20, 51],
    },
  ],
  options: {
    colors: ["#111827", "#faa59a"],

    chart: {
      toolbar: {
        show: false,
      },
      // foreColor: "#ffffff",
      type: "line",
      zoom: {
        enabled: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    labels: {
      fontColor: "white",
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [],
    },

    legend: {
      position: "top",
      align: "left",
      labels: {
        fontColor: "white",
      },
    },
    grid: {
      show: true,
    },
  },
};

export default function BasicTabs() {
  const [menu, setMenus] = useState(3);
  const [totalUser, settotalUser] = useState(0);
  const [totalRider, settotalRider] = useState(0);
  const [totalOrder, settotalOrder] = useState(0);
  const [totalpaymentSuccess, settotalpaymentSuccess] = useState(0);
  const [totalpaymentFailed, settotalpaymentFailed] = useState(0);
  const [totalOrderTransit, settotalOrderTransit] = useState(0);
  const [totalCardpayment, settotalCardpayment] = useState(0);
  const [totalBikeData, setBikeData] = useState(0);
  const [totalMiniTruckData, setMiniTruckData] = useState(0);
  const [totalBigTruckData, setBigTruckData] = useState(0);
  const [totalTowingTruckData, setTowingTruckData] = useState(0);

  
  const [totalriderOrderData, setriderOrderData] = useState(0);
  const [totalriderpaymentSuccessData, setriderpaymentSuccessData] = useState(0);
  const [totalriderpaymentFailedData, setriderpaymentFailedData] = useState(0);
  const [totalriderOrderTransitData, setriderOrderTransitData] = useState(0);
  const [totalriderCardpaymentOrderData, setriderCardpaymentOrderData] = useState(0);
  const [totalriderBikeData, setriderBikeData] = useState(0);
  const [totalriderMiniTruckData, setriderMiniTruckData] = useState(0);
  const [totalriderBigTruckData, setriderBigTruckData] = useState(0);
  const [totalriderTowingTruckData, setriderTowingTruckData] = useState(0);
  const [totalcompanyrider, setcompanyrider] = useState(0);
  const [orderCount, setorderCount] = useState([]);
  const [delayerOrders, setdelayerOrders] = useState([]);
  const [usercount, setusercount] = useState([]);
  const [ridercount, setridercount] = useState([]);
  const [gettotalUserData, setgettotalUserData] = useState(0);
  const [gettotalRiderData, setgettotalRiderData] = useState(0);
  const [monthorderCount, setmonthorderCount] = useState([]);
  const [orderDataChart, setOrderDataChart] = useState(chartOptions1);
  const [companyriderData, setcompanyriderData] = useState(chartOptions2);
  const [userandriderData, setuserandriderData] = useState(chartOptions);
  const [companyRiderOrderChart, setcompanyRiderOrderChart] = useState(chartOptions3);
  
  const Role = useSelector((state) => state?.auth?.user);
  console.log(Role.id);
  const filterList = [
    {
      id: 1,
      name: "This Week",
    },
    {
      id: 2,
      name: "Previous Week",
    },
    {
      id: 3,
      name: "This Month",
    },
    {
      id: 4,
      name: "Previous Month",
    },
    {
      id: 5,

      name: "This Quarter",
    },
    {
      id: 6,
      name: "Previous Quarter",
    },
    {
      id: 7,
      name: "This Year",
    },
    {
      id: 8,
      name: "Previous Year",
    },
  ];

  const { state } = useLocation();
  let queryString = `?filterUser=this_month&companyId=${Role.id}`;
  const handleMenuChange = (e, value) => {
    setMenus(e.target.value);
    if (e.target.value === 1) {
      queryString = `?filterUser=this_week&companyId=${Role.id}`;
      getDashboardData(1, queryString);
    }
    if (e.target.value === 2) {
      queryString = `?filterUser=previous_week&companyId=${Role.id}`;
      getDashboardData(2, queryString);
    }
    if (e.target.value === 3) {
      queryString = `?filterUser=this_month&companyId=${Role.id}`;
      getDashboardData(3, queryString);
    }
    if (e.target.value === 4) {
      queryString = `?filterUser=previous_month&companyId=${Role.id}`;
      getDashboardData(4, queryString);
    }
    if (e.target.value === 5) {
      queryString = `?filterUser=this_quarter&companyId=${Role.id}`;
      getDashboardData(5, queryString);
    }
    if (e.target.value === 6) {
      queryString = `?filterUser=previous_quarter&companyId=${Role.id}`;
      getDashboardData(6, queryString);
    }
    if (e.target.value === 7) {
      queryString = `?filterUser=this_year&companyId=${Role.id}`;
      getDashboardData(7, queryString);
    }
    if (e.target.value === 8) {
      queryString = `?filterUser=previous_year&companyId=${Role.id}`;
      getDashboardData(8, queryString);
    }
  };


  const getDashboardData = async (value, queryString) => {
    let counts1 = [];
    let counts2 = [];
    let quarters = [];
    const res = await getDashboardDatas(queryString);
    if (res.status == true) {
      settotalUser(res.data.totalUser);
      settotalRider(res.data.totalRider);
      settotalOrder(res.data.totalOrder);
      settotalpaymentSuccess(res.data.totalpaymentSuccess);
      settotalpaymentFailed(res.data.totalpaymentFailed);
      settotalOrderTransit(res.data.totalOrderTransit);
      settotalCardpayment(res.data.totalCardpayment);
      setBikeData(res.data.totalBikeData);
      setMiniTruckData(res.data.totalMiniTruckData);
      setBigTruckData(res.data.totalBigTruckData);
      setTowingTruckData(res.data.totalTowingTruckData);
      setriderOrderData(res.data.totalriderOrderData);
      setriderpaymentSuccessData(res.data.totalriderpaymentSuccessData);
      setriderpaymentFailedData(res.data.totalriderpaymentFailedData);
      setriderOrderTransitData(res.data.totalriderOrderTransitData);
      setriderCardpaymentOrderData(res.data.totalriderCardpaymentOrderData);
      setriderBikeData(res.data.totalriderBikeData);
      setriderMiniTruckData(res.data.totalriderMiniTruckData);
      setriderBigTruckData(res.data.totalriderBigTruckData);
      setriderTowingTruckData(res.data.totalriderTowingTruckData);
      setmonthorderCount(res.data.monthorderCount);
      setorderCount(res.data.orderCount);
      setusercount(res.data.usercount);
      setridercount(res.data.ridercount);
      setgettotalUserData(res.data.gettotalUserData);
      setgettotalRiderData(res.data.gettotalRiderData);
      setdelayerOrders(res.data.delayerOrders);
      setcompanyrider(res.data.getcompnayRiderData)

      if (value == 1 || value == 2) {
        setOrderDataChart((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            var date;
            date = moment(res.data.orderGraph[0].Date);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
            });

            res?.data?.orderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              weekDays[currentDate] = Number(value.countOrder);
            });

            labels = weeks.map((value) => value.substring(0, 3));
            console.log("labels", seriesArray);
            let counts = Object.values(weekDays);

            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          } else {
            seriesArray.push({
              name: "Orders",
              data: [0, 0, 0, 0, 0, 0, 0],
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setcompanyRiderOrderChart((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          labels = weeks.map((value) => value.substring(0, 3));
          if (
            res &&
            res.data &&
            res.data.companyriderorderGraph &&
            res.data.companyriderorderGraph.length > 0
          ) {
            var date;
            date = moment(res.data.companyriderorderGraph[0].Date);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
            });

            res?.data?.companyriderorderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              weekDays[currentDate] = Number(value.countcompanyOrder);
            });

            labels = weeks.map((value) => value.substring(0, 3));
            console.log("labels", seriesArray);
            let counts = Object.values(weekDays);

            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          } else {
            seriesArray.push({
              name: "Orders",
              data: [0, 0, 0, 0, 0, 0, 0],
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setcompanyriderData((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          labels = weeks.map((value) => value.substring(0, 3));

          if (
            res &&
            res.data &&
            res.data.riderGraph &&
            res.data.riderGraph.length > 0
          ) {
            var date;
            date = moment(res.data.riderGraph[0].Date);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
            });

            res?.data?.riderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              weekDays[currentDate] = Number(value.countRider);
            });

            labels = weeks.map((value) => value.substring(0, 3));
            console.log("labels", seriesArray);
            counts = Object.values(weekDays);

            seriesArray.push({
              name: "Rider",
              data: counts,
            });
          } else {
            seriesArray.push({
              name: "Rider",
              data: [0, 0, 0, 0, 0, 0, 0],
            });
          }
          console.log("labels", labels);
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setuserandriderData((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts1 = [];
          let counts2 = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          
          labels = weeks.map((value) => value.substring(0, 3));
          if (
            res &&
            res.data &&
            res.data.combinedData &&
            res.data.combinedData.length > 0
          ) {
            var date;
            date = moment(res.data.combinedData[0].Date);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            var weekDay = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
              weekDay[startingWeekDate + index] = 0;
            });

            res?.data?.combinedData?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              console.log("currentDate",currentDate)
              weekDays[currentDate] = value.countRiders;
              weekDay[currentDate] = value.countUser;
            });

            labels = weeks.map((value) => value.substring(0, 3));
            counts1 = Object.values(weekDays);
            counts2 = Object.values(weekDay);

            console.log("object Subscribed", counts1);

            seriesArray.push(
              {
                name: "Rider",
                data: counts1,
              },
              {
                name: "User",
                data: counts2,
              }
            );
          } else {
            seriesArray = [];
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
        
      }

      if (value == 3 || value == 4) {
        setOrderDataChart((previousValues) => {
          var newValues = { ...previousValues };

          var seriesArray = [];
          var labels = [];
          var counts = [];
          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            var dateElement = moment(res.data.orderGraph[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts = new Array(NO_OF_DAYS).fill(0);
            res?.data?.orderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              counts[currentDate - 1] = Number(value.countOrder);
            });

            seriesArray.push({
              name: "Orders",
              data: counts,
            });
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push({
              name: "Orders",
              data: counts1,
            });
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setcompanyRiderOrderChart((previousValues) => {
          var newValues = { ...previousValues };

          var seriesArray = [];
          var labels = [];
          var counts = [];
          if (
            res &&
            res.data &&
            res.data.companyriderorderGraph &&
            res.data.companyriderorderGraph.length > 0
          ) {
            var dateElement = moment(res.data.companyriderorderGraph[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts = new Array(NO_OF_DAYS).fill(0);
            res?.data?.companyriderorderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              counts[currentDate - 1] = Number(value.countcompanyOrder);
            });

            seriesArray.push({
              name: "Orders",
              data: counts,
            });
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push({
              name: "Orders",
              data: counts1,
            });
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setcompanyriderData((previousValues) => {
          var newValues = { ...previousValues };

          var seriesArray = [];
          var labels = [];
          var counts = [];
          if (
            res &&
            res.data &&
            res.data.riderGraph &&
            res.data.riderGraph.length > 0
          ) {
            var dateElement = moment(res.data.riderGraph[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts = new Array(NO_OF_DAYS).fill(0);
            res?.data?.riderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              counts[currentDate - 1] = Number(value.countRider);
            });

            seriesArray.push({
              name: "Rider",
              data: counts,
            });
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push({
              name: "Rider",
              data: counts1,
            });
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index} - ${month}`);
            });
            console.log("labelslabelslabelslabelslabels",labels)
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setuserandriderData((previousValues) => {
          let newValues = { ...previousValues };

          let seriesArray = [];
          let labels = [];
          if (
            res &&
            res.data &&
            res.data.combinedData &&
            res.data.combinedData.length > 0
          ) {
            // if (res.data.length == 0) {

            var dateElement = moment(res.data.combinedData[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            res?.data?.combinedData?.map((value, index) => {
              const currentDate = moment(value.months).date();
              counts1[currentDate - 1] = Number(value.countRiders);
              counts2[currentDate - 1] = Number(value.countUser);

              console.log("subscribedsubscribedsubscribedsubscribed", counts1);
              console.log("freefreefreefreefreefreefreefreefreefree", counts2);
            });

            seriesArray.push(
              {
                name: "Rider",
                data: counts1,
              },
              {
                name: "User",
                data: counts2,
              }
            );
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
            console.log("lables", labels);
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push(
              {
                name: "Rider",
                data: counts1,
              },
              {
                name: "User",
                data: counts2,
              }
            );
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
            console.log("lables", labels);
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
      }

      if (value == 5 || value == 6) {
        let labels = [];
        let seriesArray = [];
        let counts3 = [];
        let labels2 = [];
        let quarters = {
          1: {
            January: 0,
            February: 0,
            March: 0,
          },
          2: {
            April: 0,
            May: 0,
            June: 0,
          },
          3: { July: 0, August: 0, September: 0 },
          4: {
            October: 0,
            November: 0,
            December: 0,
          },
        };

        setOrderDataChart((previousValues) => {
          let newValues = { ...previousValues };
          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];

          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            var month = res.data.orderGraph[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.orderGraph?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.countOrder;
            });

            labels2 = Object.keys(quarters[quarterNo]);
            counts3 = Object.values(quarters[quarterNo]);
            seriesArray.push({
              name: "Orders",
              data: counts3,
            });
          } else {
            labels2 = Object.keys(quarters[3]);
            counts3 = Object.values(quarters[3]);
            seriesArray.push({
              name: "Orders",
              data: counts3,
            });
          }

          newValues.series = seriesArray;
          newValues.options.labels = labels2;
          return newValues;
        });

        setcompanyRiderOrderChart((previousValues) => {
          let newValues = { ...previousValues };
          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];
          let quarters = {
            1: {
              January: 0,
              February: 0,
              March: 0,
            },
            2: {
              April: 0,
              May: 0,
              June: 0,
            },
            3: { July: 0, August: 0, September: 0 },
            4: {
              October: 0,
              November: 0,
              December: 0,
            },
          };

          if (
            res &&
            res.data &&
            res.data.companyriderorderGraph &&
            res.data.companyriderorderGraph.length > 0
          ) {
            var month = res.data.companyriderorderGraph[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.companyriderorderGraph?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.countcompanyOrder;
            });

            labels2 = Object.keys(quarters[quarterNo]);
            counts3 = Object.values(quarters[quarterNo]);
            seriesArray.push({
              name: "Orders",
              data: counts3,
            });
          } else {
            labels2 = Object.keys(quarters[3]);
            counts3 = Object.values(quarters[3]);
            seriesArray.push({
              name: "Orders",
              data: counts3,
            });
          }

          newValues.series = seriesArray;
          newValues.options.labels = labels2;
          return newValues;
        });


        setcompanyriderData((previousValues) => {
          let newValues = { ...previousValues };
          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];
           let quarters = {
          1: {
            January: 0,
            February: 0,
            March: 0,
          },
          2: {
            April: 0,
            May: 0,
            June: 0,
          },
          3: { July: 0, August: 0, September: 0 },
          4: {
            October: 0,
            November: 0,
            December: 0,
          },
        };

          if (
            res &&
            res.data &&
            res.data.riderGraph &&
            res.data.riderGraph.length > 0
          ) {
            var month = res.data.riderGraph[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.riderGraph?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.countRider;
              
            });

            labels2 = Object.keys(quarters[quarterNo]);
            counts3 = Object.values(quarters[quarterNo]);
            console.log("abcdadafsdtsadftadtsafdfsafdtsa",quarters[quarterNo])
            seriesArray.push({
              name: "Rider",
              data: counts3,
            });
          } else {
            labels2 = Object.keys(quarters[3]);
            console.log("xyz",labels2)
            counts3 = [];
            console.log("abc",counts3)
            seriesArray.push({
              name: "Rider",
              data: counts3,
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels2;
          return newValues;
        });

        setuserandriderData((previousValues) => {
          let newValues = { ...previousValues };

          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];
          let quarters1 = {
            1: {
              January: 0,
              February: 0,
              March: 0,
            },
            2: {
              April: 0,
              May: 0,
              June: 0,
            },
            3: { July: 0, August: 0, September: 0 },
            4: {
              October: 0,
              November: 0,
              December: 0,
            },
          };
          let quarters2 = {
            1: {
              January: 0,
              February: 0,
              March: 0,
            },
            2: {
              April: 0,
              May: 0,
              June: 0,
            },
            3: { July: 0, August: 0, September: 0 },
            4: {
              October: 0,
              November: 0,
              December: 0,
            },
          };

          if (
            res &&
            res.data &&
            res.data.combinedData &&
            res.data.combinedData.length > 0
          ) {
            var month = res.data.combinedData[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.combinedData?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.countRiders;
              quarters1[quarterNo][value.Date] = value.countUser;
            });

            labels = Object.keys(quarters[quarterNo]);
            counts1 = Object.values(quarters[quarterNo]);
            counts2 = Object.values(quarters1[quarterNo]);
            console.log("counts1", counts1, "counts2", counts2);
            seriesArray.push(
              {
                name: "Rider",
                data: counts1,
              },
              {
                name: "User",
                data: counts2,
              }
            );
          } else {
            labels = Object.keys(quarters[1]);
            counts1 = Object.values(quarters[1]);
            counts2 = Object.values(quarters1[1]);
            seriesArray.push(
              {
                name: "Rider",
                data: counts1,
              },
              {
                name: "User",
                data: counts2,
              }
            );
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
      }

      if (value == 7 || value == 8) {
        var months1 = {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
        };
        var months2 = {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
        };
        let labels = [];
        let seriesArray = [];

        setOrderDataChart((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let months = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          let counts = [];
          let labels = [];

          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            res?.data?.orderGraph?.map((value, index) => {
              months[value.Date] = value.countOrder;
            });
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          } else {
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          }
          console.log("seriesArray", seriesArray);
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setcompanyRiderOrderChart((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let months = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          let counts = [];
          let labels = [];

          if (
            res &&
            res.data &&
            res.data.companyriderorderGraph &&
            res.data.companyriderorderGraph.length > 0
          ) {
            res?.data?.companyriderorderGraph?.map((value, index) => {
              months[value.Date] = value.countcompanyOrder;
            });
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          } else {
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          }
          console.log("seriesArray", seriesArray);
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setcompanyriderData((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let months = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          let counts = [];
          let labels = [];

          if (
            res &&
            res.data &&
            res.data.riderGraph &&
            res.data.riderGraph.length > 0
          ) {
            res?.data?.riderGraph?.map((value, index) => {
              months[value.Date] = value.countRider;
            });
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Rider",
              data: counts,
            });
          } else {
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Rider",
              data: counts,
            });
          }
         
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        
        setuserandriderData((previousValues) => {
          var newValues = { ...previousValues };
          let seriesArray = [];

          if (
            res &&
            res.data &&
            res.data.combinedData &&
            res.data.combinedData.length > 0
          ) {
            res?.data?.combinedData?.map((value, index) => {
              months1[value.Date] = value.countRiders;
              months2[value.Date] = value.countUser;
            });
            labels = Object.keys(months1);
            counts1 = Object.values(months1);
            counts2 = Object.values(months2);
            seriesArray.push(
              {
                name: "Rider",
                data: counts1,
              },
              {
                name: "User",
                data: counts2,
              }
            );
          } else {
            labels = Object.keys(months1);
            counts1 = Object.values(months1);
            counts2 = Object.values(months2);
            seriesArray = [];
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
      }
    }
  };

  useEffect(() => {
    getDashboardData(3, queryString);
    setMenus(3);
  }, []);

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={6} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Dashboard
              </Typography>
            </Grid>
            <Grid item sm={6} sx={{display:'flex',justifyContent:'flex-end'}}>
              <Select
                name="graphFilter"
                style={{
                  // width: "100%",
                  borderRadius: "10px",
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  ".MuiInputLabel-root": {
                    color: "black",
                  },
                  ".MuiSelect-select": {
                    color: "black",
                  },

                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#fc9494",
                    },
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                }}
                id="demo-select-small"
                value={Number(menu)}
                onChange={(e, value) => {
                  handleMenuChange(e, value);
                }}
              >
                {filterList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: "15px",
            marginLeft: { xs: "15px", md: "25px", lg: "25px" },
            marginRight: { xs: "15px", md: "25px", lg: "25px" },
          }}
        >
          <Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {Role.role == 1 ? (
                  <Box
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "20px",
                        md: "20px",
                        lg: "0px",
                      },
                      marginBottom: "20px",
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalOrder || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Payment Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalpaymentSuccess || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Failed Payment Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalpaymentFailed || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                   ) : null}
                   {Role.role == 1 ? (
                  <Box
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "20px",
                        md: "20px",
                        lg: "0px",
                      },
                      marginBottom: "20px",
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Card Payments
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalCardpayment || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Orders In Transit
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalOrderTransit || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Active / Total Rider
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalRider || "0"} / {gettotalRiderData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                 ) : null}
                  {Role.role == 1 ? (
                  <Box
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "20px",
                        md: "20px",
                        lg: "0px",
                      },
                      marginBottom: "20px",
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      {Role.role == 1 ? (
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Active / Total User
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalUser   || "0"} / {gettotalUserData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      ) : null}
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Bike Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalBikeData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Mini Truck Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalMiniTruckData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Big Truck Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalBigTruckData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Towing Truck Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalTowingTruckData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      {Role.role == 2 ? (
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Compnay Rider
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalcompanyrider || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                   ) : null}
                  {/* rider data */}

                  {Role.role == 2 ? (
                  <Box
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "20px",
                        md: "20px",
                        lg: "0px",
                      },
                      marginBottom: "20px",
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderOrderData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Payment Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderpaymentSuccessData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Failed Payment Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderpaymentFailedData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                  ) : null}


                    {Role.role == 2 ? (
                  <Box
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "20px",
                        md: "20px",
                        lg: "0px",
                      },
                      marginBottom: "20px",
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Card Payments
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderCardpaymentOrderData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Orders In Transit
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderOrderTransitData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Active / Total Rider
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalcompanyrider || "0"} / {totalcompanyrider || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                  ) : null}

                  {Role.role == 2 ? (
                  <Box
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "20px",
                        md: "20px",
                        lg: "0px",
                      },
                      marginBottom: "20px",
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Bike Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderBikeData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Mini Truck Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderMiniTruckData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Big Truck Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderBigTruckData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, color: "black" }}
                              gutterBottom
                            >
                              Total Towing Truck Orders
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 18,
                                mb: 1.5,
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              {totalriderTowingTruckData || "0"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                  ) : null}

                </Grid>
              </Grid>
            </Box>
          </Box>
        
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        marginTop: {
                          xs: "0px",
                          sm: "20px",
                          md: "20px",
                          lg: "0px",
                        },
                        marginBottom: "20px",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {Role.role == 1 ? (
                          <Grid item xs={6}>
                            <Card>
                              <CardContent style={{ height: "400px" }}>
                                <Typography
                                  sx={{ fontSize: 16, color: "black" }}
                                  gutterBottom
                                >
                                  User & Rider
                                </Typography>

                                <div className="card graph-container">
                                
                                  <Chart
                                  options={{
                                    ...userandriderData.options,
                                    colors: ["#111827", "#faa59a"],
                                    theme: { mode: "light", foreColor: "white" },
                                    yaxis: {
                                      labels: {
                                        formatter: (value) => {
                                          return Number(value);
                                        },
                                      },
                                    },
                                  }}
                                  series={userandriderData.series}
                                  type="bar"
                                  height="360"
                                />
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        ) : null}

                        {Role.role == 1 ? (
                        <Grid item xs={6}>
                          <Card>
                            <CardContent style={{ height: "400px" }}>
                              <Typography
                                sx={{ fontSize: 16, color: "black" }}
                                gutterBottom
                              >
                                Orders
                              </Typography>

                              <div className="card graph-container">
                                <Chart
                                  options={{
                                    ...orderDataChart.options,
                                    theme: { mode: "light" },
                                  }}
                                  series={orderDataChart.series}
                                  type="bar"
                                  height="360"
                                />
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                        ) : null}

                        {Role.role == 2 ? (
                          <Grid item xs={6}>
                          <Card>
                            <CardContent style={{ height: "400px" }}>
                              <Typography
                                sx={{ fontSize: 16, color: "black" }}
                                gutterBottom
                              >
                              Company Rider
                              </Typography>

                              <div className="card graph-container">
                              <Chart
                                options={{
                                  ...companyriderData.options,
                                  theme: { mode: "light" },
                                }}
                                series={companyriderData.series}
                                type="bar"
                                height="360"
                              />
                              </div>
                            </CardContent>
                          </Card>
                          </Grid>
                        ) : null}

                        {Role.role == 2 ? (
                          <Grid item xs={6}>
                            <Card>
                              <CardContent style={{ height: "400px" }}>
                                <Typography
                                  sx={{ fontSize: 16, color: "black" }}
                                  gutterBottom
                                >
                                  Orders
                                </Typography>

                                <div className="card graph-container">
                                  <Chart
                                    options={{
                                      ...companyRiderOrderChart.options,
                                      theme: { mode: "light" },
                                    }}
                                    series={companyRiderOrderChart.series}
                                    type="bar"
                                    height="360"
                                  />
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
        
          
          {Role.role == 1 ? (
          <Paper
            sx={{
              marginTop: { xs: "20px", sm: "15px", md: "15px", lg: "25px" },
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "20px",
                  m: 1,
                  padding: "12px",
                  fontWeight: "bold",
                }}
              >
                Delayer Orders
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: {
                  lg: "1250px",
                },
                marginTop: "-27px",
              }}
            >
              <Box
                sx={{
                  marginTop: "40px",
                  marginBottom: "20px",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                >
                  <Table
                    aria-label="simple table"
                    sx={{ paddingLeft: "0!important", minWidth: 1140 }}
                  >
                    <TableHead sx={{ paddingLeft: "0!important" }}>
                      <TableRow
                        sx={{
                          backgroundColor: "#f6f9fb",
                          paddingLeft: "0!important",
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Order Number
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          User Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Rider Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Start Location
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          End Location
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Payble Cost
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Schedule Time
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Approx Minutes
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Delayed Minutes
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          orderStatus
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Payment Mode
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          payment Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "#fff",
                        paddingLeft: "0!important",
                      }}
                    >
                      {delayerOrders?.map((row) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.orderNumber || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.UserName || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.RiderName || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.startLocation || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.endLocation || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.paybleCost || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {moment(row.scheduleTime).format("MM-DD-YYYY LT")}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.approxMinutes || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.time_difference_minutes || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.orderStatusName || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.paymentmodename || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {row?.paymentStatusName || "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {delayerOrders && delayerOrders.length === 0 ? (
                    <div
                      className="noDataFound"
                      style={{
                        display: "flex",
                      }}
                    >
                      {" "}
                      No Data Found
                    </div>
                  ) : null}
                </TableContainer>
              </Box>
            </Box>
          </Paper>
           ) : null}
        </Box>
      </Box>
    </>
  );
}
