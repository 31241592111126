import axiosRequest from "../axios/axios";

//get TermsCondition List
export const getTermsConditionList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`termsConditionList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add TermsCondition

export const addTermsConditions = async (payload) => {
  try {
    const response = await axiosRequest.post(`termsConditionAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//TermsCondition Delete
export const DeleteTermsCondition = async (id) => {
  try {
    const response = await axiosRequest.delete(`termsConditionDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//TermsCondition Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`termsConditionStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//TermsCondition update

export const updateTermsCondition = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `termsConditionUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
