import * as React from "react";
import { Box, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { InputLabel } from "@mui/material";
import Menu from "@mui/material/Menu";
export default function SelectBox(props) {
  const {
    name,
    value,
    onChange,
    maxRows = 0,
    options,
    disabled,
    ClassName,
    error,
    label,
    style,
    ...other
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ minWidth: 160, marginTop: "7px" }}>
      <FormControl fullWidth size="small" {...(error && { error: true })}>
        <InputLabel
          sx={{
            "&.MuiInputLabel-root": {
              color: "rgb(92 91 91 / 100%)",
              fontSize: "14px",
              fontWeight: "500",
            },

            "&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
              borderRadius: "7px",
              paddingTop: "15px",
              paddingBottom: "13px",
              borderColor: "blue",
            },
            "&.MuiOutlinedInput-root.Mui-focused": {
              borderColor: "red",
            },
            "&.MuiInputLabel-root.Mui-error": {
              color: "#fc9494!important",
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          name={name}
          sx={{
            paddingTop: "12px",
            paddingBottom: "5px",
            borderRadius: "5px",
            color: "black",
            borderColor: "#fff",

            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(107, 114, 128, .5)",
            },
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(107, 114, 128)",
              },
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "blue",
              },
            },
            "&.MuiOutlinedInput-root.Mui-error": {
              "& fieldset": {
                borderColor: "#fc9494!important",
              },
              "&:hover fieldset": {
                borderColor: "#fc9494!important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#fc9494!important",
              },
            },
            ".css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                paddingTop: "0!important",
              },
            ".MuiInputLabel-root": {
              color: "rgb(92 91 91 / 70%)",
            },
            ".MuiSelect-select": {
              color: "rgb(92 91 91 / 100%)",
            },
            "&.Mui-focused .MuiOutlinedInput-root": {
              borderColor: "red",
            },
            ".MuiOutlinedInput-notchedOutline.Mui-focused": {
              borderColor: "red",
            },
            "& label.Mui-focused": {
              color: "#4f46e5!important",
            },
            "& .MuiPaper-root-MuiMenu-paper-MuiPopover-paper": {
              backgroundColor: "white !important",
            },
            ".MuiSvgIcon-root ": {
              fill: "rgb(92 91 91 / 70%) !important",
            },
          }}
          id="demo-select-small"
          value={value}
          label={label}
          disabled={disabled}
          onChange={onChange}
          {...other}
          {...(error && { error: true, helperText: error })}
        >
          <MenuItem value="" sx={{ color: "#000!important" }}>
            None
          </MenuItem>
          {options.map((item) => {
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                sx={{ color: "#000!important" }}
              >
                {item.name ||
                  item.title ||
                  item.parameterTypeName ||
                  item.coupenCode}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
