import { useEffect, useState } from "react";

import { Grid, Box, Typography, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  addTermsConditions,
  updateTermsCondition,
} from "../../state/action/termsAction";

const initialValues = {
  userType: "",
  description: "",
  title: "",
};

const AddPrivacyPolicy = () => {
  const TypeLists = [
    { id: 1, title: "User" },
    { id: 2, title: "Rider" },
  ];

  const navigate = useNavigate();
  const { state } = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const _getInitialHTML = (htmlString) => {
    return convertFromHTML(htmlString);
  };
  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";

    if ("userType" in fieldValues)
      temp.userType = fieldValues.userType ? "" : "This field is required.";

   

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

    

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const htmlContent = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      const body = {
        userType: values.userType,
        description: htmlContent,
        title: values.title,
      };

      if (isEdit) {
        const res = await updateTermsCondition(state.id, body);
        if (res && res.status === true) {
          navigate("/terms-condition");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        if(htmlContent != "<p></p>\n"){
          const res = await addTermsConditions(body);
          if (res && res.status === true) {
            navigate("/terms-condition");
            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        }else{
          enqueueSnackbar("Please add description", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (state) {
      setIsEdit(true);
      setValues({
        ...values,
        description: "",
        title: state.title,
        userType: state?.type,
      });
      if (state?.description) {
        const { contentBlocks, entityMap } = _getInitialHTML(
          state?.description
        );
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlocks, entityMap)
          )
        );
      }
    } else {
      setIsEdit(false);
      resetForm();
    }
  }, [state]);

  return (
    <Box>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/terms-condition");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Terms & Condition
          </Typography>
          <div>
            <Controls.Button
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                marginRight: "10px",
              }}
              text="Cancel"
              onClick={() => {
                navigate("/terms-condition");
              }}
            />
            <Controls.Button
              style={{ marginRight: "7px" }}
              text="Submit"
              onClick={(e) => handleSubmit(e)}
              type="submit"
            />
          </div>
        </Box>
      </Box>
      <Form onSubmit={handleSubmit}>
        <Box
          className="b1"
          sx={{
            backgroundColor: "white",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Controls.SelectBox
                className="input"
                fullWidth
                id="userType"
                name="userType"
                value={values.userType}
                onChange={handleInputChange}
                options={TypeLists}
                label="UserType"
              />
              {Boolean(errors.userType) ? (
                <p
                  style={{
                    color: "rgb(255 0 0)",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.userType}
                </p>
              ) : (
                ""
              )}
            </Grid>{" "}
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Controls.Input
                text="Add New"
                variant="outlined"
                color="primary"
                label="Title"
                name="title"
                value={values.title}
                onChange={handleInputChange}
                error={errors.title}
                helperText={errors.title}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ m: 1, height: "40%" }} md={5.5} xs={12}>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbarClassName="toolbar-class"
              onEditorStateChange={onEditorStateChange}
              error={errors.description}
              helperText={errors.description}
            />
          </Grid>
        </Box>
      </Form>
    </Box>
  );
};

export default AddPrivacyPolicy;
