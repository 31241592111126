import {
  Box,
  Button,
  Chip,
  FormLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router";
import { Space } from "antd";
import { GrView } from "react-icons/gr";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignOrder from "./AssignOrder";
import moment from "moment";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
   
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};
const RecordPerPage = [
  { id: "10", title: "10" },
  { id: "20", title: "20" },
];

const OrderRequestList = (props) => {
  const {
    data,
    dataShorting,
    flagName,
    flagNumber,
    totalPages,
    page,
    totalRecords,
    pageLimit,
    handleChangeRow,
    handleChangePage,
  } = props;
  const navigate = useNavigate();

  //orderstatus
  const orderStatus = (orderStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Order Pickup Schedule",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: " Pickedup Package",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      3: {
        key: " Packagein Transit",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      4: {
        key: "Delivered",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      5: {
        key: "Cancelled",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[orderStatus]}
        label={statusColors[orderStatus] ? statusColors[orderStatus].key : ""}
      />
    );
  };

  //payment status
  const paymentStatus = (paymentStatus) => {
    const statusColors = {
      1: {
        key: "Success",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Failed",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
      3: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[paymentStatus]}
        label={
          statusColors[paymentStatus] ? statusColors[paymentStatus].key : ""
        }
      />
    );
  };

  //assign order
  const [assignUser, setAssignUser] = useState(false);
  const openUserModal = () => {
    setAssignUser(true);
  };
  const closeUserModal = () => {
    setAssignUser(false);
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          overflow: "scroll",
        }}
      >
        <TableContainer component={Paper}>
          <Table
            aria-label="customized table"
            sx={{ width: { md: "160%", lg: "140%", xl: "100%" } }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Id
                  </Button>
                </StyledTableCell>
                <StyledTableCell onClick={() => dataShorting("orderNumber")}>
                  Order Number
                  {flagNumber ? (
                    <ArrowUpwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                      }}
                    />
                  )}
                </StyledTableCell>

                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                    onClick={() => dataShorting("userName")}
                  >
                    Customer Name
                    {flagName ? (
                      <ArrowUpwardIcon
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                        }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                        }}
                      />
                    )}
                  </Button>
                </StyledTableCell>

                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Vehicle Type
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Pick up Location
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Drop Location
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Amount
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Order Status
                  </Button>
                </StyledTableCell>

                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Payment Status
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                <Button
                  variant="text"
                  sx={{
                    ":hover": { bgcolor: "transparent" },
                    paddingLeft: "0",
                    color: "#000",
                    fontSize: "13px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  Payment Type
                </Button>
              </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Date
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      justifyContent: "left",
                    }}
                  >
                    Action
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                return (
                  <StyledTableRow
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/orderReqDetails", {
                        state: row.id,
                      });
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.id || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.orderNumber || "-"}
                    </StyledTableCell>
                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/userdetails", {
                            state: {
                              user: row?.userID,
                            },
                          });
                        }}
                      >
                        {row?.userName || "-"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>{row?.vehicleName || "-"}</StyledTableCell>
                    <StyledTableCell>
                      {row?.startLocation || "-"}
                    </StyledTableCell>
                    <StyledTableCell>{row?.endLocation || "-"}</StyledTableCell>
                    <StyledTableCell>{row?.paybleCost || "-"}</StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {orderStatus(row?.orderStatus) || "-"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {paymentStatus(row?.paymentStatus) || "-"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.paymentmodename || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {moment(row.createdAt).format("MM-DD-YYYY LT")}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                      <Space size="middle">
                        <Tooltip placement="bottom" title="View Order">
                          <button
                            className="action-button view"
                            onClick={() => {
                              navigate("/orderReqDetails", {
                                state: row.id,
                              });
                            }}
                          >
                            {" "}
                            <GrView />{" "}
                          </button>
                        </Tooltip>
                      </Space>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          {data && data.length === 0 ? (
            <div
              className="noDataFound"
              style={{
                display: "flex",
              }}
            >
              {" "}
              No Data Found
            </div>
          ) : null}
        </TableContainer>
        <AssignOrder
          open={assignUser}
          setOpen={setAssignUser}
          handleClickOpen={openUserModal}
          handleClose={closeUserModal}
        />

        <Box
          sx={{
            display: {
              xs: "block",
              sm: "flex",
              md: "flex",
              lg: "flex",
            },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: {
              xs: "10px",
              sm: "20px",
              md: "20px",
              lg: "20px",
            },
            marginRight: {
              xs: "10px",
              sm: "15px",
              md: "15px",
              lg: "15px",
            },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {" "}
              Showing{" "}
              {page * pageLimit > totalRecords
                ? totalRecords
                : page * pageLimit}{" "}
              of {totalRecords} Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>
              <Select
                className="recordLimit"
                sx={{
                  color: "#000",
                  height: "40px",
                  fontSize: "15px",
                  borderRadius: "5px",
                  border: 1,
                  paddingLeft: "10px",
                  borderColor: "#000",
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="limites"
                name="limites"
                value={pageLimit}
                onChange={(e) => {
                  handleChangeRow(e.target.value);
                }}
              >
                {RecordPerPage.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default OrderRequestList;
