import { Box } from "@mui/system";
import React from "react";
import CountryList from "../Location/countryList";

const Country = () => {
  return (
    <Box>
      <CountryList />
    </Box>
  );
};

export default Country;