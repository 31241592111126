import React, { useState, useEffect } from "react";

import { TextField } from "@mui/material";

import { styled } from "@mui/material/styles";

//add date picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//date color change to white

const WhiteBorderDate = styled(DesktopDatePicker)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & .MuiInputLabel-root.Mui-error {
    color: white;
  }
  & label.Mui-focused {
    color: white;
    border-color: white;
  }
  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
  }
  & .MuiSvgIcon-root {
    color: white;
  }
`;

const Date = (props) => {
  const {
    name,
    value,
    onChange,
    maxRows = 1,
    error = null,
    label,
    style,
    ...other
  } = props;

  const [ClassName, setClassName] = useState("");

  useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <WhiteBorderDate
        label={label}
        value={value}
        onChange={onChange || ""}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default Date;
