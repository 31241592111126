import axiosRequest from "../axios/axios";

//get User List
export const getUserList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`getusers/${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//user Delete
export const DeleteUser = async (id) => {
  try {
    const response = await axiosRequest.delete(`UserDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//user Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`UserStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//user update

export const updateUser = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`UserUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//user details

export const getUserDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/UserView/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//edit Address

export const updateAddress = async (id, ids, payload) => {
  try {
    const response = await axiosRequest.put(
      `UserAddressUpdate/${id}/${ids}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//delete address
export const DeleteAddress = async (id, ids) => {
  try {
    const response = await axiosRequest.delete(
      `UserAddressDelete/${id}/${ids}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const AddressDefault = async (id, ids) => {
  try {
    const response = await axiosRequest.put(`UserAddressDefault/${id}/${ids}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
