import axiosRequest from "../axios/axios";

//get State List
export const getStateList = async () => {
  try {
    const response = await axiosRequest.get(`/stateDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// getDdbStateList

export const getDdbStateList = async (id) => {
  try {
    const response = await axiosRequest.get(`/statesall/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const allDumppedCities = async (id) => {
  try {
    const response = await axiosRequest.get(`/allcities/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDummpedStateList = async (id) => {
  try {
    const response = await axiosRequest.get(`/allstates/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDummpedCityList = async (id) => {
  try {
    const response = await axiosRequest.get(`/allcities/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get City List

export const getCityLists = async (queryString, id) => {
  try {
    const response = await axiosRequest.get(`/cityList/${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get State List
export const getStateUserList = async () => {
  try {
    const response = await axiosRequest.get(`/userStateDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};



// State and city API

//Add State

export const addState = async (payload) => {
  try {
    const response = await axiosRequest.post(`/addState`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateCity = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(
      `/updateCity/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// State List
export const getStateLocationList = async (queryString, id) => {
  try {
    const response = await axiosRequest.get(`/stateList/${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// State Dropdown List

export const getStateDropdownList = async (id) => {
  try {
    const response = await axiosRequest.get(`/stateDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// State Status Chnage

//transport status change
export const stateLocationStatus = async (id, status) => {
  try {
    const response = await axiosRequest.patch(
      `/stateStatusChange/${id}`,
      status
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Update state

export const updateStateLocation = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(
      `/updateState/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Delete State

export const deleteState = async (id) => {
  try {
    const response = await axiosRequest.patch(`/deleteState/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// City list by state

export const getCityLocationList = async (id, queryString) => {

  try {
    const response = await axiosRequest.get(`/citylist/${id}${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add City by state Id

export const addCityByState = async (payload) => {
  try {
    const response = await axiosRequest.post(`/addCity`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//transport City status change
export const cityLocationStatus = async (id) => {
  try {
    const response = await axiosRequest.patch(
      `/cityStatus/${id}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Update state

export const updateCityLocation = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/updateCity/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Delete City

export const deleteCity = async (id) => {
  try {
    const response = await axiosRequest.patch(`/deleteCity/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};