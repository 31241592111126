import React, { useEffect, useState } from "react";
import { Avatar, Chip, MenuItem, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { InputBase } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Grid,
  Tooltip,
  TextField,
  Pagination,
} from "@mui/material";
import { Drawer, FormLabel } from "@mui/material";
import { useProSidebar } from "react-pro-sidebar";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { RiDeleteBinLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { useLocation, useNavigate } from "react-router";
import {
  DeleteUser,
  StatusUpdate,
  getUserList,
} from "../../state/action/userAction";
import { useForm } from "../../Utils/useForm";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";

const initialValues = {
  name: "",
  email: "",
  mobile: "",
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },

  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

export default function BasicTabs() {
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [status, setStatus] = useState();
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [id, setId] = useState("");
  const [checked, setChecked] = useState(true);
  const [flagName, setflagName] = useState(false);
  const [flagEmail, setflagEmail] = useState(false);
  const [flagPhone, setflagPhone] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { collapsed, broken } = useProSidebar();
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const { state } = useLocation();

  const RecordPerPage = [
    { id: "10", title: "10" },
    { id: "20", title: "20" },
  ];

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const userLists = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      getuserList(value, fieldName, flagName);
    } else {
      getuserList(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "name") {
      getuserList(page, fieldName, flagName, event);
    } else {
      getuserList(page, "", "", event);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();

  //User List
  useEffect(() => {
    getuserList(page, "", "");
  }, [refresh, filterData, search]);

  const getuserList = async (page, name = "", flag, limit) => {
    const body = {
      search: search,
    };
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;
    let queryString = `?page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${flag}`;

    const res = await getUserList(queryString, body);
    if (res.status === true) {
      setUserList(res.data);
      setTotalRecords(res.totalUsers);
      setPageLimit(recordLimit);
      setTotalPages(Math.ceil(res.totalUsers / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setUserList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  //status Change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        getuserList(page, fieldName, flagName);
      } else {
        getuserList(page, "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //delete
  const handleDelete = async () => {
    const res = await DeleteUser(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getuserList(page, fieldName, flagName);
      } else {
        getuserList(page, "");
      }
      handleCloseDelete();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //sorting
  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      getuserList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "email") {
      setflagEmail(!flagEmail);
      getuserList(page, name, !flagEmail);
    } else {
      setflagEmail(false);
      setSortFlag(!sortFlag);
    }
    if (name === "phoneNumber") {
      setflagPhone(!flagPhone);
      getuserList(page, name, !flagPhone);
    } else {
      setflagPhone(false);
      setSortFlag(!sortFlag);
    }
    setFieldName(name);
  };

  //isVerify document
  const IsVerify = (IsVerify) => {
    const statusColors = {
      0: {
        key: "No",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Yes",
        backgroundColor: "green",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[IsVerify]}
        label={statusColors[IsVerify] ? statusColors[IsVerify].key : ""}
      />
    );
  };

  //pro status
  const proStatus = (proStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "green",
      },
      0: {
        key: "No",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[proStatus]}
        label={statusColors[proStatus] ? statusColors[proStatus].key : ""}
      />
    );
  };

  return (
    <>
      <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Users
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            width: "98%",
            backgroundColor: "rgb(241, 245, 249)",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
              maxHeight: "485px",
              overflow: "scroll",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                aria-label="customized table"
                sx={{ width: { md: "100%", lg: "145%", xl: "100%" } }}
              >
                <TableHead>
                  <TableRow hover>
                    <StyledTableCell>Profile</StyledTableCell>
                    <StyledTableCell onClick={() => dataShorting("name")}>
                      Name
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>Company ID</StyledTableCell>
                    <StyledTableCell>Is Pro</StyledTableCell>
                    <StyledTableCell onClick={() => dataShorting("email")}>
                      Email
                      {flagEmail ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>Mobile</StyledTableCell>
                    <StyledTableCell>Referal Code</StyledTableCell>
                    <StyledTableCell>Is Verified</StyledTableCell>
                    <StyledTableCell>DOJ</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          minWidth: "0",
                        }}
                      >
                        Status
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList?.map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/userdetails", {
                            state: {
                              user: row?.id,
                            },
                          });
                        }}
                      >
                        <StyledTableCell>
                          <Avatar
                            component="span"
                            variant="rounded"
                            alt="profile_image"
                            src={row?.profilePic}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{row.name || "-"}</StyledTableCell>
                        <StyledTableCell>{row.companyID || "0"}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {proStatus(row?.isPro) || "-"}
                        </StyledTableCell>
                        <StyledTableCell>{row.email || "-"}</StyledTableCell>
                        <StyledTableCell>
                          {row.phoneNumber || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.referalCode || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {IsVerify(row.isMobileVerifiyed) || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.createdAt || "-"}
                        </StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                          <Controls.Switch
                            checked={row.isActive == 1 ? true : false}
                            onChange={(e) => {
                              handleSwitchModel();
                              setStatus(
                                e.target.checked == true ? "Active" : "InActive"
                              );
                              setSwitchId(row.id);
                              setChecked(e.target.checked);
                            }}
                            name="checkedB"
                            color="primary"
                            sx={{ marginLeft: "40px", px: 0 }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          onClick={(e) => e.stopPropagation()}
                          sx={{ paddingLeft: "20px !important" }}
                        >
                          <Space size="middle">
                            <Tooltip placement="bottom" title="View User">
                              <button
                                className="action-button view"
                                onClick={() => {
                                  navigate("/userdetails", {
                                    state: {
                                      user: row?.id,
                                    },
                                  });
                                }}
                              >
                                <GrView />
                              </button>
                            </Tooltip>
                            <Tooltip placement="bottom" title="Delete User">
                              <button
                                className="action-button delete-btn"
                                onClick={() => {
                                  setId(row.id);
                                  handleDeleteModel();
                                }}
                              >
                                <RiDeleteBinLine />
                              </button>
                            </Tooltip>
                          </Space>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {userList && userList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
              marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
              mt: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                fontSize: "14px",
                marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                gap: 2,
                width: { sm: "100%", md: "50%" },
              }}
              display="flex"
              alignItems="center"
            >
              <Box>
                {" "}
                Showing{" "}
                {page * pageLimit > totalRecords
                  ? totalRecords
                  : page * pageLimit}{" "}
                of {totalRecords} Results
              </Box>
              <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                <Typography>Records per page</Typography>
                <Select
                  className="recordLimit"
                  sx={{
                    color: "#000",
                    height: "40px",
                    fontSize: "15px",
                    borderRadius: "5px",
                    border: 1,
                    paddingLeft: "10px",
                    borderColor: "#000",
                    ".MuiSvgIcon-root ": {
                      fill: "black !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  id="limites"
                  name="limites"
                  value={pageLimit}
                  onChange={(e) => {
                    handleChangeRow(e.target.value);
                  }}
                >
                  {RecordPerPage.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiButtonBase-root ": {
                  color: "#000 !important",
                },
                "& .Mui-selected ": {
                  background: "#4b4b4e70 !important",
                },
                "& .Mui-disabled ": {
                  background: "#6c696966 !important",
                  color: "#000000 !important",
                  opacity: 1,
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Status Change
          </Typography>
          <label className="fontLight">
            Are you sure change status to {status} ?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: { xs: "10px", sm: "0px" },
                }}
                text="Cancel"
                onClick={handleCloseSwitch}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Change"
                onClick={() => handleChangeStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Delete Record
          </Typography>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: { xs: "10px", sm: "0px" },
                }}
                text="Cancel"
                onClick={handleCloseDelete}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Delete"
                onClick={() => handleDelete(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
              padding: "10px 20px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
              >
                Filter
              </Typography>

              <AiOutlineCloseCircle
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>

            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              <StyledFormLabel htmlFor="name">User Name</StyledFormLabel>
              <WhiteBorderTextField
                className="input"
                fullWidth
                id="name"
                name="name"
                value={values.name}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />
              <StyledFormLabel htmlFor="name">Email</StyledFormLabel>
              <WhiteBorderTextField
                className="input"
                fullWidth
                id="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />

              <StyledFormLabel htmlFor="name">Mobile</StyledFormLabel>
              <WhiteBorderTextField
                className="input"
                fullWidth
                id="mobile"
                name="mobile"
                value={values.mobile}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />

              <StyledFormLabel htmlFor="name">Referal Code</StyledFormLabel>
              <WhiteBorderTextField
                className="input"
                fullWidth
                id="referalCode"
                name="referalCode"
                value={values.referalCode}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />
            </Box>

            <Box>
              <StyledFormLabel htmlFor="hsnCode">Status</StyledFormLabel>
              <Controls.SelectBox
                className="input"
                fullWidth
                id="isActive"
                name="isActive"
                value={values.isActive}
                onChange={handleInputChange}
                options={userLists}
              />
            </Box>
          </Box>
          <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
            <button className="cancel-filter" onClick={closeFilterDrawer}>
              Reset
            </button>
            <button
              className="order-filter-button"
              type="submit"
              style={{
                cursor: "pointer",
              }}
            >
              Filter
            </button>
          </Stack>
        </form>
      </Drawer>
    </>
  );
}
