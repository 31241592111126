import { Box } from "@mui/system";
import React from "react";
import UserList from "../User/UserList";

const User = () => {
  return (
    <Box >
      <UserList />
    </Box>
  );
};
export default User;
