import { Box } from "@mui/system";
import React from "react";
import ParcelTypeList from "./ParcelTypeList";

const ParcelType = () => {
    return (
        <Box>
            <ParcelTypeList />
        </Box>
    );
};

export default ParcelType;


