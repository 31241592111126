import React, { useEffect, useState } from 'react'
import Modal from "@mui/material/Modal";
import { useForm, Form } from "../../Utils/useForm";
import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Controls from "../controls/Controls";
import {
    getStateLocationList,
    stateLocationStatus,
    deleteState,
    addState,
    updateStateLocation,
    getDummpedStateList
} from "../../state/action/locationAction";
import { useProSidebar } from "react-pro-sidebar";
import axios from "axios";

const initialValues = {
    name: "",
    north: "",
    south: "",
    west: "",
    east: "",
    dumpContryId: ""
};
const styles = {
    position: "absolute",
    top: "50%",
    left: { xs: "50%", sm: "65%", md: "50", lg: "50%", xl: "50%" },
    transform: "translate(-50%, -50%)",
    width: { xs: "330px", sm: "400px", md: "400px", lg: "400px", xl: "400px" },
    border: "0",
    outline: "0",
    bgcolor: "#fff",
    borderRadius: "10px",
    p: 3,
};
function AddStateModel(props) {
    const { handleCloseAdd, openAddModel, isEdit, page, enqueueSnackbar, StateListData, stateData, selectedCountryId, allCountries } = props;
    const [allDummpedStates, setallDummpedStates] = useState([])
    const { collapsed, broken } = useProSidebar();
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("name" in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required.";
        if ("dumpContryId" in fieldValues)
            temp.dumpContryId = fieldValues.dumpContryId ? "" : "This field is required.";
        // if ("north" in fieldValues) {
        //     if (fieldValues.north !== "") {
        //         if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.north)) {
        //             temp.north = "Invalid Input";
        //         } else {
        //             temp.north = "";
        //         }
        //     } else {
        //         temp.north = "This field is required.";
        //     }
        // }

        // if ("south" in fieldValues) {
        //     if (fieldValues.south !== "") {
        //         if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.south)) {
        //             temp.south = "Invalid Input";
        //         } else {
        //             temp.south = "";
        //         }
        //     } else {
        //         temp.south = "This field is required.";
        //     }
        // }

        // if ("west" in fieldValues) {
        //     if (fieldValues.west !== "") {
        //         if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.west)) {
        //             temp.west = "Invalid Input";
        //         } else {
        //             temp.west = "";
        //         }
        //     } else {
        //         temp.west = "This field is required.";
        //     }
        // }

        // if ("east" in fieldValues) {
        //     if (fieldValues.east !== "") {
        //         if (!/^[0-9]*\.?[0-9]*$/.test(fieldValues.east)) {
        //             temp.east = "Invalid Input";
        //         } else {
        //             temp.east = "";
        //         }
        //     } else {
        //         temp.east = "This field is required.";
        //     }
        // }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            const payload = {
                name: values.name,
                countryId: values.dumpContryId,
                north: values.north,
                south: values.south,
                west: values.west,
                east: values.east,
            };
            if (isEdit) {
                const res = await updateStateLocation(stateData.id, payload);
                if (res && res.status === true) {

                    StateListData(page, "");
                    handleCloseAdd();
                    enqueueSnackbar(res.message, {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                } else {
                    // StateListData(page, "");

                    // handleCloseAdd();
                    enqueueSnackbar(res.message, {
                        variant: "error",
                        autoHideDuration: 2000,
                    });
                }
            }

            if (!isEdit) {
                const res = await addState(payload);
                if (res && res.status === true) {
                    resetForm();
                    StateListData(page, "");
                    handleCloseAdd();
                    enqueueSnackbar(res.message, {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                } else {
                    // StateListData(page, "");

                    // handleCloseAdd();
                    enqueueSnackbar(res.message, {
                        variant: "error",
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    };

    const getStateList = async (id) => {
        const getDummyStates = await getDummpedStateList(id);
        if (getDummyStates.status == true) {
            setallDummpedStates(getDummyStates.data)
        } else {
            enqueueSnackbar(getDummyStates.message, {
                variant: "error",
                autoHideDuration: 2000,
            });
            setallDummpedStates([]);
        }
    }

    const handelCountrySelect = async (e) => {
        setValues({ ...values, dumpContryId: e.target.value })
        getStateList(e.target.value)
    }
    // console.log('stateData', );
    useEffect(() => {
        if (isEdit) {
            setValues({
                ...values,
                name: stateData?.dumpStateId,
                dumpContryId: stateData?.countryId,
                stateId: stateData?.stateId,
                north: stateData.north,
                south: stateData.south,
                west: stateData.west,
                east: stateData.east,
            });
            getStateList(stateData?.countryId)
        }
        else {
            resetForm()
        }

    }, [isEdit]);

    const handleStateCountry = async (event) => {
        const stateId = event.target.value;
        const selectedState = allDummpedStates.find(item => item.id === stateId);
        const selectedCountry = allCountries.find(item => item.id === values.dumpContryId);
        console.log('selectedCountry -----', selectedCountry);
        if (selectedState) {
            try {
                const qry = `${selectedCountry.name},${selectedState.name}`
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                    params: {
                        address: qry,
                        key: 'AIzaSyD2cfignyIQC94CJmpxD5XdLakeuyAOOnQ' // Replace 'YOUR_API_KEY' with your actual API key
                    }
                });

                const { data } = response;
                if (data.status === "OK" && data.results?.length) {
                    const bounds = data.results[0].geometry.bounds;

                    if (bounds) {
                        setValues(prevValues => ({
                            ...prevValues,
                            name: stateId,
                            north: bounds.northeast.lat,
                            south: bounds.southwest.lat,
                            west: bounds.southwest.lng,
                            east: bounds.northeast.lng
                        }));
                    }
                } else {
                    enqueueSnackbar("Selected state not found", {
                        variant: "error",
                        autoHideDuration: 2000,
                    });
                }
            } catch (error) {
                enqueueSnackbar("Selected country not found", {
                    variant: "error",
                    autoHideDuration: 2000,
                });
            }
        }
    };
    return (
        <>
            <Modal
                // keepMounted
                open={openAddModel}
                onClose={handleCloseAdd}
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: { xs: "50%", sm: broken ? "50%" : collapsed ? "50%" : "65%", md: collapsed ? "50%" : "60%", lg: collapsed ? "50%" : "55%" },
                    transform: "translate(-50%, -50%)",
                    width: { xs: "700px", sm: "600px" },
                    border: "0",
                    outline: "0",
                    bgcolor: "#fff",
                    borderRadius: "10px",
                    p: 3,
                }}>
                    <Typography
                        sx={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#000",
                            marginBottom: "15px",
                        }}
                    >
                        {isEdit ? "Edit State" : "Add State"}
                    </Typography>
                    <Form sx={{ width: "100%" }} onSubmit={handleFormSubmit}>
                        <Grid container style={{ marginTop: "2rem", }}>

                            <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <Controls.SelectBox
                                    className="input"
                                    label="Select Country"
                                    fullWidth
                                    id="dumpContryId"
                                    name="dumpContryId"
                                    value={values.dumpContryId}
                                    onChange={handelCountrySelect}
                                    options={allCountries}
                                    error={errors.dumpContryId}
                                    helperText={errors.dumpContryId}
                                />

                            </Grid>

                            <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <Controls.SelectBox
                                    className="input"
                                    label="Select State"
                                    fullWidth
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleStateCountry}
                                    options={allDummpedStates}
                                    error={errors.name}
                                    helperText={errors.name}
                                />
                            </Grid>

                            <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <table border={2} style={{ color: "black", border: "1px solid #dddddd", padding: "10px", borderCollapse: "collapse", width: "100%" }}>
                                    {/* <th> */}
                                    <tr>
                                        <th>North</th>
                                        <th>South</th>
                                        <th>West</th>
                                        <th>East</th>
                                    </tr>
                                    <tr>
                                        <td>{values?.north}</td>
                                        <td>{values?.south}</td>
                                        <td>{values?.west}</td>
                                        <td>{values?.east}</td>
                                    </tr>
                                </table>
                            </Grid>

                            {/* <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <Controls.Input
                                    text="North"
                                    variant="outlined"
                                    color="primary"
                                    label="North"
                                    name="north"
                                    value={values.north}
                                    onChange={handleInputChange}
                                    error={errors.north}
                                    helperText={errors.north}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <Controls.Input
                                    text="South"
                                    variant="outlined"
                                    color="primary"
                                    label="South"
                                    name="south"
                                    value={values.south}
                                    onChange={handleInputChange}
                                    error={errors.south}
                                    helperText={errors.south}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <Controls.Input
                                    text="West"
                                    variant="outlined"
                                    color="primary"
                                    label="West"
                                    name="west"
                                    value={values.west}
                                    onChange={handleInputChange}
                                    error={errors.west}
                                    helperText={errors.west}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <Controls.Input
                                    text="East"
                                    variant="outlined"
                                    color="primary"
                                    label="East"
                                    name="east"
                                    value={values.east}
                                    onChange={handleInputChange}
                                    error={errors.east}
                                    helperText={errors.east}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid> */}
                            <Grid container justifyContent="end"  >
                                <Grid item xs={12} textAlign="end">
                                    <Controls.Button
                                        style={{ backgroundColor: "#fff", color: "#000", }}
                                        text="Cancel"
                                        onClick={handleCloseAdd}
                                    />
                                    <Controls.Button
                                        sx={{ marginLeft: "15px" }}
                                        text="Submit"
                                        type="submit"
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Form>
                </Box>
            </Modal>
        </>
    )
}

export default AddStateModel