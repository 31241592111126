import axiosRequest from "../axios/axios";

//get PushNotifiction List
export const getPushNotificationLists = async (queryString) => {
  try {
    const response = await axiosRequest.get(`getNotification` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add PushNotifiction

export const addPushNotification = async (payload) => {
  try {
    const response = await axiosRequest.post(`addNotification`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//PushNotifiction Delete
export const DeletePushNotifiction = async (id) => {
  try {
    const response = await axiosRequest.delete(`deleteNotification/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get PushNotifiction coupan
export const getCoupanPush = async () => {
  try {
    const response = await axiosRequest.get(`getOfferCode`);
    return response.data;
  } catch (error) {
    return error;
  }
};
