import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Dialog,
  DialogContent,
  Paper,
  Tab,
  Slide,
  Tabs,
  TextField,
  useTheme,
  Divider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useLocation, useNavigate } from "react-router";
import { styled } from "@mui/system";
import { useForm } from "../../Utils/useForm";
import { getRiderDetails } from "../../state/action/riderAction";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import OrderDetailsPage from "./OrderDetailsPage";
import OrderHistory from "./OrderHistory";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { getOrderDetails } from "../../state/action/orderAction";
import VerifiedIcon from "../../assets/premium.png";
import UsrIcon from "../../assets/user.png";
import { Margin } from "@mui/icons-material";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};
const initialValues = {
  remark: "",
};
//
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: "gray",
      },
    },
    MuiTab: {
      root: {
        "&:hover": {
          backgroundColor: "gray",
          color: "gray",
        },
      },
      selected: {
        backgroundColor: "white",
        color: "black",
        "&:hover": {
          backgroundColor: "white",
          color: "white",
        },
      },
    },
  },
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OrderDetails = () => {
  const [value, setValue] = React.useState("1");

  const [orderList, setOrderList] = useState([]);
  const [ridersnapimageList, setridersnapimageList] = useState([]);
  
  const [riderList, setRiderList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [orderHistoryList, setorderHistoryList] = useState([]);
  const [currentImageIndex, setImageIndex] = useState(0);
  const [openPassportBack, setOpenimage] = useState(false);
  // image

  const handleClickOpenimage = (index) => {
    setImageIndex(index);
    setOpenimage(true);
  };
  const handleCloseimages = () => {
    setOpenimage(false);
  };

  //tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const { state } = useLocation();

  //get the details
  const getDetails = async (state) => {
    const res = await getOrderDetails(state);
    if (res && res.status) {
      setOrderList(res?.data);
      setRiderList(res.data.RiderData);
      setUserList(res.data.UserData);
      setorderHistoryList(res.data.orderHistory);
      setridersnapimageList(res.data.ridersnapimage);
      
    }
  };

  useEffect(() => {
    getDetails(state);
  }, [state]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Typography
          sx={{
            paddingTop: "25px",
            paddingBottom: "15px",
            fontSize: "15px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/orders");
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ verticalAlign: "top", marginRight: "5px" }}
          />
          Orders
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          marginLeft: "25px",
          marginRight: "25px",
          marginBottom: "25px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8}>
            <Item>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <MuiThemeProvider theme={theme}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          label="Order Detail"
                          value="1"
                          sx={{ width: "50%", color: "black" }}
                        />
                        <Tab
                          label="Order History"
                          value="2"
                          sx={{ width: "50%", color: "black" }}
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ color: "black" }}>
                      <OrderDetailsPage />
                    </TabPanel>
                    <TabPanel value="2" sx={{ color: "black" }}>
                      <OrderHistory />
                    </TabPanel>
                  </MuiThemeProvider>
                </TabContext>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            {userList !== "" ? (
              <Item>
                <Box>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "20px",
                      px: 2,
                      pb: 1,
                      fontWeight: "bold",
                      display: "flex",
                    }}
                  >
                    User Information
                  </Typography>
                </Box>
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

                <Card sx={{ display: "flex", boxShadow: "none", m: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div className="cd-icon">
                        <Avatar
                          alt="Remy Sharp"
                          sx={{ width: 70, height: 70 }}
                          src={userList?.profilePic}
                        />
                      </div>

                      <div className="cd-detail">
                        <h2
                          style={{
                            color: "black",
                            display: "flex",
                            justifyContent: "end",
                            marginLeft: "12px",
                          }}
                        >
                          {userList?.name}
                          {userList?.isPro !== 0 ? (
                            <img
                              src={UsrIcon}
                              alt=""
                              style={{
                                width: "30px",
                                height: "31px",
                                color: "blue",
                                marginTop: "-17px",
                              }}
                            />
                          ) : null}
                        </h2>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <EmailIcon sx={{ color: "black" }} />
                        <span
                          style={{
                            fontSize: "15px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        >
                          {userList?.email}
                        </span>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        my: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <PhoneIcon sx={{ color: "black" }} />
                        <span
                          style={{
                            fontSize: "15px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        >
                          {userList?.countryCode}
                          {userList?.mobile}
                        </span>
                      </div>
                    </Box>
                  </Box>
                </Card>
              </Item>
            ) : null}

            {riderList !== "" ? (
              <Item sx={{ mt: 2 }}>
                <Box>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "20px",
                      px: 2,
                      pb: 1,
                      fontWeight: "bold",
                      display: "flex",
                    }}
                  >
                    Rider Information
                  </Typography>
                </Box>
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

                <Card sx={{ display: "flex", boxShadow: "none", m: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <div className="cd-icon">
                        <Avatar
                          alt="Remy Sharp"
                          sx={{ width: 70, height: 70 }}
                          src={riderList?.profilePic}
                        />
                      </div>

                      <div className="cd-detail">
                        <h2
                          style={{
                            color: "black",
                            display: "flex",
                            justifyContent: "end",
                            marginLeft: "12px",
                          }}
                        >
                          {riderList?.name}
                          {riderList?.isPro !== 0 ? (
                            <img
                              src={VerifiedIcon}
                              alt=""
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "blue",
                              }}
                            />
                          ) : null}
                        </h2>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <EmailIcon sx={{ color: "black" }} />
                        <span
                          style={{
                            fontSize: "15px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        >
                          {riderList?.email}
                        </span>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        my: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <PhoneIcon sx={{ color: "black" }} />
                        <span
                          style={{
                            fontSize: "15px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        >
                          {riderList?.countryCode}
                          {riderList?.mobile}
                        </span>
                      </div>
                    </Box>
                  </Box>
                </Card>
              </Item>
            ) : null}

            <Item sx={{ mt: 2 }}>
                <Box>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "20px",
                      px: 2,
                      pb: 1,
                      fontWeight: "bold",
                      display: "flex",
                    }}
                  >
                    Show rider snap
                  </Typography>
                </Box>
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />

                {ridersnapimageList?.map((row) => {
                  return (
                    row.remark != null ? (
                      <Card sx={{ display: "flex", boxShadow: "none", m: 2 }} key={row?.id}>
                        <Box>
                          <Box>
                            <div className="cd-icon" style={{ margin: "20px" }}>
                              <Avatar
                                component="span"
                                variant="rounded"
                                sx={{
                                  width: {
                                    sm: "100px",
                                    md: "100px",
                                    lg: "150px",
                                    xs: "100%",
                                  },
                                  height: {
                                    lg: "100px",
                                    xs: "150px",
                                  },
                                }}
                                src={row && row.remark != null ? JSON.parse(row?.remark)[0] : ""}
                                onClick={(e) => {
                                  handleClickOpenimage();
                                }}
                              />
                            </div>
                          </Box>
                        </Box>
                      </Card>
                    ) : null
                  );
                })}
              </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrderDetails;
