import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";

import {
  AddNeedHelps,
  updateNeedHelp,
} from "../../state/action/needhelpAction";

const initialValues = {
  userType: "",
  question: "",
  answer: "",
  sequence: "",
};

const userTypesList = [
  { id: "1", name: "User" },
  { id: "2", name: "Rider" },
];

const AddNeedHelp = (props) => {
  const { open, handleClose, needHelpData, refreshScreen, setIsEdit, isEdit } =
    props;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("userType" in fieldValues)
      temp.userType = fieldValues.userType ? "" : "This field is required.";
    if ("sequence" in fieldValues) {
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.sequence)) {
        temp.sequence = " Only numbers are allowed";
      } else {
        temp.sequence = "";
      }
    }

    if ("question" in fieldValues)
      temp.question = fieldValues.question ? "" : "This field is required.";

    if ("answer" in fieldValues)
      temp.answer = fieldValues.answer ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        userType: values.userType,
        question: values.question,
        answer: values.answer,
        sequence: values.sequence,
      };

      if (isEdit) {
        const res = await updateNeedHelp(needHelpData.id, body);
        if (res && res.status === true) {
          close();
          refreshScreen();
          resetForm();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await AddNeedHelps(body);
        if (res && res.status === true) {
          close();
          refreshScreen();
          resetForm();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  const reset = () => {
    resetForm();
  };

  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        userType: needHelpData.userType,
        question: needHelpData.question,
        answer: needHelpData.answer,
        sequence: needHelpData.sequence,
      });
    } else {
      resetForm();
    }
  }, [isEdit, needHelpData]);

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{ width: "50%", marginLeft: "450px", color: "black" }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Need Help " : "Add Need Help"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  resetForm();
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.SelectBox
                    name="userType"
                    label="Select UserType"
                    value={values.userType}
                    onChange={handleInputChange}
                    options={userTypesList}
                  />
                  {Boolean(errors.userType) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.userType}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Sequence"
                    name="sequence"
                    value={values.sequence}
                    onChange={handleInputChange}
                    error={errors.sequence}
                    helperText={errors.sequence}
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Question"
                    name="question"
                    multiline
                    rows={3}
                    value={values.question}
                    onChange={handleInputChange}
                    error={errors.question}
                    helperText={errors.question}
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Answer"
                    name="answer"
                    value={values.answer}
                    onChange={handleInputChange}
                    error={errors.answer}
                    helperText={errors.answer}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "-40px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default AddNeedHelp;
