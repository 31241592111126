import { useRef, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useProSidebar } from "react-pro-sidebar";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import { addVehical, updateVehical } from "../../state/action/vehicalAction";
import "./AddVehicle.css";
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  name: "",
  sequence: "",
  capacity: "",
  cost: "",
  loadingTime: "",
  additionalPrice: "",
  description: "",
  vehicleIcon: "",
  baseCost: "",

};

const AddVehicle = (props) => {
  const {
    open,
    handleClose,
    stateData,
    VehicalTypeList,
    refreshScreen,
    isEdit,
  } = props;

  const { collapsed, broken } = useProSidebar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [vehicleImage, setVehicleImage] = useState({});
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("sequence" in fieldValues) {
      
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.sequence)) {
        temp.sequence = " Only numbers are allowed";
      } else {
        temp.sequence = "";
      }
    }

    if ("baseCost" in fieldValues) {
      let reg = /^[+-]?([0-9]*[.])?[0-9]+$/;
      if (fieldValues.baseCost === "") {
        temp.baseCost = "This field is required.";
      } else if (!reg.test(fieldValues.baseCost)) {
        temp.baseCost = "Please enter valid baseCost.";
      } else {
        temp.baseCost = "";
      }
    }


    if ("capacity" in fieldValues)
      temp.capacity = fieldValues.capacity ? "" : "This field is required.";

    if ("additionalPrice" in fieldValues)
      temp.additionalPrice = fieldValues.additionalPrice
        ? ""
        : "This field is required.";

    if ("loadingTime" in fieldValues)
      temp.loadingTime = fieldValues.loadingTime
        ? ""
        : "This field is required.";

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";

    if ("cost" in fieldValues) {
      
      var regex = /^\d+(\.\d+)?$/;
      if (!regex.test(fieldValues.cost)) {
        temp.cost = " Only numbers are allowed";
      } else {
        temp.cost = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("cost", values.cost);
      formData.append("capacity", values.capacity);
      formData.append("sequence", values.sequence);
      if (vehicleImage && vehicleImage.name) {
        formData.append("vehicleIcon", vehicleImage);
      }
      formData.append("loadingTime", values.loadingTime);
      formData.append("additionalPrice", values.additionalPrice);
      formData.append("description", values.description);
      formData.append("baseCost", values.baseCost);
      

      if (isEdit) {
        const res = await updateVehical(stateData.id, formData);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        if (vehicleImage && vehicleImage.name) {
          const res = await addVehical(formData);
          if (res && res.status === true) {
            close();
            refreshScreen();
            setImage("");
            resetForm();
            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        } else {
          enqueueSnackbar("Please Selecte Image", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
        setVehicleImage({});
        resetImage();
      } else {
        return true;
      }
    }
  };

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const removeImage = () => {
    resetImage();
    setVehicleImage({});
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["vehicleIcon"];
      return newValues;
    });
  };

  async function handleImageItem(e) {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setVehicleImage(selected);
        setValues({ ...values, vehicleIcon: selected });
      } else {
      }
    } else {
      enqueueSnackbar("Maximum image size for upload is 5MB.", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        name: stateData.name,
        additionalPrice: stateData.additionalPrice,
        capacity: stateData.capacity,
        cost: stateData.cost,
        description: stateData.description,
        loadingTime: stateData.loadingTime,
        sequence: stateData.sequence,
        baseCost: stateData?.baseCost,
       
        vehicleIcon: stateData.vehicalIcon,
      });
    } else {
      resetForm();
    }
  }, [isEdit, stateData]);

  useEffect(() => {
    if (isEdit) {
      setImage(stateData?.vehicalIcon);
      setVehicleImage(stateData?.vehicalIcon);
    } else {
      setImage("");
    }
  }, [isEdit, stateData]);

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        className={collapsed ? "" : "vehicleModel" + broken ? "noMargin" : ""}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "auto", md: "auto", lg: "80%", xl: "80%" },
          marginLeft: {
            xs: "0px",
            sm: collapsed ? "10%" : "28%",
            md: collapsed ? "10%" : "250px",
            lg: collapsed ? "12%" : "260px",
            xl: collapsed ? "12%" : "260px",
          },
          color: "black",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Vehicle Type" : "Add New Vehicle Type"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setImage("");
                  resetForm();
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={3} sm={12} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        image == ""
                          ? {}
                          : {
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }
                      }
                    >
                      {image === "" && <img src={camera} alt="Camera" />}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    {isEdit ? "Edit Profile Image" : "Add Profile Image"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 5 MB
                  </Typography>

                  {image != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage();
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Grid>

              <Grid item md={9} sm={12} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Vehical Name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Per Km Cost"
                    name="cost"
                    value={values.cost}
                    onChange={handleInputChange}
                    error={errors.cost}
                    helperText={errors.cost}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Base Cost"
                    name="baseCost"
                    value={values.baseCost}
                    onChange={handleInputChange}
                    error={errors.baseCost}
                    helperText={errors.baseCost}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>
               
                <Grid item sx={{ m: 1}} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Capacity"
                    name="capacity"
                    value={values.capacity}
                    onChange={handleInputChange}
                    error={errors.capacity}
                    helperText={errors.capacity}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Grid>
                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Sequence"
                    name="sequence"
                    value={values.sequence}
                    onChange={handleInputChange}
                    error={errors.sequence}
                    helperText={errors.sequence}
                    inputProps={{
                      maxLength: 5,
                    }}
                  />
                </Grid>
                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Loading Time	"
                    name="loadingTime"
                    value={values.loadingTime}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    inputProps={{
                      maxLength: 150,
                    }}
                    error={errors.loadingTime}
                    helperText={errors.loadingTime}
                  />
                </Grid>
                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Additional Price	"
                    name="additionalPrice"
                    value={values.additionalPrice}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    inputProps={{
                      maxLength: 150,
                    }}
                    error={errors.additionalPrice}
                    helperText={errors.additionalPrice}
                  />
                </Grid>

                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    inputProps={{
                      maxLength: 150,
                    }}
                    error={errors.description}
                    helperText={errors.description}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "-124px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    setImage("");
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default AddVehicle;
