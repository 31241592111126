import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import { useForm, Form } from "../../Utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Paper from "@mui/material/Paper";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";

import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

import camera from "../../assets/camera.svg";
import { addOffer, updateOffer } from "../../state/action/offerAction";
import {
  getRiderDropdownList,
  getUserDropdownList,
} from "../../state/action/orderAction";
import DeleteIcon from "@mui/icons-material/Delete";

import { Space } from "antd";

import CloseIcon from "@mui/icons-material/Close";
import {
  supScriptionPlanAdd,
  updateSubscription,
} from "../../state/action/subscription";

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
   
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const strong = {
  color: "black",
};

const initialValues = {
  userType: "",
  planName: "",
  title: "",
  image: "",
  planPrice: "",
  planExpirationDuration: "",
  planDurationType: "",
  maxOrder: "",
  finalPrice: "",
  dayCount: "",
  planDescription: "",
};

const userList = [
  { id: 1, name: "User" },
  { id: 2, name: "Rider" },
];

const planDurationType = [{ id: 1, name: "Month" }];

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [rowsTermsData, setRowsTermsData] = useState([]);
  const [val, setVal] = useState([]);
  const [subscriptionImage, setsubscriptionImage] = useState();
 //image
  const [image, setImage] = useState("");
  const addTableRows = () => {
    const rowsInput = {
      image: "",
      subValue: "",
      description: "",
      sequence: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  //terms and condition

  const addTableRowsTerms = () => {
    const rowsInput = {
      subValue: "",
      description: "",
      sequence: "",
    };
    setRowsTermsData([...rowsTermsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  //terms and condition

  const deleteTableRowsTerms = (index) => {
    const rows = [...rowsTermsData];
    rows.splice(index, 1);
    setRowsTermsData(rows);
  };

  const handleChanges = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  //terms and condition

  const handleChangesTerms = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsTermsData];
    rowsInput[index][name] = value;
    setRowsTermsData(rowsInput);
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("userType" in fieldValues)
      temp.userType = fieldValues.userType ? "" : "This field is required.";

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";

    if ("planName" in fieldValues)
      temp.planName = fieldValues.planName
        ? ""
        : "This field is required.";
    if ("planPrice" in fieldValues) {
      let regex2 = /^[0-9\b]+(\.[0-9]{1,2})?$/;
      if (regex2.test(fieldValues.planPrice)) {
        temp.planPrice = "";
      } else {
        temp.planPrice = "Only number allowed";
      }
    }
    if ("planExpirationDuration" in fieldValues) {
      let regex2 = /^[0-9\b]+(\.[0-9]{1,2})?$/;
      if (regex2.test(fieldValues.planExpirationDuration)) {
        temp.planExpirationDuration = "";
      } else {
        temp.planExpirationDuration = "Only number allowed";
      }
    }
    if ("maxOrder" in fieldValues) {
      let regex2 = /^[0-9\b]+(\.[0-9]{1,2})?$/;
      if (regex2.test(fieldValues.maxOrder)) {
        temp.maxOrder = "";
      } else {
        temp.maxOrder = "Only number allowed";
      }
    }
    if ("planDurationType" in fieldValues)
      temp.planDurationType = fieldValues.planDurationType ? "" : "This field is required.";
   
   

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //descrption for array
  const data = rowsData?.map((row) => row);
  //terms and condition
  const dataTerms = rowsTermsData?.map((row) => row);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("userType", values.userType);
      formData.append("planName", values.planName);
      formData.append("title", values.title);
      if(subscriptionImage){
        formData.append("image",subscriptionImage);
      }
      formData.append("planPrice", values.planPrice);
      formData.append("planExpirationDuration", values.planExpirationDuration);
      formData.append("planDurationType", values.planDurationType);
      formData.append("maxOrder", values.maxOrder);
      formData.append("dayCount", values.planExpirationDuration * 30);
      formData.append(
        "finalPrice",
        values.planPrice * values.planExpirationDuration
      );
      formData.append("planDescription", JSON.stringify(data));
      formData.append("termsConditionFAQs", JSON.stringify(dataTerms));

      if (isEdit) {
        const res = await updateSubscription(state?.subscription?.id, formData);
        if (res && res.status === true) {
          navigate("/subscription");

          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await supScriptionPlanAdd(formData);

        if (res && res.status === true) {
          navigate("/subscription");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

 

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
    setsubscriptionImage({});
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setsubscriptionImage(selected);
      setValues({ ...values, subscriptionImage: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  //details edit
  useEffect(() => {
    if (
      state?.subscription.id != "" &&
      state?.subscription.id != null &&
      state?.subscription.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        userType: state?.subscription.userType,
        planName: state?.subscription.planName,
        title: state?.subscription.title,
        planPrice: state?.subscription.planPrice,
        planExpirationDuration: state?.subscription?.planExpirationDuration,
        planDurationType: state?.subscription?.planDurationType,
        description: state?.subscription?.description,
        discountAmount: state?.subscription?.discountAmount,
        discountType: state?.subscription?.discountType,
        maxOrder: state?.subscription?.maxOrder,
        finalPrice: state?.subscription?.finalPrice,
        dayCount: state?.subscription?.dayCount,
        planDescriptions: state?.subscription?.planDescriptions,
        planFAQ: state?.subscription?.planFAQ,
      });
      setImage(state?.subscription?.image);
      setRowsData(state?.subscription?.planDescriptions);
      setRowsTermsData(state?.subscription?.planFAQ);
    } else {
      resetForm();
    }
  }, [state?.subscription]);

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              sx={{
                paddingTop: "25px",
                paddingBottom: "15px",
                fontSize: "15px",
                fontWeight: "600",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/subscription");
              }}
            >
              <KeyboardBackspaceIcon
                sx={{ verticalAlign: "top", marginRight: "5px" }}
              />
              Subscription
            </Typography>
            <div>
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: "10px",
                }}
                text="Cancel"
                onClick={() => {
                  navigate("/subscription");
                  resetForm();
                }}
              />
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Submit"
                onClick={(e) => handleSubmit(e)}
                type="submit"
              />
            </div>
          </Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Box
            className="b1"
            sx={{
              backgroundColor: "white",
            }}
          >
            <Grid item sx={{ m: 1 }} md={6} xs={12}>
              <StyledImageUploadWrapper>
                <label htmlFor="image-upload">
                  <StyledInput
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    onChange={handleImageItem}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                  <StyledIconWrapper
                    sx={
                      image == ""
                        ? {}
                        : {
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                    }
                  >
                    {image === "" && <img src={camera} alt="Camera" />}
                  </StyledIconWrapper>
                </label>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Add Profile Image
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(15, 15, 15, 0.5)",
                    mx: 2,
                    fontSize: "12px",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                </Typography>
                {image != "" && (
                  <StyledRemoveButton
                    onClick={(e) => {
                      e.preventDefault();
                      removeImage();
                    }}
                  >
                    Remove
                  </StyledRemoveButton>
                )}
              </StyledImageUploadWrapper>
            </Grid>
            <Grid
              item
              md={12}
              justifyContent="center"
              sm={12}
              xs={12}
              container
            >
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input"
                  fullWidth
                  name="userType"
                  label="Select User"
                  value={values.userType}
                  onChange={handleInputChange}
                  options={userList}
                />
                 {Boolean(errors.userType) ? (
                <p
                  style={{
                    color: "rgb(255 0 0)",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.userType}
                </p>
              ) : (
                ""
              )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Plan Name"
                  name="planName"
                  fullWidth
                  value={values.planName}
                  onChange={handleInputChange}
                  error={errors.planName}
                  helperText={errors.planName}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Title"
                  name="title"
                  fullWidth
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                  helperText={errors.title}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Base Price"
                  name="planPrice"
                  value={values.planPrice}
                  onChange={handleInputChange}
                  error={errors.planPrice}
                  helperText={errors.planPrice}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  name="planDurationType"
                  onChange={handleInputChange}
                  value={values.planDurationType}
                  options={planDurationType}
                  s
                  label="Select Plan Duration Type"
                />
                 {Boolean(errors.planDurationType) ? (
                <p
                  style={{
                    color: "rgb(255 0 0)",
                    marginTop: "4px",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  {errors.planDurationType}
                </p>
              ) : (
                ""
              )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Plan Expiration Duration Month"
                  name="planExpirationDuration"
                  value={values.planExpirationDuration}
                  onChange={handleInputChange}
                  error={errors.planExpirationDuration}
                  helperText={errors.planExpirationDuration}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Plan Price"
                  name="finalPrice"
                  value={values.planPrice * values.planExpirationDuration}
                  onChange={handleInputChange}
                  error={errors.finalPrice}
                  helperText={errors.finalPrice}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Max Order"
                  name="maxOrder"
                  value={values.maxOrder}
                  onChange={handleInputChange}
                  error={errors.maxOrder}
                  helperText={errors.maxOrder}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}></Grid>{" "}
            </Grid>
            <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            <Grid
              item
              md={12}
              justifyContent="center"
              sm={12}
              xs={12}
              container
            >
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <Typography
                  sx={{
                    fontWeight: "400",
                    marginLeft: "28px",
                    fontSize: "20px",
                  }}
                >
                  Description
                </Typography>
              </Grid>

              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <Box
                  sx={{
                    backgroundColor: "rgb(241, 245, 249)",
                    width: "98%",
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: 1,
                      borderColor: "#eaeaea",
                    }}
                  >
                    <Table className="table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Image</StyledTableCell>
                          <StyledTableCell>Title</StyledTableCell>
                          <StyledTableCell>Description</StyledTableCell>
                          <StyledTableCell>Sequnces</StyledTableCell>

                          <StyledTableCell sx={{ width: "10px" }}>
                            <Button
                              variant="contained"
                              size="large"
                              style={{
                                backgroundColor: "blue",
                                borderRadius: "10px",
                                width: "0%",
                              }}
                              startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                              fullWidth
                              onClick={addTableRows}
                            />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsData?.map((data, index) => {
                          const { image, subValue, description, sequence } =
                            data;

                          return (
                            <StyledTableRow
                              key={index}
                              sx={{ cursor: "pointer" }}
                            >
                              <StyledTableCell component="th" scope="row">
                                <Controls.Input
                                  text="Add New"
                                  variant="outlined"
                                  color="primary"
                                  label="Image Link"
                                  name="image"
                                  value={image}
                                  onChange={(e) => {
                                    handleChanges(index, e);
                                    handleInputChange(e);
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Controls.Input
                                  text="Add New"
                                  variant="outlined"
                                  color="primary"
                                  label="Title"
                                  value={subValue}
                                  onChange={(e) => {
                                    handleChanges(index, e);
                                    handleInputChange(e);
                                  }}
                                  name="subValue"
                                />
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Controls.Input
                                  text="Add New"
                                  variant="outlined"
                                  color="primary"
                                  label="Description"
                                  value={description}
                                  onChange={(e) => {
                                    handleChanges(index, e);
                                    handleInputChange(e);
                                  }}
                                  name="description"
                                />
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Controls.Input
                                  text="Add New"
                                  variant="outlined"
                                  color="primary"
                                  label="Sequnces"
                                  value={sequence}
                                  onChange={(e) => {
                                    handleChanges(index, e);
                                    handleInputChange(e);
                                  }}
                                  name="sequence"
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Space size="middle">
                                  <Tooltip placement="bottom" title="Delete ">
                                    <button
                                      className="action-button delete-btn"
                                      onClick={() => deleteTableRows(index)}
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <RiDeleteBinLine />
                                    </button>
                                  </Tooltip>
                                </Space>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            <Grid
              item
              md={12}
              justifyContent="center"
              sm={12}
              xs={12}
              container
            >
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <Typography
                  sx={{
                    fontWeight: "400",
                    marginLeft: "28px",
                    fontSize: "20px",
                  }}
                >
                  Terms And Condition
                </Typography>
              </Grid>

              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <Box
                  sx={{
                    backgroundColor: "rgb(241, 245, 249)",
                    width: "98%",
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: 1,
                      borderColor: "#eaeaea",
                    }}
                  >
                    <Table className="table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Title</StyledTableCell>
                          <StyledTableCell>Description</StyledTableCell>
                          <StyledTableCell>Sequnces</StyledTableCell>

                          <StyledTableCell sx={{ width: "10px" }}>
                            <Button
                              variant="contained"
                              size="large"
                              style={{
                                backgroundColor: "blue",
                                borderRadius: "10px",
                                width: "0%",
                              }}
                              startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                              fullWidth
                              onClick={addTableRowsTerms}
                            />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsTermsData?.map((data, index) => {
                          const { subValue, description, sequence } = data;

                          return (
                            <StyledTableRow
                              key={index}
                              sx={{ cursor: "pointer" }}
                            >
                              <StyledTableCell component="th" scope="row">
                                <Controls.Input
                                  text="Add New"
                                  variant="outlined"
                                  color="primary"
                                  label="Title"
                                  value={subValue}
                                  onChange={(e) => {
                                    handleChangesTerms(index, e);
                                    handleInputChange(e);
                                  }}
                                  name="subValue"
                                />
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Controls.Input
                                  text="Add New"
                                  variant="outlined"
                                  color="primary"
                                  label="Description"
                                  value={description}
                                  onChange={(e) => {
                                    handleChangesTerms(index, e);
                                    handleInputChange(e);
                                  }}
                                  name="description"
                                />
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Controls.Input
                                  text="Add New"
                                  variant="outlined"
                                  color="primary"
                                  label="Sequnces"
                                  value={sequence}
                                  onChange={(e) => {
                                    handleChangesTerms(index, e);
                                    handleInputChange(e);
                                  }}
                                  name="sequence"
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Space size="middle">
                                  <Tooltip placement="bottom" title="Delete ">
                                    <button
                                      className="action-button delete-btn"
                                      onClick={() =>
                                        deleteTableRowsTerms(index)
                                      }
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <RiDeleteBinLine />
                                    </button>
                                  </Tooltip>
                                </Space>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Box>
    </>
  );
}
