import axiosRequest from "../axios/axios";

export const updatePlayerId = async (id, payload) => {
    try {
        const response = await axiosRequest.post(`updatePlayerId/${id}`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
}
