import React, { useEffect, useState } from "react";
import {
  Avatar,
  InputBase,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Autocomplete,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Tooltip, Drawer, FormLabel, TextField } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { Space } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
// import AddSettingModal from "./AddSettings";
import {
  getRiderDropdownList,
} from "../../state/action/orderAction";

import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import {
  DeleteSetting,
  StatusUpdate,
  getSettingLists,
} from "../../state/action/settingAction";

import {
  getProfitSharingLists,
  getCompanyDropdownList,
  getexportProfitSharingFile
} from "../../state/action/profitSharingAction";
import moment from "moment";

const initialValues = {

};
const today = dayjs();
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {

    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(State, Status, Action) {
  return { State, Status, Action };
}
const rows = [createData("Alashka")];

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [settingsList, setSettingsList] = useState([]);
  const [riderLists, setRiderLists] = useState([]);
  const [companyLists, setcomapnyLists] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);

  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [flagFromState, setFlagFromState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");

  const [sortFlag, setSortFlag] = useState(false);
  const [id, setId] = useState("");
  const [parameterData, setParameterData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [flagName, setflagName] = useState(false);
  const [flagParameterType, setflagParameterType] = useState(false);
  const [flagValueCode, setflagValueCode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [exportdata, setexportdata] = useState(0);

  const parameterStatusLists = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];

  const RecordPerPage = [
    { id: "10", title: "10" },
    { id: "20", title: "20" },
    { id: "30", title: "30" },
    { id: "40", title: "40" },
  ];

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const handleChangePage = (e, value) => {
    if (fieldName == "settingKey") {
      SettingList(page, fieldName, flagName);
    } else {
      SettingList(page, "", "");
    }

    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "name") {
      SettingList(page, fieldName, flagName, event);
    } else {
      SettingList(page, "", "", event);
    }
  };

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  console.log("values", values)

  // Setting List
  useEffect(() => {
    SettingList(page, "", "");
  }, [refresh, filterData, search, page]);


  //rider dropdownlist
  useEffect(() => {
    getriderListing(page, "", "");
  }, []);
  const getriderListing = async () => {
    setOpenBreakdrop(true);
    const res = await getRiderDropdownList();
    if (res.status === true) {
      setRiderLists(res.data);

      setOpenBreakdrop(false);
    } else {
      setRiderLists([]);
    }
    setFilter(false);
  };

  //rider dropdownlist
  useEffect(() => {
    getcompanyListing(page, "", "");
  }, []);
  const getcompanyListing = async () => {
    setOpenBreakdrop(true);
    const res = await getCompanyDropdownList();
    if (res.status === true) {
      setcomapnyLists(res.data);

      setOpenBreakdrop(false);
    } else {
      setcomapnyLists([]);
    }
    setFilter(false);
  };

  const exports = async () => {
    let queryString = ``;
    const res = await getexportProfitSharingFile(queryString);
    console.log(res)
  };
  useEffect(() => {
    exports();
  }, []);


  // console.log("exportdata",exportdata)
  const SettingList = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let recordLimit = limit ? limit : pageLimit;




    let queryString = `?page=${page}${string}&limit=${recordLimit}`;

    const res = await getProfitSharingLists(queryString);
    console.log(res)
    if (res.status === true) {
      setSettingsList(res.data);
      setTotalRecords(res.totalCount);
      setPageLimit(recordLimit);
      setTotalPages(Math.ceil(res.totalCount / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setSettingsList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  //status Change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await StatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "settingKey") {
        SettingList(page, fieldName, flagName);
      } else {
        SettingList(page, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //delete
  const handleDelete = async () => {
    const res = await DeleteSetting(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "settingKey") {
        SettingList(page, fieldName, flagName);
      } else {
        SettingList(page, "", "");
      }
      handleCloseDelete();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };



  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();

  const dataShorting = (name) => {
    if (name === "settingKey") {
      setflagName(!flagName);
      SettingList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(name);
  };

  const [AddParcel, setAddParcel] = useState(false);
  const openParcelModal = () => {
    resetForm();
    setAddParcel(true);
  };
  const closeParcelModal = () => {
    setAddParcel(false);
    setIsEdit(false);
  };

  return (
    <>
      <Box>
        <BackdropComponent open={openBackdrop} />

        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Profit Sharing
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<ArrowDownwardIcon />}
                    // onClick={exports}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = `${process.env.REACT_APP_API_URL}/exportProfitSharingFile`;
                    }}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}

                  >
                    Export
                  </Button>
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table" sx={{ width: "154%" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Booking Date</StyledTableCell>
                    <StyledTableCell>Transaction Id</StyledTableCell>
                    <StyledTableCell>Booking UserId</StyledTableCell>
                    <StyledTableCell>Booking UserName</StyledTableCell>
                    <StyledTableCell>Booking UserMobile</StyledTableCell>
                    <StyledTableCell>Reciever Name</StyledTableCell>
                    <StyledTableCell>Reciever Mobile</StyledTableCell>
                    <StyledTableCell>Company Name</StyledTableCell>
                    <StyledTableCell>Company Owner</StyledTableCell>
                    <StyledTableCell>Company Mobile</StyledTableCell>
                    <StyledTableCell>Pickup Address</StyledTableCell>
                    <StyledTableCell>Drop Address</StyledTableCell>
                    <StyledTableCell>Vehicle</StyledTableCell>
                    <StyledTableCell>Total KM</StyledTableCell>
                    <StyledTableCell>Pre Discount Fare</StyledTableCell>
                    <StyledTableCell>Discount Amount</StyledTableCell>
                    <StyledTableCell>Discount Code</StyledTableCell>
                    <StyledTableCell>Fare Post Discount</StyledTableCell>
                    <StyledTableCell>Total FRoundUp</StyledTableCell>
                    <StyledTableCell>Payment GWFixed Charges</StyledTableCell>
                    <StyledTableCell>Payment GW Commission Amount</StyledTableCell>
                    <StyledTableCell>Payment GWTotal Amount</StyledTableCell>
                    <StyledTableCell>Final ValueOf_A</StyledTableCell>
                    <StyledTableCell>Roundup Value</StyledTableCell>
                    <StyledTableCell>Base Fare</StyledTableCell>

                    <StyledTableCell>Final ValueOf_B</StyledTableCell>
                    <StyledTableCell>Jyffit Company Profit</StyledTableCell>
                    <StyledTableCell>Jyffit Final Amount</StyledTableCell>
                    <StyledTableCell>ACP</StyledTableCell>
                    <StyledTableCell>JCP</StyledTableCell>
                    <StyledTableCell>Freelancer Profit</StyledTableCell>
                    <StyledTableCell>Aggrecatter Profit</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settingsList?.map((row) => {
                    return (
                      <StyledTableRow
                        sx={{ cursor: "pointer" }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {moment(row.bookingDate).format("MM-DD-YYYY LT")}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.transactionId}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.bookingUserId}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.bookingUserName}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.bookingUserMobile}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.recieverName}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.recieverMobile}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.companyName || "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.companyowner || "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.companyMobile || "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.pickupAddress}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.dropAddress}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.vehical}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.totalKM}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.preDiscountFare}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.discountAmount}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {'-'}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.farePostDiscount}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.totalFRoundUp}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.PaymentGWFixedCharges}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.paymentGWCommissionAmount}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.PaymentGWTotalAmount}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.finalValueOf_A}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.RoundupValue}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.baseFare}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.finalValueOf_B}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.jyffitCompanyProfit}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.jyffitFinalAmount}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.ACP}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.JCP}
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          {row?.FreelancerProfit}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.AggrecatterProfit}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {settingsList && settingsList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {" "}
              Showing{" "}
              {settingsList.length
                ? settingsList.length
                : page * pageLimit} of {totalRecords} Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>
              <Select
                className="recordLimit"
                sx={{
                  color: "#000",
                  height: "40px",
                  fontSize: "15px",
                  borderRadius: "5px",
                  border: 1,
                  paddingLeft: "10px",
                  borderColor: "#000",
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="limites"
                name="limites"
                value={pageLimit}
                onChange={(e) => {
                  handleChangeRow(e.target.value);
                }}
              >
                {RecordPerPage.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Status Change
            </Typography>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                <StyledFormLabel htmlFor="name">Start Date</StyledFormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values.startDate ? values.startDate : null}
                    name="startDate"
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        startDate: newValue ? dayjs(newValue).toDate() : null,
                      });
                    }}
                    label="Start Date" // Update the label
                    renderInput={(params) => <Controls.Input {...params} />} // Make sure Controls.Input is defined
                  />
                </LocalizationProvider>
              </Box>

              <Box>
                <StyledFormLabel htmlFor="hsnCode">End Date</StyledFormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values.endDate ? values.endDate : null}
                    name="endDate"
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        endDate: newValue ? dayjs(newValue).toDate() : null,
                      });
                    }}
                    label="End Date" // Update the label
                    renderInput={(params) => <Controls.Input {...params} />} // Make sure Controls.Input is defined
                  />
                </LocalizationProvider>
              </Box>

              <StyledFormLabel htmlFor="name">Rider Name / Mobile</StyledFormLabel>
              <Box sx={{ marginTop: "10px" }}>
                <Autocomplete
                  id="riderId"
                  name="riderId"
                  value={riderLists.find((rider) => rider.id === values.riderId) || null} // Initial selected rider object
                  onChange={(event, newValue) => {
                    setValues({
                      ...values,
                      riderId: newValue ? newValue.id : null,
                    });
                  }}
                  options={riderLists}
                  variant="outlined"
                  color="primary"
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Select a rider" />}

                />
              </Box>

              <StyledFormLabel htmlFor="name">Company Name / Mobile</StyledFormLabel>
              <Box sx={{ marginTop: "10px" }}>
                <Autocomplete
                  id="companyId"
                  name="companyId"
                  value={companyLists.find((company) => company.id === values.companyId) || null} // Initial selected company object
                  onChange={(event, newValue) => {
                    setValues({
                      ...values,
                      companyId: newValue ? newValue.id : null,
                    });
                  }}
                  options={companyLists}
                  variant="outlined"
                  color="primary"
                  getOptionLabel={(option) => option.companyName}
                  renderInput={(params) => <TextField {...params} label="Select a company" />}
                />
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button
                className="order-filter-button"
                type="submit"
                style={{
                  cursor: "pointer",
                }}
              >
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>

        {/* <AddSettingModal
          open={AddParcel}
          setOpen={setAddParcel}
          handleClickOpen={openParcelModal}
          handleClose={closeParcelModal}
          refreshScreen={refreshScreen}
          parameterData={parameterData}
          isEdit={isEdit}
        /> */}
      </Box>
    </>
  );
}
