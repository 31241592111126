import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});
const initialValues = {
  fname: "",
  lname: "",
  email: "",
  mobile: "",
  country: "",
  state: "",
  city: "",
  address: "",
  zipcode: "",
};

const AssignOrder = (props) => {
  const { open, handleClose } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("fname" in fieldValues)
      temp.fname = fieldValues.fname ? "" : "This field is required.";
    if ("lname" in fieldValues)
      temp.lname = fieldValues.lname ? "" : "This field is required.";

    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fieldValues.email
          )
        ) {
          temp.email = "";
        } else {
          temp.email = "Please enter valid email.";
        }
      } else {
        temp.email = fieldValues.email ? "" : "This field is required.";
      }
    }

    if ("mobile" in fieldValues) {
      if (fieldValues.mobile !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.mobile)) {
          temp.mobile = "";
        } else {
          temp.mobile = "Only Number allow";
        }
      } else {
        temp.mobile = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const close = () => {
    reset();
    handleClose();
  };

  const handleSubmit = async (e) => {};

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["profile_image"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, profile_image: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{ width: "80%", marginLeft: "260px", color: "black" }}
      >
        <DialogTitle
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "30px",
            display: "flex",
            justifyContent: "space-between",
            color: "black",
          }}
        >
          Assign Order
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                close();
              }}
            >
              <Close sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              maxWidth: {
                lg: "1250px",
              },
              marginTop: "-27px",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                marginBottom: "20px",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
                marginRight: "20px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ boxShadow: 0, paddingLeft: "0!important" }}
              >
                <Table
                  aria-label="simple table"
                  sx={{ paddingLeft: "0!important" }}
                >
                  <TableHead sx={{ paddingLeft: "0!important" }}>
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f9fb",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Rider Id
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Rider Name
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        City Name
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "none",
                          borderColor: "rgba(107, 114, 128, .4)",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#fff",
                      paddingLeft: "0!important",
                    }}
                  >
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "400",
                          textTransform: "none",
                        }}
                      >
                        -
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "400",
                          textTransform: "none",
                        }}
                      >
                        xyz
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "400",
                          textTransform: "none",
                        }}
                      >
                        Ahemdabad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "400",
                          textTransform: "none",
                        }}
                      >
                        <Tooltip placement="bottom" title="Assign Order">
                          <Button
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              borderRadius: "50px",
                              border: "0",
                              color: "#fff",
                              ":hover": {
                                bgcolor: "rgb(79, 70, 229)",
                                border: "0",
                              },
                              backgroundColor: "rgb(79, 70, 229)",

                              textTransform: "none",
                              marginLeft: "-7px",
                            }}
                            type="submit"
                            form="addnewUser"
                          >
                            Assign
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#fff",
              backgroundColor: "Red",
              ":hover": { bgcolor: "Red" },
              textTransform: "none",
              borderRadius: "50px",
              boxShadow: 0,
            }}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignOrder;
