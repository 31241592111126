import React from "react";
import Topbar from "../../pages/global/Topbar";
import { MyProSidebarProvider } from "../../pages/global/sidebar/sidebarContext";
import Main from "../Layout/Main";

function Layout() {
  return (
    <MyProSidebarProvider>
      <div style={{ height: "100%", width: "100%", overflowY: "scroll" }}>
        <main>
          <Topbar />
          <Main />
        </main>
      </div>
    </MyProSidebarProvider>
  );
}

export default Layout;
