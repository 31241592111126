import axiosRequest from "../axios/axios";

//get Rider List
export const getSubscriptionList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/planList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSubscriptionDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/planView/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider Delete
export const DeleteSubscription = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteplan/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Rider Status Update

export const SubscriptionStatusUpdate = async (id, data) => {
  try {
    const response = await axiosRequest.put(`/statusChange/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider update

export const updateSubscription = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`/updateplan/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//rider Add
export const supScriptionPlanAdd = async (payload) => {
  try {
    const response = await axiosRequest.post(`/addsubscriptionplan`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//subscription specificaion delete
export const DeleteSubscriptionSpecification = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteDescription/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//subscriptiontermsdelete

export const DeleteSubscriptionTerms = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteSubscriptionFAQ/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//subscriptiondescriptiondelete

export const DeleteSubscriptionDescription = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteDescription/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
