import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { Grid, IconButton } from "@mui/material";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./AddNotification.css";
import { useSnackbar } from "notistack";
import Close from "@mui/icons-material/Close";
import camera from "../../assets/camera.svg";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import {
  addPushNotification,
  getCoupanPush,
} from "../../state/action/pushNotification";

const initialValues = {
  notificationImage: "",
  sendTo: "",
  description: "",
  isRedirect: "",
  offerID: "",
  title: "",
};
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});
const AddNotification = (props) => {
  const { handleClose, open, refreshScreen, data } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [coupanListing, setCoupanListing] = useState([]);

  const planTypes = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];

  const Types = [
    { id: 1, title: "User" },
    { id: 2, title: "Rider" },
  ];

  const navigate = useNavigate();

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, notificationImage: selected });
    } else {
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["notificationImage"];
      return newValues;
    });
  };
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("sendTo" in fieldValues) {
      temp.sendTo = fieldValues.sendTo ? "" : "This field is required.";
    }

    if ("description" in fieldValues) {
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";
    }
    if ("isRedirect" in fieldValues) {
      temp.isRedirect = fieldValues.isRedirect ? "" : "This field is required.";
    }

    if ("offerID" in fieldValues) {
      temp.offerID = fieldValues.offerID ? "" : "This field is required.";
    }
    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("notificationImage", values.notificationImage);
      formData.append("sendTo", values.sendTo);
      formData.append("description", values.description);
      formData.append("isRedirect", values.isRedirect);
      formData.append("offerID", values.offerID);
      formData.append("title", values.title);

      const res = await addPushNotification(formData);
      if (res && res.status == true) {
        close();
        refreshScreen();
        resetForm();
        setImage("");
        handleClose();
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  const reset = () => {
    resetImage();
    resetForm();
  };

  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  //get coupan

  const getCoupanList = async () => {
    const res = await getCoupanPush();
    if (res.status === true) {
      setCoupanListing(res.data);
    } else {
      setCoupanListing([]);
    }
  };

  // Push List
  useEffect(() => {
    getCoupanList();
  }, []);

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "70%", md: "50%", lg: "50%", xl: "50%" },
          marginLeft: {
            xs: "0px",
            sm: "30%",
            md: "430px",
            lg: "430px",
            xl: "430px",
          },
          color: "black",
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "30px",
            display: "flex",
            justifyContent: "space-between",
            color: "black",
          }}
        >
          Add Push Notification
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                setImage("");
                resetForm();
                close();
              }}
            >
              <Close sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Form onSubmit={handleSubmit} id="addNewBuyer">
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "1px",
              }}
            >
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
                container
                spacing={1}
                sx={{ display: "contents" }}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <StyledImageUploadWrapper>
                    <label htmlFor="image-upload">
                      <StyledInput
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        onChange={handleImageItem}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      <StyledIconWrapper
                        sx={
                          image == ""
                            ? {}
                            : {
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }
                        }
                      >
                        {image === "" && <img src={camera} alt="Camera" />}
                      </StyledIconWrapper>
                    </label>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      Add Profile Image
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(15, 15, 15, 0.5)",
                        mx: 2,
                        fontSize: "12px",
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                    </Typography>
                    {image != "" && (
                      <StyledRemoveButton
                        onClick={(e) => {
                          e.preventDefault();
                          removeImage();
                        }}
                      >
                        Remove
                      </StyledRemoveButton>
                    )}
                  </StyledImageUploadWrapper>
                  {Boolean(errors.vehicleIcon) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.vehicleIcon}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} className="fields">
                  <Box display="flex" alignItems="center">
                    <Controls.RadioGroup
                      name="sendTo"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      value={values.sendTo}
                      items={Types}
                      // defaultChecked={values.type}
                      isRowDir={true}
                    />{" "}
                    {Boolean(errors.sendTo) ? (
                      <p
                        style={{
                          color: "rgb(255 0 0)",
                          marginTop: "4px",
                          marginBottom: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.sendTo}
                      </p>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
                  <Controls.Input
                    name="title"
                    onChange={handleInputChange}
                    value={values.title}
                    error={errors.title}
                    helperText={errors.title}
                    label="Title"
                    sx={{ marginTop: "0px !important" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="Discription"
                    multiline
                    rows={4}
                    name="description"
                    onChange={handleInputChange}
                    value={values.description}
                    error={errors.description}
                    helperText={errors.description}
                    sx={{ marginTop: "0px !important" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.SelectBox
                    name="isRedirect"
                    onChange={handleInputChange}
                    value={values.isRedirect}
                    options={planTypes}
                    error={errors.isRedirect}
                    label="Is redirect to screen"
                  />
                  {Boolean(errors.isRedirect) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.isRedirect}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controls.SelectBox
                    name="offerID"
                    onChange={handleInputChange}
                    value={values.offerID}
                    options={coupanListing}
                    label="Select Coupon"
                  />
                  {Boolean(errors.offerID) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.offerID}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  marginRight: "-40px",
                  color: "#000",
                }}
                text="Cancel"
                onClick={() => {
                  close();
                  setImage("");
                  resetForm();
                }}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Submit"
                onClick={handleSubmit}
                type="submit"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNotification;
