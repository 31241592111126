import { Box } from "@mui/material";
import React from "react";
import CompanyList from "./CompanyList";

const Company = () => {
  return (
    <Box>
      <CompanyList />
    </Box>
  );
};

export default Company;