import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";

import User from "../../pages/User/User";

import Order from "../../pages/Order/Order";
import State from "../../pages/Location/State";
import AddState from "../../pages/Location/AddState";
import City from "../../pages/Location/City";
import Country from "../../pages/Location/Country";
import AddCity from "../../pages/Location/AddCity";
import { Routes, Route } from "react-router-dom";
import Payment from "../../pages/Payment/Payment";
import Rider from "../../pages/Rider/Rider";
import ParcelType from "../../pages/Parcel-type/ParcelType";
import VehicleType from "../../pages/Vehicle-type/VehiclelType";
import PrivacyPolicy from "../../pages/Privacy Policy/PrivacyPolicy";
import TermsCondition from "../../pages/Terms-condition/TermsCondition";
import PushNotification from "../../pages/Push-notification/PushNotification";
import CouponsOffers from "../../pages/Coupons-offers/CouponsOffers";
import RiderDetails from "../../pages/Rider/RiderDetails";
import DetailsUser from "../../pages/User/DetailsUser";
import OrderDetails from "../../pages/Order/Orderdetail";
import OrderRequestDetails from "../../pages/Order/OrderRequestDetails";
import AddCouponsOffers from "../../pages/Coupons-offers/AddCouponsOffers";
import ParameterValue from "../../pages/ParameterValue/ParameterValue";
import ExtraCharges from "../../pages/ExtraCharges/ExtraCharges";
import AddExtraCharges from "../../pages/ExtraCharges/AddExtraCharges";
import OrderPayment from "../../pages/Payment/OrderPayment";
import PlanPayment from "../../pages/Payment/PlanPayment";
import RiderRequestDetails from "../../pages/Rider/RiderReqDetails";
import Subscription from "../../pages/Subscription/Suscription";
import Banner from "../../pages/Banner/Banner";
import AddPrivacy from "../../pages/Privacy Policy/PrivacyPoliyAdd";
import AddTerms from "../../pages/Terms-condition/TermsConditionAdd";
import Settings from "../../pages/Settings/Settings";
import SubScriptionAdd from "../../pages/Subscription/SubscriptionAdd";
import WithDraw from "../../pages/Withdraw/Withdraw";
import NeedHelp from "../../pages/NeedHelp/NeedHelp";
import Notification from "../../pages/Notification/Notification";
import Company  from "../../pages/company/Company";
import AddCompany from "../../pages/company/CompanyAdd"
import CompanyDetails from "../../pages/company/CompanyDetails"
import ProfitSharing from "../../pages/ProfitSharing/ProfitSharing";
import PaymentMode from "../../pages/PaymentMode/PaymentMode";
function Main() {
  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/rider" element={<Rider />} />
        <Route path="/riderdetails" element={<RiderDetails />} />
        <Route path="/riderReqDetails" element={<RiderRequestDetails />} />
        <Route path="/addSubscription" element={<SubScriptionAdd />} />

        <Route path="/user" element={<User />} />
        <Route path="/userdetails" element={<DetailsUser />} />

        <Route path="/city" element={<City />} />
        <Route path="/state" element={<State />} />
        <Route path="/addcity" element={<AddCity />} />
        <Route path="/addstate" element={<AddState />} />
        <Route path="/country" element={<Country />} />

        <Route path="/orders" element={<Order />} />
        <Route path="/orderdetails" element={<OrderDetails />} />
        <Route path="/orderReqDetails" element={<OrderRequestDetails />} />

        <Route path="/payment" element={<OrderPayment />} />
        <Route path="/planpayment" element={<PlanPayment />} />

        <Route path="/parcel" element={<ParcelType />} />
        <Route path="/vehicle" element={<VehicleType />} />
        <Route path="/paymentmode" element={<PaymentMode />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/addprivacy" element={<AddPrivacy />} />

        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/addterms" element={<AddTerms />} />

        <Route path="/push-notification" element={<PushNotification />} />
        <Route path="/notification" element={<Notification />} />

        <Route path="/coupons-offers" element={<CouponsOffers />} />
        <Route path="/addcouponsoffers" element={<AddCouponsOffers />} />

        <Route path="/parameterValue" element={<ParameterValue />} />

        <Route path="/extraCharges" element={<ExtraCharges />} />
        <Route path="/addextraCharges" element={<AddExtraCharges />} />

        <Route path="/subscription" element={<Subscription />} />

        <Route path="/banner" element={<Banner />} />

        <Route path="/withdraw" element={<WithDraw />} />
        <Route path="/needhelp" element={<NeedHelp />} />

        <Route path="/setting" element={<Settings />} />
        <Route path="/company" element={<Company />} />
        <Route path="/addcompany" element={<AddCompany />} />
        <Route path="/companydetails" element={<CompanyDetails />} />

        <Route path="/profitsharing" element={<ProfitSharing />} />

      </Routes>
    </div>
  );
}

export default Main;
