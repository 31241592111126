import { Box } from "@mui/material";
import React from "react";
import SubscriptionList from "./SubscriptionList";

const Subscriptions = () => {
  return (
    <Box>
      <SubscriptionList />
    </Box>
  );
};

export default Subscriptions;
