import { Box } from "@mui/material";
import React from "react";
import TermsConditionList from "./TermsConditionList";

const TermsCondition = () => {
  return (
    <Box>
      <TermsConditionList />
    </Box>
  );
};

export default TermsCondition;
