import React, { useState } from "react";

export function useForm1(initialFValues, validateOnChange = false, validate1) {
  const [values1, setValues1] = useState(initialFValues);
  const [errors1, setErrors1] = useState({});

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setValues1({
      ...values1,
      [name]: value,
    });
    if (validateOnChange) validate1({ [name]: value });
  };

  const resetForm1 = () => {
    setValues1(initialFValues);
    setErrors1({});
  };

  return {
    values1,
    setValues1,
    errors1,
    setErrors1,
    handleInputChange1,
    resetForm1,
  };
}

export function Form(props) {
  const { children, ...other } = props;
  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
