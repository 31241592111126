import { Box } from "@mui/material";
import React from "react";
import ProfitSharingList from "./ProfitSharingList";

const ProfitSharing = () => {
  return (
    <Box>
      <ProfitSharingList />
    </Box>
  );
};

export default ProfitSharing;