import { Box } from "@mui/system";
import React from "react";
import RiderList from "./RiderList";

const Rider = () => {
    return (
        <Box>
            <RiderList />
        </Box>
    );
};

export default Rider;


