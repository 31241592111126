import axiosRequest from "../axios/axios";

//get Need Help List
export const getNeedHelpList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`needHelpList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Need Help

export const AddNeedHelps = async (payload) => {
  try {
    const response = await axiosRequest.post(`needHelpAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Need Help Delete
export const DeleteNeedHelp = async (id) => {
  try {
    const response = await axiosRequest.delete(`needHelpDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Need Help Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`needHelpStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Need Help update

export const updateNeedHelp = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`needHelpUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
