import axiosRequest from "../axios/axios";

//get Need Help List
export const getCountryList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`countryLists` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const dumpAllContry = async (queryString) => {
  try {
    const response = await axiosRequest.get(`contriesall`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// allcontries
export const dumpAllContryList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`allcontries`);
    return response.data;
  } catch (error) {
    return error;
  }
};
//add Need Help

export const AddCountry = async (payload) => {
  try {
    const response = await axiosRequest.post(`countryAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Need Help Delete
export const DeleteCountry = async (id) => {
  try {
    const response = await axiosRequest.delete(`countryDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Need Help Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`countryStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Need Help update

export const updateCountry = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`countryUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// country data get api 

// export const getCountryDropdownList = async (payload) => {
//   try {
//     const response = await axiosRequest.put(`getcountryData/`, payload);
//     return response.data;
//   } catch (error) {
//     return error.response.data;
//   }
// };
