import { Box } from "@mui/material";
import React from "react";
import BannerList from "./BannerList";

const Banner = () => {
  return (
    <Box>
      <BannerList />
    </Box>
  );
};

export default Banner;
