import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import CardMedia from "@mui/material/CardMedia";

function Login() {
  return (
    <Box
      sx={{
        backgroundColor: "#1e293b",
        height: { xs: "100%", sm: "100vh", md: "100vh" },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "900px",
          margin: "0 auto",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Grid
          container
          item
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                padding: { xs: "20px", md: "40px" },
              }}
            >
              <CardMedia
                sx={{ maxWidth: "50px", marginBottom: "20px" }}
                component="img"
                image="../../assets/images/logo.png"
                alt="Paella dish"
              />
              <Typography
                sx={{ fontSize: "35px", fontWeight: "bold", color: "#000" }}
              >
                Forgot Password
              </Typography>
              <Typography
                sx={{ fontSize: "16px", color: "#000", marginBottom: "15px" }}
              >
                Lost your Password? Please enter your email address. You will
                recieve a link to create a new password via email.
              </Typography>
              <form>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Email"
                />
              </form>

              <Button
                sx={{
                  ":hover": { bgcolor: "rgb(79, 70, 229)" },
                  marginTop: "20px",
                  textTransform: "none",
                  fontSize: "16px",
                  color: "#fff",
                  width: "100%",
                  backgroundColor: "rgb(79, 70, 229)",
                  borderRadius: "15px",
                  padding: "10px 40px",
                }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box className="login_img">
              <CardMedia
                sx={{ maxWidth: "420px", marginLeft: "auto", padding: "10px" }}
                component="img"
                image="../../assets/images/login-img.jpg"
                alt="Paella dish"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Login;
