import React, { useState } from "react";
import { InputBase, Pagination } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Tooltip, Drawer, FormLabel, TextField } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { Space } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";

import { useNavigate } from "react-router";

import Modal from "@mui/material/Modal";

import { useSnackbar } from "notistack";
import AddExtraCharges from "./AddExtraCharges";

const initialValues = {
  name: "",
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(State, Status, Action) {
  return { State, Status, Action };
}
const rows = [createData("Alashka")];

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [switchId, setSwitchId] = useState(null);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [flagFromState, setFlagFromState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [sortFlag, setSortFlag] = useState(false);

  const handleChangePage = (e, value) => {};

  const options = ["View Cities"];
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleChangeStatus = async () => {};

  const handleDelete = async (id) => {};
  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();

  const dataShorting = (name) => {
    if (name == "name") {
      setFlagFromState(!flagFromState);
    } else {
      setFlagFromState(false);
      setSortFlag(false);
    }

    setFieldName(name);
  };

  //add
  const [extracharge, setAddExtracharge] = useState(false);
  const openUserModal = () => {
    setAddExtracharge(true);
  };
  const closeUserModal = () => {
    setAddExtracharge(false);
  };

  return (
    <>
      <Box>
        <BackdropComponent open={openBackdrop} />

        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Country/City
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Controls.Button
                    startIcon={<AddIcon />}
                    text="Add"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                    }}
                    onClick={openUserModal}
                    variant="outlined"
                  />
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Id</StyledTableCell>
                    <StyledTableCell>Title</StyledTableCell>
                    <StyledTableCell>Country Name</StyledTableCell>
                    <StyledTableCell>City Name</StyledTableCell>
                    <StyledTableCell>Charge</StyledTableCell>
                    <StyledTableCell>Created date</StyledTableCell>

                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                        }}
                      >
                        Status
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    sx={{ cursor: "pointer" }}
                  >
                    <StyledTableCell component="th" scope="row">
                      -
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      Forevermark
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      India
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      Pune
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      10
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      23/3/2023
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Controls.Switch
                        checked={true}
                        onChange={(e) => {
                          handleSwitchModel();
                        }}
                        name="checkedB"
                        color="primary"
                        sx={{ marginLeft: "40px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Space size="middle">
                        <Tooltip placement="bottom" title="Edit User">
                          <button
                            className="action-button edit"
                            onClick={openUserModal}
                          >
                            <RiEditLine />
                          </button>
                        </Tooltip>

                        <Tooltip
                          placement="bottom"
                          title="Delete Parameter value"
                        >
                          <button
                            className="action-button delete-btn"
                            onClick={() => {
                              handleDeleteModel();
                            }}
                          >
                            <RiDeleteBinLine />
                          </button>
                        </Tooltip>
                      </Space>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            }}
          >
            {" "}
            Showing{" "}
            {page * pagePerSize > totalRecords
              ? totalRecords
              : page * pagePerSize}{" "}
            of {totalRecords} Results
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Status Change
            </Typography>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                width: 300,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
                <StyledFormLabel htmlFor="name">Label</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
              </Box>

              <Box></Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button className="order-filter-button" type="submit">
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
        <AddExtraCharges
          open={extracharge}
          setOpen={setAddExtracharge}
          handleClickOpen={openUserModal}
          handleClose={closeUserModal}
        />
      </Box>
    </>
  );
}
