import axiosRequest from "../axios/axios";

//get Banner List
export const getDashboardDatas = async (queryString) => {
  try {
    const response = await axiosRequest.get(`getDashboardData` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};