import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { Routes, Route } from "react-router-dom";
import Rider from "../../pages/Rider/Rider";
import RiderDetails from "../../pages/Rider/RiderDetails";
import RiderRequestDetails from "../../pages/Rider/RiderReqDetails";
import { useSelector } from "react-redux";
function CompanyMain() {
  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/rider" element={<Rider />} />
        <Route path="/riderdetails" element={<RiderDetails />} />
        <Route path="/riderReqDetails" element={<RiderRequestDetails />} />
      </Routes>
    </div>
  );
}

export default CompanyMain;
