import { Box } from "@mui/system";
import React from "react";
import PaymentModeList from "./PaymentModeList";

const PaymentMode = () => {
    return (
        <Box>
            <PaymentModeList />
        </Box>
    );
};

export default PaymentMode;


