import axiosRequest from "../axios/axios";

//get PrivacyPolicy List
export const getPrivacyPolicyList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`privacyPolicyList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add PrivacyPolicy

export const addPrivacyPolicy = async (payload) => {
  try {
    const response = await axiosRequest.post(`privacyPolicyAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//PrivacyPolicy Delete
export const DeletePrivacyPolicy = async (id) => {
  try {
    const response = await axiosRequest.delete(`privacyPolicyDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//PrivacyPolicy Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`privacyPolicyStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//PrivacyPolicy update

export const updatePrivacyPolicy = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `privacyPolicyUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
