import axiosRequest from "../axios/axios";


//get PushNotifiction List
export const getProfitSharingLists = async (queryString) => {
    try {
      const response = await axiosRequest.get(`getsharing` + queryString);
      return response.data;
    } catch (error) {
      return error;
    }
  };


  //rider Dropdown
export const getCompanyDropdownList = async () => {
  try {
    const response = await axiosRequest.get(`/CompanyDropDown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get PushNotifiction List
export const getexportProfitSharingFile= async (queryString) => {
  try {
    const response = await axiosRequest.get(`exportProfitSharingFile` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};