import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Dialog,
  DialogContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./rider.css";
import { useLocation, useNavigate } from "react-router";

import moment from "moment";
import { styled } from "@mui/system";
import { useForm } from "../../Utils/useForm";
import {
  RiderApprove,
  RiderDocumentVerify,
  RiderReject,
  getRiderDetails,
} from "../../state/action/riderAction";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Controls from "../../Component/controls/Controls";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RiderVerifyDocument from "./RiderVerifyDocument";
import IdentyFront from "../../assets/identityfront.png";
import { useSnackbar } from "notistack";
import { useProSidebar } from "react-pro-sidebar";

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};
const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));
const initialValues = {
  remark: "",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const RiderReqDetails = () => {
  const [value, setValue] = useState("1");
  const [switchId, setSwitchId] = useState(null);

  const [riderList, setRiderList] = useState([]);
  const [riderDocsList, setRiderDocsList] = useState([]);
  const [deviceDetailsList, setDeviceDetailsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPassport, setOpenPassport] = useState(false);
  const [openPassportBack, setOpenPassportBack] = useState(false);
  const [openIdentityCard, setOpenIdentityCard] = useState(false);
  const [openIdentityCardBack, setOpenIdentityCardBack] = useState(false);
  const [openDriverLicense, setOpenDriverLicense] = useState(false);
  const [openDriverLicenseBack, setOpenDriverLicenseBack] = useState(false);
  const [openDriverLicenseOther, setOpenDriverLicenseOther] = useState(false);

  const [openVehicleDocument, setOpenVehicleDocument] = useState(false);
  const [openVehicleDocumentBack, setOpenVehicleDocumentBack] = useState(false);

  // visa
  const [openVisaDocument, setOpenVisaDocument] = useState(false);
  const [openVisaDocumentBack, setOpenVisaDocumentBack] = useState(false);

  const [openRegistrationCert, setOpenRegistrationCert] = useState(false);
  const [openRegistrationCertBack, setOpenRegistrationCertBack] =
    useState(false);

  const [openPermit, setOpenPermit] = useState(false);
  const [openPermitBack, setOpenPermitBack] = useState(false);

  const [openStatusModel, setOpenStatusModel] = useState(false);
  const [status, setStatus] = useState();
  const [currentImageIndex, setImageIndex] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openSwitchsModel, setOpenSwitchsModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [openRejectDetailsModel, setOpenRejectDetailsModel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { collapsed, broken } = useProSidebar();

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = (index) => {
    setImageIndex(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //passport

  const handleClickOpenPassport = (index) => {
    setImageIndex(index);
    setOpenPassport(true);
  };
  const handleClosePassport = () => {
    setOpenPassport(false);
  };

  //passportback

  const handleClickOpenPassportBack = (index) => {
    setImageIndex(index);
    setOpenPassportBack(true);
  };
  const handleClosePassportBack = () => {
    setOpenPassportBack(false);
  };

  //Identity Card

  const handleClickOpenIdentityCard = (index) => {
    setImageIndex(index);
    setOpenIdentityCard(true);
  };
  const handleCloseIdentityCard = () => {
    setOpenIdentityCard(false);
  };

  //Identity Card Back

  const handleClickOpenIdentityCardBack = (index) => {
    setImageIndex(index);
    setOpenIdentityCardBack(true);
  };
  const handleCloseIdentityCardBack = () => {
    setOpenIdentityCardBack(false);
  };

  //Driver License

  const handleClickOpenDriverLicense = (index) => {
    setImageIndex(index);
    setOpenDriverLicense(true);
  };
  const handleCloseDriverLicense = () => {
    setOpenDriverLicense(false);
  };

  //Driver License back

  const handleClickOpenDriverLicenseBack = (index) => {
    setImageIndex(index);
    setOpenDriverLicenseBack(true);
  };
  const handleCloseDriverLicenseBack = () => {
    setOpenDriverLicenseBack(false);
  };

  //Driver License other

  const handleClickOpenDriverLicenseOther = (index) => {
    setImageIndex(index);
    setOpenDriverLicenseOther(true);
  };
  const handleCloseDriverLicenseOther = () => {
    setOpenDriverLicenseOther(false);
  };

  //Registration Certificate

  const handleClickOpenRegistrationCertificate = (index) => {
    setImageIndex(index);
    setOpenRegistrationCert(true);
  };
  const handleCloseRegistrationCertificate = () => {
    setOpenRegistrationCert(false);
  };

  //Registration Certificate back

  const handleClickOpenRegistrationCertificateBack = (index) => {
    setImageIndex(index);
    setOpenRegistrationCertBack(true);
  };
  const handleCloseRegistrationCertificateBack = () => {
    setOpenRegistrationCertBack(false);
  };

  //Vehicle Document

  const handleClickOpenVehicleDocument = (index) => {
    setImageIndex(index);
    setOpenVehicleDocument(true);
  };
  const handleCloseVehicleDocument = () => {
    setOpenVehicleDocument(false);
  };

  // visa Document front

  const handleClickOpenVisaDocument = (index) => {
    setImageIndex(index);
    setOpenVisaDocument(true);
  };
  const handleCloseVisaDocument = () => {
    setOpenVisaDocument(false);
  };


  // visa Document back
  const handleClickOpenVisaDocumentBack = (index) => {
    setImageIndex(index);
    setOpenVisaDocumentBack(true);
  };
  const handleCloseVisaDocumentBack = () => {
    setOpenVisaDocumentBack(false);
  };

  //Vehicle Document

  const handleClickOpenVehicleDocumentBack = (index) => {
    setImageIndex(index);
    setOpenVehicleDocumentBack(true);
  };
  const handleCloseVehicleDocumentBack = () => {
    setOpenVehicleDocumentBack(false);
  };

  //Permit

  const handleClickOpenPermit = (index) => {
    setImageIndex(index);
    setOpenPermit(true);
  };
  const handleClosePermit = () => {
    setOpenPermit(false);
  };

  //Permit back

  const handleClickOpenPermitBack = (index) => {
    setImageIndex(index);
    setOpenPermitBack(true);
  };
  const handleClosePermitBack = () => {
    setOpenPermitBack(false);
  };

  //reject

  const handleRejectDetailsModel = () => setOpenRejectDetailsModel(true);
  const handleCloseRejectDetails = () => setOpenRejectDetailsModel(false);

  //delete the user
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  //document verify
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  //document reject
  const handleRejectModel = () => setOpenRejectModel(true);
  const handleCloseReject = () => setOpenRejectModel(false);

  //aprrove user
  const handleSwitchsModel = () => setOpenSwitchsModel(true);
  const handleCloseSwitchs = () => setOpenSwitchsModel(false);

  const navigate = useNavigate();

  const { state } = useLocation();
  const id = state?.rider;

  //details rider
  const getDetails = async (id) => {
    const res = await getRiderDetails(id);
    if (res && res.status) {
      setRiderList(res?.data);
      setRiderDocsList(res?.data?.riderDocs);
      setDeviceDetailsList(res.data.deviceDetails);
    }
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  //status change document
  const handleDocumentVerify = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      isApproved: 1,
    };
    const res = await RiderDocumentVerify(switchId, payload);

    if (res && res.status === true) {
      setOpenBreakdrop(false);
      handleCloseSwitch();
      getDetails(id);
      refreshScreen();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //document reject
  const handleDocumentReject = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      isApproved: 2,
    };
    const res = await RiderDocumentVerify(switchId, payload);

    if (res && res.status === true) {
      setOpenBreakdrop(false);
      handleCloseReject();
      getDetails(id);
      refreshScreen();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //reject the rider
  const handleRejectStatus = async (e) => {
    e.preventDefault();

    const body = {
      reason: values.reason,
    };

    const res = await RiderReject(id, body);

    if (res && res.status === true) {
      setOpenBreakdrop(false);
      handleCloseRejectDetails();
      navigate("/rider");
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  //document status
  const documentStatus = (documentStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        color: "#fc7e00",
      },
      1: {
        key: "Approved",
        color: "green",
      },
      2: {
        key: "Rejected",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[documentStatus]}
        label={
          statusColors[documentStatus] ? statusColors[documentStatus].key : ""
        }
      />
    );
  };

  // approve the status
  const handleApproveStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    // let queryString = `status=1`;
     if(id){
       const res = await RiderApprove(id);
       if (res && res.status === true) {
         setOpenBreakdrop(false);
         navigate("/rider");
         handleCloseSwitchs();
         enqueueSnackbar(res.response.data.message, {
           variant: "success",
           autoHideDuration: 2000,
         });
       } else {
         setOpenBreakdrop(!openBackdrop);
         enqueueSnackbar(res.response.data.message, {
           variant: "error",
           autoHideDuration: 2000,
         });
       }
     }
  };

  //device status
  const deviceStatus = (deviceStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "black",
      },
      0: {
        key: "No",
        color: "black",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[deviceStatus]}
        label={statusColors[deviceStatus] ? statusColors[deviceStatus].key : ""}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Riders
          </Typography>
          <div>
            <Controls.Button
              text="Back"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "50px",
                border: "0",
                color: "white",
                ":hover": { bgcolor: "black", border: "0" },
                backgroundColor: "black",
                textTransform: "none",
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          marginLeft: { xs: "15px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              md: "900px",
              lg: "100%",
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Item>
                  <Card
                    sx={{
                      paddingTop: "24px",
                      textAlign: "center",
                      color: "black",
                      paddingBottom: "15px",
                    }}
                  >
                    <StyledIcon
                      sx={{
                        color: "black",
                        backgroundImage: "white",
                        mt: 1,
                      }}
                    >
                      <Avatar
                        sx={{ width: "90px", height: "90px" }}
                        src={riderList?.profilePic}
                      />
                    </StyledIcon>

                    <Typography
                      variant="h4"
                      sx={{
                        marginTop: "25px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {riderList?.name}
                    </Typography>
                    <Typography
                      variant="h5"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 0.5 }}
                    >
                      <EmailIcon
                        sx={{ width: "15px", height: "15px", px: 1 }}
                      />{" "}
                      {riderList?.email}
                    </Typography>

                    <Typography
                      variant="h5"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 0.5 }}
                    >
                      <PhoneIcon
                        sx={{ width: "15px", height: "15px", px: 1 }}
                      />{" "}
                      {riderList?.countryCode} {riderList?.mobile}
                    </Typography>
                    <Typography
                      variant="h5"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 0.5 }}
                    >
                      <b style={{ marginRight: "5px" }}>Vehicle Type: </b>{" "}
                      {riderList?.vehicleType}
                    </Typography>
                    <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <b style={{ marginRight: "5px" }}>Referal Code: </b>
                    {riderList?.referalCode}
                  </Typography>
                  <Typography
                    variant="h5"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <b style={{ marginRight: "5px" }}>Joining Date: </b>
                    {moment(riderList?.createdAt).format('YYYY-MM-DD')}
                  </Typography>
                    <Typography
                      variant="h5"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 0.5 }}
                    ></Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ py: 1 }}
                    >
                      <Controls.Button
                        text="Reject"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "red",
                          backgroundColor: "#fff",
                          ":hover": { bgcolor: "#F1F5F9" },
                          textTransform: "none",
                          borderRadius: "15px",
                          border: 1,
                          borderColor: "#d3d3d3",
                          boxShadow: 0,
                        }}
                        onClick={() => {
                          handleRejectDetailsModel();
                        }}
                      />
                      <Controls.Button
                        text="Approve"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#fff",
                          backgroundColor: "#4F46E5",
                          ":hover": { bgcolor: "#4F46E5" },
                          textTransform: "none",
                          borderRadius: "15px",
                          border: 1,
                          borderColor: "#d3d3d3",
                          boxShadow: 0,
                          marginLeft: "7px",
                        }}
                        onClick={(e) => {
                          handleSwitchsModel();
                        }}
                      />
                    </Box>
                  </Card>
                </Item>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                <Paper sx={{ marginTop: "0px" }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "20px",
                        m: 1,
                        paddingTop: "15px",
                        px: 1,
                        fontWeight: "bold",
                      }}
                    >
                      Device Details
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      maxWidth: {
                        lg: "1250px",
                      },
                      marginTop: "-27px",
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "40px",
                        marginBottom: "20px",
                        borderTopRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        border: 1,
                        borderColor: "#eaeaea",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{
                          boxShadow: 0,
                          paddingLeft: "0!important",
                          height: "263px",
                          overFlow: "auto",
                        }}
                      >
                        <Table
                          aria-label="simple table"
                          sx={{ paddingLeft: "0!important", minWidth: 1205 }}
                        >
                          <TableHead sx={{ paddingLeft: "0!important" }}>
                            <TableRow
                              sx={{
                                backgroundColor: "#f6f9fb",
                                paddingLeft: "0!important",
                              }}
                            >
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Is Logged In
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Device Id
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Model
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Platform
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Device Version
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                App Version
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Registration Date
                              </TableCell>

                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Last Used
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Ip
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              backgroundColor: "#fff",
                              paddingLeft: "0!important",
                            }}
                          >
                            {deviceDetailsList?.map((row, i) => {
                              return (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {deviceStatus(row?.isLoggedIn)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row?.deviceID}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row?.model}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row?.platform}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row?.version}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row?.appVersion}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {moment(row.createdAt).format(
                                      "MM-DD-YYYY LT"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {moment(row.lastUsed).format(
                                      "MM-DD-YYYY LT"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row?.ip}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        {deviceDetailsList && deviceDetailsList.length === 0 ? (
                          <div
                            className="noDataFound"
                            style={{
                              display: "flex",
                            }}
                          >
                            {" "}
                            No Data Found
                          </div>
                        ) : null}
                      </TableContainer>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ marginTop: "23px", width: "100%" }}>
            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <Card
                    sx={{
                      boxShadow: 0,
                      color: "black",
                    }}
                  >
                    {row?.docType == 1 ? (
                      <Grid container spacing={{ xs: 2, md: 3 }} sx={{ px: 1 }}>
                        <Grid
                          item
                          xs={5}
                          sm={5}
                          md={6}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Basic Details
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>Name: </b>
                            {row?.firstName}
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>Birth date: </b>{" "}
                            {row?.dob}
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>

                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}

                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          sm={5}
                          md={6}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Profile Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpen(i);
                                  }}
                                  src={row?.imageFront}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={open}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClose}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                );
              })}
            </Item>

            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <>
                    <Card
                      sx={{
                        boxShadow: 0,

                        color: "black",
                      }}
                    >
                      {row.docType == 2 ? (
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                          sx={{ px: 1 }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ px: 2 }}
                          >
                            <Typography
                              variant="h3"
                              textAlign="left"
                              sx={{ py: 1 }}
                            >
                              Passport
                            </Typography>
                            <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Expiration Date:{" "}
                            </b>{" "}
                            {row?.expirationDate}
                          </Typography>
                            <Typography
                              variant="h5"
                              display="flex"
                              justifyContent="left"
                              alignItems="center"
                              sx={{ py: 0.5 }}
                            >
                              <b style={{ marginRight: "5px" }}>
                                Document Status:{" "}
                              </b>{" "}
                              {documentStatus(row?.isApproved)}
                            </Typography>

                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{ py: 1 }}
                            >
                              {row?.isApproved !== 1 &&
                              row?.isApproved !== 2 ? (
                                <Controls.Button
                                  text="Reject"
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "red",
                                    backgroundColor: "#fff",
                                    ":hover": { bgcolor: "#F1F5F9" },
                                    textTransform: "none",
                                    borderRadius: "15px",
                                    border: 1,
                                    borderColor: "#d3d3d3",
                                    boxShadow: 0,
                                  }}
                                  onClick={() => {
                                    handleRejectModel();
                                    setSwitchId(row?.id);
                                  }}
                                />
                              ) : null}
                              {row?.isApproved !== 1 &&
                              row?.isApproved !== 2 ? (
                                <Controls.Button
                                  text="Approve"
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#fff",
                                    backgroundColor: "#4F46E5",
                                    ":hover": { bgcolor: "#4F46E5" },
                                    textTransform: "none",
                                    borderRadius: "15px",
                                    border: 1,
                                    borderColor: "#d3d3d3",
                                    boxShadow: 0,
                                    marginLeft: "7px",
                                  }}
                                  onClick={() => {
                                    handleSwitchModel();
                                    setSwitchId(row?.id);
                                  }}
                                />
                              ) : null}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={8}
                            sx={{ px: 2, py: 1 }}
                          >
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                sx={{ px: 2 }}
                              >
                                <Box>
                                  <Typography
                                    variant="h4"
                                    textAlign="left"
                                    sx={{ py: 1 }}
                                  >
                                    Front Image
                                  </Typography>
                                  <Avatar
                                    component="span"
                                    variant="rounded"
                                    alt="profile_image"
                                    onClick={(e) => {
                                      handleClickOpenPassport(i);
                                    }}
                                    src={row?.imageFront}
                                    sx={{
                                      width: {
                                        sm: "100px",
                                        md: "100px",
                                        lg: "150px",
                                        xs: "100%",
                                      },
                                      height: {
                                        lg: "100px",
                                        xs: "150px",
                                      },
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                sx={{ px: 2 }}
                              >
                                <Box>
                                  <Typography
                                    variant="h4"
                                    textAlign="left"
                                    sx={{ py: 1 }}
                                  >
                                    Back Image
                                  </Typography>
                                  <Avatar
                                    component="span"
                                    variant="rounded"
                                    alt="profile_image"
                                    onClick={(e) => {
                                      handleClickOpenPassportBack();
                                    }}
                                    src={row?.imageBack}
                                    sx={{
                                      width: {
                                        sm: "100px",
                                        md: "100px",
                                        lg: "150px",
                                        xs: "100%",
                                      },
                                      height: {
                                        lg: "100px",
                                        xs: "150px",
                                      },
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Dialog
                            open={openPassport}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClosePassport}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogContent>
                              <img
                                style={{
                                  width: "50%",
                                  height: "100%",
                                  marginLeft: "127px",
                                }}
                                src={row?.imageFront}
                                alt=""
                              />
                            </DialogContent>
                          </Dialog>

                          <Dialog
                            open={openPassportBack}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClosePassportBack}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogContent>
                              <img
                                style={{
                                  width: "50%",
                                  height: "100%",
                                  marginLeft: "127px",
                                }}
                                src={row?.imageBack}
                                alt=""
                              />
                            </DialogContent>
                          </Dialog>
                        </Grid>
                      ) : null}
                    </Card>
                  </>
                );
              })}
            </Item>

            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <Card
                    sx={{
                      boxShadow: 0,

                      color: "black",
                    }}
                  >
                    {row?.docType == 3 ? (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ px: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Identity Card
                          </Typography>
                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "4px" }}>Identity No: </b>{" "}
                            {row?.identityNumber}
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>

                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}

                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Front Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenIdentityCard();
                                  }}
                                  src={row?.imageFront}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Back Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenIdentityCardBack();
                                  }}
                                  src={row?.imageBack}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={openIdentityCard}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseIdentityCard}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={openIdentityCardBack}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseIdentityCardBack}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageBack}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                );
              })}
            </Item>

            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <Card
                    sx={{
                      boxShadow: 0,

                      color: "black",
                    }}
                  >
                    {row?.docType == 4 ? (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ px: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Driver License
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Expiration Date:{" "}
                            </b>{" "}
                            {row?.expirationDate}
                          </Typography>
                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>

                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}

                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Front Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenDriverLicense();
                                  }}
                                  src={row?.imageFront}
                                  sx={{
                                    width: {
                                      sm: "100px",
                                      md: "100px",
                                      lg: "150px",
                                    },
                                    height: 100,
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Back Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenDriverLicenseBack();
                                  }}
                                  src={row?.imageBack}
                                  sx={{
                                    width: {
                                      sm: "100px",
                                      md: "100px",
                                      lg: "150px",
                                    },
                                    height: 100,
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Other Document
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenDriverLicenseOther();
                                  }}
                                  src={row?.otherDocuments}
                                  sx={{
                                    width: {
                                      sm: "100px",
                                      md: "100px",
                                      lg: "150px",
                                    },
                                    height: 100,
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={openDriverLicense}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseDriverLicense}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={openDriverLicenseBack}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseDriverLicenseBack}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageBack}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                        <Dialog
                          open={openDriverLicenseOther}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseDriverLicenseOther}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.otherDocuments}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                );
              })}
            </Item>

            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <Card
                    sx={{
                      boxShadow: 0,

                      color: "black",
                    }}
                  >
                    {row?.docType == 5 ? (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ px: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Vehicle Document
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>Vehicle Type: </b>{" "}
                            {row?.bike}
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}

                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Front Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenVehicleDocument();
                                  }}
                                  src={row?.imageFront}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Dialog
                          open={openVehicleDocument}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseVehicleDocument}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={openVehicleDocumentBack}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseVehicleDocumentBack}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageBack}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                );
              })}
            </Item>

            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <Card
                    sx={{
                      boxShadow: 0,

                      color: "black",
                    }}
                  >
                    {row?.docType == 6 ? (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ px: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Registration Certificate
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Vehicle Transport Year:{" "}
                            </b>{" "}
                            {row?.vehicleTransportyear}
                          </Typography>
                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}

                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Front Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenRegistrationCertificate();
                                  }}
                                  src={row?.imageFront}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Back Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenRegistrationCertificateBack();
                                  }}
                                  src={row?.imageBack}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={openRegistrationCert}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseRegistrationCertificate}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={openRegistrationCertBack}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseRegistrationCertificateBack}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageBack}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                );
              })}
            </Item>

            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <Card
                    sx={{
                      boxShadow: 0,

                      color: "black",
                    }}
                  >
                    {row?.docType == 7 ? (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ px: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Permit
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}

                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Front Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenPermit();
                                  }}
                                  src={row?.imageFront}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Back Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenPermitBack();
                                  }}
                                  src={row?.imageBack}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={openPermit}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClosePermit}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={openPermitBack}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClosePermitBack}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageBack}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                );
              })}
            </Item>

            <Item sx={{ marginBottom: "10px" }}>
              {riderDocsList?.map((row, i) => {
                return (
                  <Card
                    sx={{
                      boxShadow: 0,

                      color: "black",
                    }}
                  >
                    {row?.docType == 8 ? (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ px: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2 }}
                        >
                          <Typography
                            variant="h3"
                            textAlign="left"
                            sx={{ py: 1 }}
                          >
                            Visa Document
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Expiration Date:{" "}
                            </b>{" "}
                            {row?.expirationDate}
                          </Typography>

                          <Typography
                            variant="h5"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            sx={{ py: 0.5 }}
                          >
                            <b style={{ marginRight: "5px" }}>
                              Document Status:{" "}
                            </b>{" "}
                            {documentStatus(row?.isApproved)}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ py: 1 }}
                          >
                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Reject"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "red",
                                  backgroundColor: "#fff",
                                  ":hover": { bgcolor: "#F1F5F9" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                }}
                                onClick={() => {
                                  handleRejectModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}

                            {row?.isApproved !== 1 && row?.isApproved !== 2 ? (
                              <Controls.Button
                                text="Approve"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#fff",
                                  backgroundColor: "#4F46E5",
                                  ":hover": { bgcolor: "#4F46E5" },
                                  textTransform: "none",
                                  borderRadius: "15px",
                                  border: 1,
                                  borderColor: "#d3d3d3",
                                  boxShadow: 0,
                                  marginLeft: "7px",
                                }}
                                onClick={() => {
                                  handleSwitchModel();
                                  setSwitchId(row?.id);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          sx={{ px: 2, py: 1 }}
                        >
                          <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              sx={{ px: 2 }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  textAlign="left"
                                  sx={{ py: 1 }}
                                >
                                  Front Image
                                </Typography>
                                <Avatar
                                  component="span"
                                  variant="rounded"
                                  alt="profile_image"
                                  onClick={(e) => {
                                    handleClickOpenVisaDocument();
                                  }}
                                  src={row?.imageFront}
                                  sx={{ width: 150, height: 100 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Dialog
                          open={openVisaDocument}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseVisaDocument}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageFront}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={openVisaDocumentBack}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseVisaDocumentBack}
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogContent>
                            <img
                              style={{
                                width: "50%",
                                height: "100%",
                                marginLeft: "127px",
                              }}
                              src={row?.imageBack}
                              alt=""
                            />
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Card>
                );
              })}
            </Item>
          </Box>
        </Box>

        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Verify Document
            </Typography>
            <label className="fontLight">Verify the Document ?</label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="No"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Yes"
                  onClick={() => handleDocumentVerify(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  // onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openRejectModel}
          onClose={handleCloseReject}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure to want to reject this record?
            </label>

            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid container style={{ marginTop: "2rem" }}>
                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    style={{ backgroundColor: "#fff", color: "#000" }}
                    text="No"
                    onClick={handleCloseReject}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    style={{ marginRight: "7px" }}
                    text="Yes"
                    onClick={() => handleDocumentReject(switchId)}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openRejectDetailsModel}
          onClose={handleCloseRejectDetails}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Reason
            </Typography>
            <form onSubmit={handleRejectStatus}>
              <Box>
                <Box sx={{ marginTop: "30px" }}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Reason"
                    name="reason"
                    value={values.reason}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Box>
                <Grid container style={{ marginTop: "2rem" }}>
                  <Grid item xs={9} className="text-end">
                    <Controls.Button
                      style={{ backgroundColor: "#fff", color: "#000" }}
                      text="Cancel"
                      onClick={handleCloseRejectDetails}
                    />
                  </Grid>
                  <Grid item xs={3} className="text-end">
                    <Controls.Button
                      style={{ marginRight: "7px" }}
                      text="Save"
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openSwitchsModel}
          onClose={handleCloseSwitchs}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Approve Rider
            </Typography>
            <label className="fontLight">
              Are you sure to want to approve the Rider?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="No"
                  onClick={handleCloseSwitchs}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Yes"
                  onClick={() => handleApproveStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default RiderReqDetails;
