import axiosRequest from "../axios/axios";

//get setting List
export const getSettingLists = async (queryString) => {
  try {
    const response = await axiosRequest.get(`settingList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add setting

export const addSetting = async (payload) => {
  try {
    const response = await axiosRequest.post(`settingAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//setting Delete
export const DeleteSetting = async (id) => {
  try {
    const response = await axiosRequest.delete(`settingDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`settingStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//setting update

export const updateSetting = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`settingUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//get notification list
//get setting List
export const getNotificationLists = async () => {
  try {
    const response = await axiosRequest.get(`pushNotificationList`);
    return response.data;
  } catch (error) {
    return error;
  }
};
