import { useRef, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useProSidebar } from "react-pro-sidebar";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import {addPaymentMode, updatePaymentMode} from "../../state/action/paymentModeAction";
import "./AddPaymentMode.css";
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  name: "",
  description: "",
  status: "",
  action: "",

};

const AddPaymentMode = (props) => {
  const {
    open,
    handleClose,
    stateData,
    PaymentModeList,
    refreshScreen,
    isEdit,
  } = props;

  const { collapsed, broken } = useProSidebar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
 // const [vehicleImage, setVehicleImage] = useState({});
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";    

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      //const formData = new FormData();
      console.log(values.name, values.description);
      // formData.append("name", values.name);
      // formData.append("description", values.description);  
      
      const body = {
        name: values.name,
        description: values.description,
      };

      if (isEdit) {
        const res = await updatePaymentMode(stateData.id, body);
        if (res && res.status === true) {
          close();
          refreshScreen();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
          const res = await addPaymentMode(body);
          if (res && res.status === true) {
            close();
            refreshScreen();
            setImage("");
            resetForm();
            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          }   
      }
    }
  };

  const maxSize = 2048;

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        name: stateData.name,
        description: stateData.description,
      });
    } else {
      resetForm();
    }
  }, [isEdit, stateData]);

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        className={collapsed ? "" : "paymentModeModel" + broken ? "noMargin" : ""}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "auto", md: "auto", lg: "30%", xl: "30%" },
          marginLeft: {
            xs: "0px",
            sm: collapsed ? "10%" : "28%",
            md: collapsed ? "10%" : "250px",
            lg: collapsed ? "12%" : "260px",
            xl: collapsed ? "12%" : "260px",
          },
          color: "black",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Payment Mode" : "Add New Payment Mode"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setImage("");
                  resetForm();
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={10} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Payment mode Name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1, marginTop: "-10px" }} md={10} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    inputProps={{
                      maxLength: 150,
                    }}
                    error={errors.description}
                    helperText={errors.description}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "15px", marginTop: "-22px", marginLeft: "-500px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "-124px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    setImage("");
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default AddPaymentMode;
