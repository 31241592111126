import axiosRequest from "../axios/axios";

//get Company List
export const getCompanyLists = async (queryString) => {
  try {
    const response = await axiosRequest.get(`companyList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get Pending Company Rider List
export const getPendingCompanyRiderLists = async (stateData) => {
  try {
    //console.log("nfa getPendingCompanyRiderLists api ",stateData);
    const response = await axiosRequest.put(`pendingCompanyRiderList/${stateData}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Company rider approve
export const CompanyRiderApprove = async (id, queryString) => {
  try {
    const response = await axiosRequest.post(
      `/companyRiderApprove/${id}?status=1`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//Company rider reject

export const CompanyRiderReject = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/companyRiderApprove/${id}?status=2`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Company

export const addCompany = async (payload) => {
  try {
    const response = await axiosRequest.post(`companyAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Company Delete
export const DeleteCompany = async (id) => {
  try {
    const response = await axiosRequest.delete(`companyDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Company Details
export const CompanyDetails = async (id) => {
    try {
      console.log("nfa CompanyDetails");
      const response = await axiosRequest.get(`companyDetail/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

//Company Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`companyStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Company update

export const updateCompany = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`companyUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getVehicleDropdownList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getVehicleData`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const deleteSelectedImageApi = async (id) => {
  try {
    const response = await axiosRequest.delete(`removeDocumentImage/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


export const AssignNewCompanyRider = async (payload) => {
  try {
    const response = await axiosRequest.post(`assignNewCompanyRider`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const AssignCompanyRider = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`assignCompanyRider/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Company Delete
export const DeleteAssignCompany = async (id) => {
  try {
    const response = await axiosRequest.delete(`deleteAssignCompany/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


