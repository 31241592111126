import axiosRequest from "../axios/axios";

//get Parameter List
export const getPacelTypeList = async (queryString) => {
  try {
    const response = await axiosRequest.get(
      `parameterValueData/1` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Parameter

export const addParcelType = async (payload) => {
  try {
    const response = await axiosRequest.post(`parameterValueAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Parameter Delete
export const DeleteParcelType = async (id) => {
  try {
    const response = await axiosRequest.delete(`parameterValueDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Parameter Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`parameterValueStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Parameter update

export const updateParcelType = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(
      `parameterValueUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
