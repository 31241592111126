import axiosRequest from "../axios/axios";

//get Parameter List
export const getParameterList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`parameterValueList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Parameter

export const addParameterValue = async (payload) => {
  try {
    const response = await axiosRequest.post(`parameterValueAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Parameter Delete
export const DeleteParameter = async (id) => {
  try {
    const response = await axiosRequest.delete(`parameterValueDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Parameter Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`parameterValueStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Parameter update

export const updateParameter = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(
      `parameterValueUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//getParameter Type

export const getParameterTypeList = async () => {
  try {
    const response = await axiosRequest.get(`getparameterType`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//remove image
export const removeImageParameter = async (id) => {
  try {
    const response = await axiosRequest.put(`removeImage/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
