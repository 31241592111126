import { CompassCalibrationOutlined } from "@mui/icons-material";
import axiosRequest from "../axios/axios";

//get Vehical List
export const getPaymentModeList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`paymentModeList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Vehical

export const addPaymentMode = async (payload) => {
  try {
    console.log(JSON.stringify(payload));
    const response = await axiosRequest.post(`paymentModeAdd`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Vehical Delete
export const DeletePaymentMode = async (id) => {
  try {
    const response = await axiosRequest.delete(`paymentModeDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Payment Mode Status Update

export const StatusUpdate = async (id) => {
  try {
    console.log("nfa1", id);
    const response = await axiosRequest.put(`paymentModeStatusUpdate/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Vehical update

export const updatePaymentMode = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`paymentModeUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
