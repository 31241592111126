import axiosRequest from "../axios/axios";

//get withDraw List
export const getWithDrawList = async (queryString) => {
  try {
    const response = await axiosRequest.get(
      `withdrawalRequestList` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//status  update withDraw List

export const WithStatusUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `withdrawalRequestStatusChange/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//details

export const getWithDrawDetailsList = async (id) => {
  try {
    const response = await axiosRequest.get(`withdrawalRequestDetails/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
