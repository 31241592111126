import { Box } from "@mui/material";
import React from "react";
import PrivacyPolicyList from "./PrivacyPolicyList";

const PrivacyPolicy = () => {
  return (
    <Box>
      <PrivacyPolicyList />
    </Box>
  );
};

export default PrivacyPolicy;
