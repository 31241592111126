import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { TextField, FormLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import { addBanner, updateBanner } from "../../state/action/BannerAction";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useProSidebar } from "react-pro-sidebar";
import "./AddBanner.css";
import Multiselect from "multiselect-react-dropdown";
import moment from 'moment'

const today = dayjs();
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "220px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  bannerName: "",
  url: "",
  startDate: "",
  endDate: "",
  bannerImage: "",
  useFor: "",
  isRedirect: "",
};

const PublicList = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];

const userFor = [
  { id: "1", name: "User" },
  { id: "2", name: "Rider" },
];

const AddBanner = (props) => {
  const { open, handleClose, bannerData, refreshScreen, isEdit } = props;
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [vehicleImage, setVehicleImage] = useState({});
  const [error, setError] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { collapsed } = useProSidebar();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("bannerName" in fieldValues)
      temp.bannerName = fieldValues.bannerName ? "" : "This field is required.";

    if ("startDate" in fieldValues)
      temp.startDate = fieldValues.startDate ? "" : "This field is required.";
    if ("endDate" in fieldValues)
      temp.endDate = fieldValues.endDate ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const data = values?.useForIds?.map((row) => row?.id);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (!values?.useForIds && !values.startDate) {
        setError("Please select at least one User");
        setErrorDate("Please select a start date");
        setErrorEndDate("Please select a end date");
      } else {
        const formData = new FormData();
        formData.append("bannerName", values.bannerName);
        formData.append("url", values.url);
        formData.append("startDate", values.startDate);
        formData.append("endDate", values.endDate);
        formData.append("useFor", JSON.stringify(data));
        formData.append("isRedirect", values.isRedirect);
        if (vehicleImage && vehicleImage.name) {
          formData.append("bannerImage", vehicleImage);
        }

        if (isEdit) {
          const res = await updateBanner(bannerData.id, formData);
          if (res && res.status === true) {
            close();
            refreshScreen();
            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        }

        if (!isEdit) {
          if (vehicleImage && vehicleImage.name) {
            const res = await addBanner(formData);
            if (res && res.status === true) {
              close();
              refreshScreen();
              setImage("");
              resetForm();
              enqueueSnackbar(res.message, {
                variant: "success",
                autoHideDuration: 2000,
              });
            } else {
              enqueueSnackbar(res.message, {
                variant: "error",
                autoHideDuration: 2000,
              });
            }
          } else {
            enqueueSnackbar("Please Selecte Image", {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        }
      }
    }
  };

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["bannerImage"];
      return newValues;
    });
    setVehicleImage({});
  };

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  async function handleImageItem(e) {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setVehicleImage(selected);
        setValues({ ...values, vehicleIcon: selected });
      } else {
      }
    } else {
      enqueueSnackbar("Please Upload Image Size Less Than 5 MB", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const color = "#c44242 !important";

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        bannerName: bannerData.bannerName,
        url: bannerData.url,
        startDate: bannerData.startDate,
        endDate: bannerData.endDate,
        bannerImage: bannerData.image,
        useForIds: bannerData.useFors_test,
        isRedirect: bannerData.isRedirect,
      });
    } else {
      resetForm();
    }
  }, [isEdit, bannerData]);

  useEffect(() => {
    if (isEdit) {
      setImage(bannerData?.image);
      setVehicleImage(bannerData?.image);
    } else {
      setImage("");
    }
  }, [isEdit, bannerData]);

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "70%", md: "77%", lg: "80%", xl: "80%" },
          marginLeft: {
            xs: "0px",
            sm: collapsed ? "20%" : "25%",
            md: collapsed ? "15%" : "235px",
            lg: collapsed ? "12%" : "260px",
            xl: "260px",
          },
          color: "black",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Banner" : "Add New Banner"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setImage("");
                  resetForm();
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageItem}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper
                      sx={
                        image == ""
                          ? {}
                          : {
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }
                      }
                    >
                      {image === "" && <img src={camera} alt="Camera" />}
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    {isEdit ? "Edit Profile Image" : "Add Profile Image"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(15, 15, 15, 0.5)",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 5 MB
                  </Typography>

                  {image != "" && (
                    <StyledRemoveButton
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage();
                      }}
                    >
                      Remove
                    </StyledRemoveButton>
                  )}
                </StyledImageUploadWrapper>
              </Grid>

              <Grid item md={9} sm={8} xs={12} container spacing={1}>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Banner Name"
                    name="bannerName"
                    value={values.bannerName}
                    onChange={handleInputChange}
                    error={errors.bannerName}
                    helperText={errors.bannerName}
                  />
                </Grid>

                <Grid item sx={{ m: 1, display: 'grid', alignItems: 'center' }} md={5.5} xs={12}>
                  <Multiselect
                    options={userFor}
                    name="useFor"
                    displayValue="name"
                    closeOnSelect={true}
                    showArrow={true}
                    sx={{ marginTop: "21px", height: "100px" }}
                    selectedValues={values?.useForIds}
                    onSelect={(e) => {
                      setValues({
                        ...values,
                        useForIds: e,
                      });
                      setError("");
                    }}
                    onRemove={(e) => {
                      setValues({
                        ...values,
                        useForIds: e,
                      });
                      setError("");
                    }}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Controls.SelectBox
                    name="isRedirect"
                    label="Select Redirect"
                    value={values.isRedirect}
                    onChange={handleInputChange}
                    options={PublicList}
                  />
                </Grid>
                {values.isRedirect === 1 ? (
                  <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="URL"
                      name="url"
                      value={values.url}
                      onChange={handleInputChange}
                      error={values.isRedirect !== 0 ? errors.url : null}
                      helperText={values.isRedirect !== 0 ? errors.url : null}
                    />
                  </Grid>
                ) : null}

                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={values.startDate}
                      name="startDate"
                      onChange={(newValue) => {
                        setValues({
                          ...values,
                          startDate: dayjs(newValue).toDate(),
                          endDate: null,
                        });
                        setErrors({ ...errors, startDate: "" })
                      }}
                      label="Start Date"
                      minDate={today}
                      renderInput={(params) => <Controls.Input {...params} />}
                    />
                  </LocalizationProvider>
                  {Boolean(errors.startDate) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.startDate}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={values.endDate}
                      name="endDate"
                      onChange={(newValue) => {
                        setValues({
                          ...values,
                          endDate: dayjs(newValue).toDate(),
                        });
                        setErrors({ ...errors, endDate: "" })
                      }}
                      label="End Date"
                      minDate={values.startDate ? values.startDate : today}
                      renderInput={(params) => <Controls.Input {...params} />}
                    />
                  </LocalizationProvider>
                  {Boolean(errors.endDate) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.endDate}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginRight: "-127px",
                  }}
                  text="Cancel"
                  onClick={() => {
                    setImage("");
                    close();
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default AddBanner;
