import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Grid,
} from "@mui/material";

import Controls from "../../Component/controls/Controls";
import { useForm, Form } from "../../Utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/lab";
import camera from "../../assets/camera.svg";
import { addOffer, updateOffer } from "../../state/action/offerAction";
import Multiselect from "multiselect-react-dropdown";
import {
  getRiderDropdownList,
  getUserDropdownList,
} from "../../state/action/orderAction";

const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
const todayStartOfTheDay = today.startOf("day");

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  coupenCode: "",
  title: "",
  shortDescription: "",
  description: "",
  couponType: "",
  isActive: "",
  startDate: "",
  endDate: "",
  maxUsed: "",
  usedCount: "",
  usePerUser: "",
  userID: "",
  riderID: "",
  discountType: "",
  discountAmount: "",
};

const DisCountTypeList = [
  { id: 1, name: "Flat" },
  { id: 2, name: "Percentage" },
];

const CoupnTypeList = [
  { id: "0", name: "Global Coupon" },
  { id: "1", name: "User Specific Coupon " },
];
const StatusList = [
  { id: "1", name: "Active" },
  { id: "0", name: "Inactive" },
];
const PublicList = [
  { id: "1", name: "Yes" },
  { id: "0", name: "No" },
];

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [userLists, setUserLists] = useState([]);
  const [vehicleImage, setVehicleImage] = useState({});

  const [riderLists, setRiderLists] = useState([]);
  const [UserIds, setUserIds] = useState([]);
  // const [errorDate, setErrorDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const maxSize = 2048;

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
        setVehicleImage({});
        resetImage();
      } else {
        return true;
      }
    }
  };

  //user dropdownlist
  useEffect(() => {
    getriderListing();
  }, []);
  const getriderListing = async () => {
    const res = await getRiderDropdownList();
    if (res.status === true) {
      setRiderLists(res.data);
    } else {
      setRiderLists([]);
    }
  };

  //user dropdownlist
  useEffect(() => {
    getuserListing();
  }, []);

  const getuserListing = async () => {
    const res = await getUserDropdownList();
    if (res.status === true) {
      setUserLists(res.data);
    } else {
      setUserLists([]);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };


    if ("discountAmount" in fieldValues) {
      //only numbers allow regex
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.discountAmount)) {
        temp.discountAmount = " Only numbers are allowed";
      } else {
        temp.discountAmount = "";
      }
    }

    if ("usePerUser" in fieldValues) {
      //only numbers allow regex
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.usePerUser)) {
        temp.usePerUser = " Only numbers are allowed";
      }
      else if (fieldValues.maxUsed < fieldValues.usePerUser) {
        temp.usePerUser = "Use Per User cannot be greater than Max Used.";
      }
      else {
        temp.usePerUser = "";
      }
    }

    if ("maxUsed" in fieldValues) {
      //only numbers allow regex
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.maxUsed)) {
        temp.maxUsed = " Only numbers are allowed";
      }
      else {
        temp.maxUsed = "";
      }
    }

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";
    if ("shortDescription" in fieldValues)
      temp.shortDescription = fieldValues.shortDescription
        ? ""
        : "This field is required.";
    if ("coupenCode" in fieldValues)
      temp.coupenCode = fieldValues.coupenCode ? "" : "This field is required.";
    if ("startDate" in fieldValues)
      temp.startDate = fieldValues.startDate ? "" : "This field is required.";
    if ("endDate" in fieldValues)
      temp.endDate = fieldValues.endDate ? "" : "This field is required.";
    if ("isActive" in fieldValues)
      temp.isActive = fieldValues.isActive ? "" : "This field is required.";

    if ("isPublic" in fieldValues)
      temp.isPublic = fieldValues.isPublic ? "" : "This field is required.";
    if ("discountType" in fieldValues)
      temp.discountType = fieldValues.discountType
        ? ""
        : "This field is required.";

    if ("couponType" in fieldValues) {
      //only numbers allow regex
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.couponType)) {
        temp.couponType = " This field is required.";
      } else {
        temp.couponType = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);


  const [errorDate, setErrorDate] = useState("");


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const UserData = values?.useForIds?.map((row) => row?.id);
  const RiderData = values?.riderForIds?.map((row) => row?.id);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (!values?.useForIds && !values.startDate) {
        setErrorDate("Please select a start date");
        setErrorEndDate("Please select a end date");
      } else {
        const formData = new FormData();
        formData.append("coupenCode", values.coupenCode);
        formData.append("title", values.title);
        formData.append("startDate", values.startDate);
        formData.append("endDate", values.endDate);
        if (vehicleImage && vehicleImage.name) {
          formData.append("image", vehicleImage);
        }
        formData.append("shortDescription", values.shortDescription);
        formData.append("description", values.description);
        formData.append("couponType", values.couponType);
        formData.append("isActive", values.isActive);
        formData.append("maxUsed", values.maxUsed);
        formData.append("usedCount", values.usedCount);
        formData.append("usePerUser", values.usePerUser);
        formData.append("userID", JSON.stringify(UserData));
        formData.append("riderID", '');
        formData.append("discountType", values.discountType);
        formData.append("discountAmount", values.discountAmount);



        if (isEdit) {
          const res = await updateOffer(state?.offer?.id, formData);
          if (res && res.status === true) {
            navigate("/coupons-offers");

            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        }

        if (!isEdit) {
          if (vehicleImage && vehicleImage.name) {
            const res = await addOffer(formData);
            if (res && res.status === true) {
              navigate("/coupons-offers");
              enqueueSnackbar(res.message, {
                variant: "success",
                autoHideDuration: 2000,
              });
            } else {
              enqueueSnackbar(res.message, {
                variant: "error",
                autoHideDuration: 2000,
              });
            }
          } else {
            enqueueSnackbar("Please Selecte Image", {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        }
      }
    }
  };

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const removeImage = () => {
    resetImage();
    setVehicleImage({});

    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setVehicleImage(selected);

        setValues({ ...values, image: selected });
      } else {
      }
    } else {
      enqueueSnackbar("Please Upload the min 5MB Image", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  //details edit
  useEffect(() => {
    if (
      state?.offer.id != "" &&
      state?.offer.id != null &&
      state?.offer.id != undefined
    ) {
      console.log(
        "🚀 ~ file: AddCouponsOffers.jsx:413 ~ useEffect ~ state:",
        state?.offer?.couponType
      );
      setIsEdit(true);
      setValues({
        ...values,
        coupenCode: state?.offer.coupenCode,
        startDate: state?.offer.startDate,
        endDate: state?.offer.endDate,
        image: state?.offer?.image,
        couponType: state?.offer?.couponType,
        description: state?.offer?.description,
        discountAmount: state?.offer?.discountAmount,
        discountType: state?.offer?.discountType,
        isActive: state?.offer?.isActive,
        maxUsed: state?.offer?.maxUsed,
        riderForIds: state?.offer?.riderarrays,
        shortDescription: state?.offer?.shortDescription,
        title: state?.offer?.title,
        usePerUser: state?.offer?.usePerUser,
        useForIds: state?.offer?.userarrays && state?.offer?.userarrays[0]?.name ? state?.offer?.userarrays : [],
        usedCount: state?.offer?.usedCount,
      });
      setImage(state?.offer?.image);
      setVehicleImage(state?.offer?.image);
    } else {
      resetForm();
    }
  }, [state?.offer]);
  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              sx={{
                paddingTop: "25px",
                paddingBottom: "15px",
                fontSize: "15px",
                fontWeight: "600",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/coupons-offers");
              }}
            >
              <KeyboardBackspaceIcon
                sx={{ verticalAlign: "top", marginRight: "5px" }}
              />
              Coupons and Offers
            </Typography>
            <div>
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginRight: "10px",
                }}
                text="Cancel"
                onClick={() => {
                  navigate("/coupons-offers");
                  resetForm();
                }}
              />
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Submit"
                onClick={(e) => handleSubmit(e)}
                type="submit"
              />
            </div>
          </Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Box
            className="b1"
            sx={{
              backgroundColor: "white",
            }}
          >
            <Grid item sx={{ m: 1 }} md={6} xs={12}>
              <StyledImageUploadWrapper>
                <label htmlFor="image-upload">
                  <StyledInput
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    onChange={handleImageItem}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                  <StyledIconWrapper
                    sx={
                      image == ""
                        ? {}
                        : {
                          backgroundImage: `url(${image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }
                    }
                  >
                    {image === "" && <img src={camera} alt="Camera" />}
                  </StyledIconWrapper>
                </label>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Add Coupon Image
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(15, 15, 15, 0.5)",
                    mx: 2,
                    fontSize: "12px",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, max size of 5 MB
                </Typography>
                {image != "" && (
                  <StyledRemoveButton
                    onClick={(e) => {
                      e.preventDefault();
                      removeImage();
                    }}
                  >
                    Remove
                  </StyledRemoveButton>
                )}
              </StyledImageUploadWrapper>
            </Grid>
            <Grid
              item
              md={12}
              justifyContent="center"
              sm={12}
              xs={12}
              container
            >
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Title"
                  name="title"
                  fullWidth
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                  helperText={errors.title}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  label="Coupen Code"
                  name="coupenCode"
                  value={values.coupenCode}
                  onChange={handleInputChange}
                  error={errors.coupenCode}
                  helperText={errors.coupenCode}
                />
              </Grid>
              <Grid tem sx={{ m: 1 }} md={5.5} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values.startDate}
                    name="startDate"
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        startDate: dayjs(newValue).toDate(),
                        endDate: null,
                      });
                      setErrors({ ...errors, startDate: "" })
                    }}
                    label="Start Date"
                    minDate={today}
                    renderInput={(params) => <Controls.Input {...params} />}
                  />

                </LocalizationProvider>
                {Boolean(errors.startDate) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.startDate}
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid tem sx={{ m: 1 }} md={5.5} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values.endDate}
                    name="endDate"
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        endDate: dayjs(newValue).toDate(),
                      });
                      setErrors({ ...errors, endDate: "" })
                    }}
                    label="End Date"
                    minDate={values.startDate ? values.startDate : today}
                    renderInput={(params) => <Controls.Input {...params} />}
                  />

                </LocalizationProvider>
                {Boolean(errors.endDate) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.endDate}
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input"
                  fullWidth
                  label="Select Discount Type"
                  id="discountType"
                  name="discountType"
                  value={values.discountType}
                  onChange={handleInputChange}
                  options={DisCountTypeList}
                />{" "}
                {Boolean(errors.discountType) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.discountType}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}

              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Discount Amount"
                  name="discountAmount"
                  value={values.discountAmount}
                  onChange={handleInputChange}
                  error={errors.discountAmount}
                  helperText={errors.discountAmount}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Max Used"
                  name="maxUsed"
                  value={values.maxUsed}
                  onChange={handleInputChange}
                  error={errors.maxUsed}
                  helperText={errors.maxUsed}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Use Per User"
                  name="usePerUser"
                  value={values.usePerUser}
                  onChange={handleInputChange}
                  error={errors.usePerUser}
                  helperText={errors.usePerUser}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>

              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  fullWidth
                  label="Select Coupon Type"
                  name="couponType"
                  value={values.couponType}
                  onChange={handleInputChange}
                  options={CoupnTypeList}
                />
                {Boolean(errors.couponType) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.couponType}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.SelectBox
                  className="input"
                  fullWidth
                  id="isActive"
                  label="Select Status"
                  name="isActive"
                  value={values.isActive}
                  onChange={handleInputChange}
                  options={StatusList}
                />
                {Boolean(errors.isActive) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.isActive}
                  </p>
                ) : (
                  ""
                )}
              </Grid>{" "}
              {values.couponType == "1" ? (
                <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                  <Multiselect
                    placeholder="Select User"
                    options={userLists}
                    displayValue="name"
                    closeOnSelect={true}
                    showArrow={true}
                    sx={{ marginTop: "21px", height: "100px" }}
                    selectedValues={values?.useForIds}
                    onSelect={(e) => {
                      setValues({
                        ...values,
                        useForIds: e,
                      });
                    }}
                    onRemove={(e) => {
                      setValues({
                        ...values,
                        useForIds: e,
                      });
                    }}
                    error={errors.useFor}
                    helperText={errors.useFor}
                  />
                </Grid>
              ) : null}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  multiline
                  rows={4}
                  color="primary"
                  label="Short Description"
                  name="shortDescription"
                  value={values.shortDescription}
                  onChange={handleInputChange}
                  error={errors.shortDescription}
                  helperText={errors.shortDescription}
                />
              </Grid>{" "}
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  multiline
                  rows={4}
                  color="primary"
                  label="Description"
                  name="description"
                  value={values.description}
                  onChange={handleInputChange}
                  error={errors.description}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} md={5.5} xs={12}></Grid>
            </Grid>
          </Box>
        </Form>
      </Box>
    </>
  );
}
