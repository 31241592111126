import axiosRequest from "../axios/axios";

//order list
export const getOrderList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getorders` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//rider Dropdown
export const getRiderDropdownList = async () => {
  try {
    const response = await axiosRequest.get(`/riderDropDown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//user Dropdown
export const getUserDropdownList = async () => {
  try {
    const response = await axiosRequest.get(`/userDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//order details
export const getOrderDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/OrderDetails/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//upadte
export const getOrderStatusUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `/OrderStatusUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
